<template>
  <div>
    <v-form
      ref="updateYearForm"
      v-model="isValid"
      lazy-validation
    >
      <div>
        <h3 class="title px-0 mb-5">Add your PDP year</h3>
      </div>
      <Alert
        :subtext="subtext"
        class="mb-10"
        inPage
        text="Action required"
        type="warning"
      ></Alert>
      <v-select
        v-model="year"
        :items="selectableYears"
        :messages="pdpYearMessage"
        :rules="[rules.numberFieldRequired]"
        class="mt-1 mb-4"
        item-text="value"
        item-value="value"
        label="PDP Year"
        outlined
        placeholder="Select"
        persistent-placeholder
        return-object
        validate-on-blur
      />
      <div class="d-flex flex-row-reverse">
        <AdsButton
          :disabled="!year"
          buttonText="Proceed"
          class="ml-auto"
          icon="mdi-arrow-right"
          type="submit"
          @click.prevent="proceed"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AdsButton, Alert } from '@nswdoe/doe-ui-core';
import validators from '@/lib/validators';
import { selectablePdpYears } from '@/utils';

export default {
  name: 'UpdateYearModal',
  components: {
    AdsButton,
    Alert,
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    closeDialog: false,
    isValid: true,
    year: null,
    rules: {
      numberFieldRequired: validators.numberFieldRequired,
    },
    subtext: 'To clarify, add the year your PDP will be completed.',
  }),
  computed: {
    ...mapGetters(['formPdp', 'isPdpLoaded', 'ownedPdp']),
    selectableYears() {
      const yearSet = [
        ...new Set([
          new Date().getFullYear() - 1,
          new Date().getFullYear(),
          new Date().getFullYear() + 1,
          ...(this.formPdp?.year ? [this.formPdp?.year] : []),
        ]),
      ].sort((a, b) => a - b);
      return selectablePdpYears(this.ownedPdp, yearSet, true);
    },
    pdpYearMessage() {
      switch (true) {
        case this.rules.numberFieldRequired(this.year) !== true &&
          !this.isValid:
          return 'Please select from the list';
        default:
          return 'Select the year for this PDP';
      }
    },
  },
  methods: {
    proceed() {
      const validated = this.$refs.updateYearForm.validate();
      if (validated) {
        this.formPdp.year = this.year;
        this.$emit('save');
      }
    },
  },
};
</script>

<template>
  <Dialog
    maxWidth="55em"
    :openDialog="openCkaModal"
    :displayCloseBtn="true"
    @close="handleCkaModalClose"
    :return-handler="handleCkaModalClose"
    :fullscreen="!isMobile"
  >
    <template #title>
      <v-container
        v-if="isMobile"
        class="pb-0 px-7"
      >
        <v-row class="ma-0 pt-3">
          <v-col
            cols="auto"
            class="ml-2"
          >
            <v-btn
              color="#cadaee"
              depressed
              rounded
              :ripple="false"
              :readonly="true"
              min-width="51px"
              max-width="51px"
              dense
            >
              <v-icon
                size="28px"
                :color="'#122a61'"
              >
                mdi-sitemap-outline
              </v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <p class="headline">Add key accountabilities</p>
            <p>
              Expand the list below to find desired key accountabilities in your
              desired area of expertise.
              <br />
              Select
              <strong>Add selected</strong>
              to add them to your PDP.
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-container
        v-else
        class="pa-0"
      >
        <v-row class="ma-0">
          <v-toolbar
            color="primary"
            elevation="0"
          >
            <v-btn
              icon
              @click="handleCkaModalClose"
              aria-label="close"
            >
              <v-icon color="white">close</v-icon>
            </v-btn>
            <v-toolbar-title class="font-weight-bold">
              Add key accountabilities
            </v-toolbar-title>
          </v-toolbar>
        </v-row>
        <v-row class="mx-5 mt-6">
          <p>
            Expand the list below to find desired key accountabilities in your
            desired area of expertise.
            <br />
            Select
            <strong>Add selected</strong>
            to add them to your PDP.
          </p>
        </v-row>
      </v-container>
    </template>
    <template #text>
      <v-container
        data-test="key-accountabilities-modal"
        class="ma-0 pt-0 px-5 px-md-7"
      >
        <v-row
          v-if="showWarning"
          class="ma-0 ma-md-n3"
        >
          <v-col class="mt-6 mt-md-0 pa-0 pa-md-3 pt-md-0">
            <Alert
              type="warning"
              text="You have added the maximum number of key accountabilities to your goal."
              subtext="Please work with your PDP supervisor to refine your goals."
              inPage
              :backgroundColour="true"
              :border="$vuetify.breakpoint.mdAndUp ? 'left' : 'top'"
              class="ma-0 ma-md-4"
            />
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col class="pt-0">
            <v-list expand>
              <v-list-group
                v-for="(area, areaIndex) in areasOfPractice"
                :key="`area-${areaIndex}-goal-${currentGoal?.id}`"
                :value="isOpenArea[areaIndex]"
              >
                <template #activator>
                  <v-list-item-content>
                    <p
                      class="pa-0 ma-0 font-size-18"
                      style="line-height: 1.5rem"
                      v-dompurify-html:plaintext="area.text ? area.text : ''"
                    />
                  </v-list-item-content>
                </template>
                <v-list-item
                  v-for="(
                    accountability, accountabilityIndex
                  ) in area.accountabilities"
                  :key="`accountability-${currentGoal?.id}.${areaIndex}.${accountabilityIndex}`"
                >
                  <v-card
                    :id="`accountability-${currentGoal?.id}.${areaIndex}`"
                    :class="`${
                      isAccountabilitySelected(area?.text, accountability?.text)
                        ? 'selected'
                        : ''
                    } my-2 list-item`"
                    min-width="100%"
                    :ripple="false"
                    @click.stop="
                      handleAccountabilitySelect(
                        area?.text,
                        accountability?.text,
                        areaIndex,
                        accountabilityIndex
                      )
                    "
                  >
                    <v-card-text>
                      <v-row class="align-center">
                        <v-col
                          class="flex-shrink-1 flex-grow-0 justify-center align-center"
                        >
                          <v-checkbox
                            :input-value="
                              isAccountabilitySelected(
                                area?.text,
                                accountability?.text
                              )
                            "
                            :disabled="
                              !isAccountabilitySelected(
                                area?.text,
                                accountability?.text
                              ) && activeAccountabilities.length === maximum
                            "
                          />
                        </v-col>
                        <v-col class="flex-shrink-0 flex-grow-1">
                          <p
                            class="body-1 text-md-body-2 line-height-24 correct-color mb-0"
                            v-dompurify-html:plaintext="
                              accountability.text ? accountability.text : ''
                            "
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
      <v-row class="bottom-row">
        <v-col class="button-right">
          <v-btn
            color="primary"
            @click="handleFinish"
            data-test="add-accountability"
          >
            Add selected
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@nswdoe/doe-ui-core/src/Dialog/Dialog.vue';
import { mdiChevronRight } from '@mdi/js';
import { mapGetters } from 'vuex';
import { areasOfPractice, maxAccountabilities } from '@/data/areasOfPractice';
import { Alert } from '@nswdoe/doe-ui-core';
import { clone } from 'ramda';

export default {
  name: 'CreateKeyAccountability',
  props: {
    openCkaModal: Boolean,
    section: {
      type: Object,
      default: () => ({}),
    },
    goal: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Dialog,
    Alert,
  },
  data() {
    return {
      icons: {
        mdiChevronRight,
      },
      areasOfPractice,
      activeAccountabilities: [],
      openSet: { areaOfPractice: new Set() },
      isOpenArea: [],
      showWarning: false,
      maximum: maxAccountabilities,
    };
  },
  computed: {
    ...mapGetters(['formPdp']),
    currentGoal() {
      return clone(this.goal);
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
  watch: {
    openCkaModal: {
      handler() {
        if (this.openCkaModal) {
          this.updateActive();
          this.updateCompleteAccountabilityOpenSet();
        } else this.isOpenArea = [];
      },
      immediate: true,
    },
  },
  methods: {
    updateActive() {
      this.activeAccountabilities = this.currentGoal.accountabilities?.filter(
        (item) => {
          return !item.markForDelete;
        }
      );
    },
    async handleFinish() {
      this.formPdp.sections = this.formPdp.sections.map((section) => {
        if (section.id === this.section.id) {
          section.goals = section.goals.map((goal) => {
            if (goal.id === this.currentGoal.id) return this.currentGoal;
            return goal;
          });
        }
        return section;
      });
      this.handleCkaModalClose();
    },
    handleCkaModalClose() {
      this.$emit('closeCkaModal');
    },
    handleAccountabilitySelect(
      areaText,
      accountabilityText,
      areaIndex,
      accountabilityIndex
    ) {
      const isMaximum = this.checkMaximum();
      let existing;
      const accountability = this.currentGoal.accountabilities?.find(
        (accountability, index) => {
          if (
            accountability.areaOfPractice === areaText &&
            accountability.accountability === accountabilityText
          ) {
            existing = index;
            return true;
          } else return false;
        }
      );
      if (accountability) {
        if (
          accountability.markForDelete === undefined ||
          accountability.markForDelete === false
        ) {
          accountability.markForDelete = true;
          this.currentGoal.accountabilities.splice(existing, 1);
          this.currentGoal.accountabilities.push(accountability);
        } else if (accountability.markForDelete === true && !isMaximum) {
          accountability.markForDelete = false;
          this.currentGoal.accountabilities.splice(existing, 1);
          this.currentGoal.accountabilities.push(accountability);
        }
        this.updateActive();
        this.updateCompleteAccountabilityOpenSet();
      } else if (!isMaximum) {
        const foundArea = this.areasOfPractice.find(
          (area) => area.text === areaText
        );
        if (foundArea) {
          const foundAccountability = foundArea.accountabilities.find(
            (accountability) => accountability.text === accountabilityText
          );
          if (foundAccountability) {
            const newAccountability = {
              goal_id: this.currentGoal.id,
              index: `${areaIndex + 1}.${accountabilityIndex + 1}`,
              areaOfPractice: areaText,
              accountability: accountabilityText,
            };
            this.currentGoal?.accountabilities.push(newAccountability);
            this.updateActive();
            this.updateCompleteAccountabilityOpenSet();
          }
        }
      }
    },
    checkMaximum() {
      const existingAccountabilitiesLength =
        this.currentGoal.accountabilities.filter(
          (item) => !item.markForDelete
        ).length;
      this.showWarning = existingAccountabilitiesLength >= this.maximum;
      return this.showWarning;
    },
    isAccountabilitySelected(areaText, accountabilityText) {
      let selected = false;
      let result;
      if (this.currentGoal.accountabilities?.length > 0) {
        result = this.currentGoal.accountabilities?.find((accountability) => {
          return (
            accountability.areaOfPractice === areaText &&
            accountability.accountability === accountabilityText &&
            !accountability.markForDelete
          );
        });
      }
      selected = !!result;
      return selected;
    },
    deleteAccountability(areaText, accountabilityText) {
      let existing;
      const foundAccountability = this.currentGoal?.accountabilities.find(
        (accountability, index) => {
          if (
            accountability.areaOfPractice === areaText &&
            accountability.accountability === accountabilityText
          ) {
            existing = index;
            return true;
          } else return false;
        }
      );
      if (foundAccountability && foundAccountability.id) {
        this.currentGoal.accountabilities[existing].markForDelete = true;
        this.updateActive();
      } else if (foundAccountability && !foundAccountability.id) {
        this.currentGoal.accountabilities.splice(existing, 1);
        this.updateActive();
      }
    },
    updateOpenSet(areaText) {
      this.openSet.areaOfPractice.add(areaText);
    },
    updateCompleteAccountabilityOpenSet() {
      if (this.activeAccountabilities.length) {
        for (const accountability of this.activeAccountabilities) {
          this.updateOpenSet(accountability.areaOfPractice);
        }
      }
      this.checkOpenPanels();
    },
    checkOpenPanels() {
      if (this.openSet.areaOfPractice.size) {
        this.isOpenArea = this.areasOfPractice.map((item) => {
          return this.checkIsOpen(item.text);
        });
      }
    },
    checkIsOpen(area) {
      return this.openSet.areaOfPractice.has(area);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../scss/variables';

.correct-color {
  color: #000;
}

.full-width {
  width: 100%;
  text-align: left;
  justify-content: left;
}

.button-right {
  float: right;
}

.dark-border {
  border-color: $ads-dark-80;
}

.selected-border {
  border-color: $ads-success-green;
  border-width: 3px;
}

.card-outer {
  position: relative;
  padding-bottom: 50px;
}

.bottom-row {
  position: sticky;
  bottom: 0;
  justify-content: flex-end;
  margin: 0;
  background-color: $ads-white;
  border-top: solid 1px $ads-light-20;

  .button-right {
    flex-grow: 0;
  }
}

.selected {
  background: #d0ebfb;
}

.list-item {
  outline: 1px solid black;
}

:deep .v-toolbar__content .v-toolbar__title {
  color: $ads-white;
}

:deep .v-list-group .v-list-group {
  .v-input--selection-controls {
    &__input {
      margin: 0;
    }
  }
}

@media screen and (max-width: 959px) {
  :deep .v-list-group {
    &__header.v-list-item {
      padding-left: 0;
      padding-right: 0;
    }
    &__items > .v-list-item {
      padding-left: 1px !important;
      padding-right: 1px !important;
    }
    .v-input--checkbox {
      margin: 0;
      padding: 0;
    }
    .v-input--selection-controls {
      &__input {
        margin: 0;
      }
      &__ripple:before {
        display: none;
      }
    }
    .v-input__slot {
      margin-bottom: 0;
    }
    .v-messages {
      display: none;
    }
  }
  :deep .v-alert {
    &__border--top {
      border-width: 3px !important;
    }
  }
  .line-height-24 {
    line-height: 24px;
  }
  .font-size-18 {
    font-size: 18px;
  }
}
</style>

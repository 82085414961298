<template>
  <tbody>
    <v-container>
      <TSUserBannerPrint
        :pdp="pdp"
        :user="pdp?.owner"
        backgroundColour="white"
        class="ts-user-banner--print"
      />
      <!-- PLAN SECTION -->
      <v-container class="px-0 pb-5">
        <PrintTabs
          :selectedTab="getTabIndexById(TAB_TYPES.plan.id)"
          :tabs="tabs"
        />
      </v-container>
      <OrientationBanner
        v-if="!planSection.complete"
        :isSupervisor="false"
        :pdp="pdp"
        :section="planSection"
        class="px-5 px-md-0 pb-0 pb-md-3 mt-3"
        readOnly
      />
      <TSPlanReadOnlyPrint
        :pdp="pdp"
        :section="planSection"
      />
      <!-- GOALS AND EVIDENCE -->
      <v-container class="px-0 py-5 page-break-before">
        <PrintTabs
          :selectedTab="getTabIndexById(TAB_TYPES.goalsAndEvidence.id)"
          :tabs="tabs"
        />
      </v-container>
      <TSPdpGoalsPrint
        :editable="false"
        :goals="midYearSectionGoals"
        :section="midYearSection"
        expandAll
      />
      <!-- OBSERVATIONS -->
      <v-container class="pa-0 pt-5 page-break-before">
        <PrintTabs
          :selectedTab="getTabIndexById(TAB_TYPES.observations.id)"
          :tabs="tabs"
        />
      </v-container>
      <v-card class="my-5 py-5">
        <TSPdpObservationsPrint
          :editMode="false"
          :pdp="pdp"
        />
      </v-card>
      <!-- REVIEWS -->
      <v-container class="pa-0 pb-5 page-break-before">
        <PrintTabs
          :selectedTab="getTabIndexById(TAB_TYPES.reviews.id)"
          :tabs="tabs"
        />
      </v-container>
      <OrientationBanner
        v-if="planSection.complete"
        :pdp="pdp"
        :section="activeSection"
        readOnly
      />
      <TSReviewsPrint
        expandAll
        readOnly
      />
      <!-- PERSONAL NOTES -->
      <PersonalNotesCard
        :comments="notes"
        class="my-10 py-0 page-break-before"
      />
    </v-container>
  </tbody>
</template>

<script>
import PrintTabs from '@/components/print/PrintTabs';
import OrientationBanner from '@/components/OrientationBanner';
import TSPlanReadOnlyPrint from '@/components/print/TSPlanReadOnlyPrint';
import TSPdpGoalsPrint from '@/components/print/TSPdpGoalsPrint';
import TSPdpObservationsPrint from '@/components/print/TSPdpObservationsPrint';
import TSReviewsPrint from '@/components/print/TSReviewsPrint';
import TSUserBannerPrint from '@/components/print/TSUserBannerPrint';
import PersonalNotesCard from '@/components/print/PersonalNotesCard';
import { TAB_TYPES } from '@/data/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'TSPrintContent',
  components: {
    PrintTabs,
    OrientationBanner,
    TSPlanReadOnlyPrint,
    TSPdpGoalsPrint,
    TSPdpObservationsPrint,
    TSReviewsPrint,
    TSUserBannerPrint,
    PersonalNotesCard,
  },
  props: {
    pdp: {
      type: Object,
      default: () => ({}),
    },
    planSection: {
      type: Object,
      default: () => ({}),
    },
    midYearSection: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      TAB_TYPES,
      tabs: [
        TAB_TYPES.plan,
        TAB_TYPES.goalsAndEvidence,
        TAB_TYPES.observations,
        TAB_TYPES.reviews,
      ],
    };
  },
  computed: {
    ...mapGetters('pdp', ['personalNotes']),
    activeSection() {
      if (this.planSection?.complete && this.midYearSection?.complete) {
        return this.actionPdpFinalSection;
      }
      if (this.planSection?.complete) {
        return this.midYearSection;
      }
      return this.planSection;
    },
    // this is so GOALS & EVIDENCE tab is not blank when user prints before having defined plan goals
    midYearSectionGoals() {
      if (this.midYearSection.goals?.length > 0) {
        return this.midYearSection.goals;
      } else {
        return this.planSection.goals;
      }
    },
    notes() {
      return this.personalNotes?.text;
    },
  },
  methods: {
    getTabIndexById(tabId) {
      return this.tabs.findIndex((tab) => tab.id === tabId);
    },
  },
  provide: {
    isPrint: true,
  },
};
</script>

<style lang="scss" scoped>
.ts-user-banner--print {
  &.banner1 {
    color: $ads-grey-01;

    :deep .v-icon {
      color: $ads-grey-01;
    }
  }
}

.page-break-before {
  break-before: page;
}

.v-tabs {
  pointer-events: none;
}

:deep .quillWrapper {
  user-select: none;
}
</style>

<template>
  <div>
    <v-row
      v-if="isPlanSection && !isOwner"
      class="mb-7 px-md-10"
      no-gutters
    >
      <v-col class="pa-0">
        <v-card
          class="px-6 pt-5 pb-0 pb-md-0"
          color="primary-teal"
          elevation="0"
          style="border-radius: 12px"
        >
          <v-row
            class="d-flex ma-0 pa-0"
            no-gutters
          >
            <v-col class="pa-0">
              <v-card-title class="pa-0 pb-5 primary-blue--text headline">
                Helpful links for supervisors
              </v-card-title>
              <v-card-text class="px-0 pb-0 ads-primary-blue">
                <p>
                  <a
                    class="ads-primary-blue"
                    href="https://education.nsw.gov.au/inside-the-department/human-resources/performance/non-teaching-staff-in-schools/supervising-performance-and-development/supporting-meaningful-conversations"
                    target="_blank"
                  >
                    <b>Supporting Meaningful Conversations</b>
                    <v-icon class="ml-2 ads-primary-blue">
                      mdi-exit-to-app
                    </v-icon>
                  </a>
                </p>
                <p>
                  <a
                    class="ads-primary-blue"
                    href="https://education.nsw.gov.au/content/dam/main-education/inside-the-department/human-resources/media/documents/performance/non-teaching-staff-performance-and-development/Meaningful-conversations-useful-approaches.docx"
                    target="_blank"
                  >
                    <b>
                      Meaningful conversations and feedback - useful approaches
                    </b>
                    <v-icon class="ml-2 ads-primary-blue">
                      mdi-exit-to-app
                    </v-icon>
                  </a>
                </p>
                <p>
                  <a
                    class="ads-primary-blue"
                    href="https://education.nsw.gov.au/inside-the-department/human-resources/performance/non-teaching-staff-in-schools/supervising-performance-and-development/supporting-non-teaching-staff-to-develop-their-goals"
                    target="_blank"
                  >
                    <b>Supporting staff to develop their goals</b>
                    <v-icon class="ml-2 ads-primary-blue">
                      mdi-exit-to-app
                    </v-icon>
                  </a>
                </p>
              </v-card-text>
            </v-col>
            <v-col
              v-if="!isMobile"
              class="d-flex justify-end align-end image-col"
              cols="auto"
            >
              <v-img
                class="fill-height image-content"
                max-height="115px"
                max-width="116px"
                src="../../public/gender-neutral-clipboard.svg"
              ></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="isMidYearSection"
      no-gutters
    >
      <v-col>
        <NTSReviewReadOnly
          :pdp="pdp"
          :section="section"
          class="mt-5 mx-md-10 mb-10"
        />
      </v-col>
    </v-row>
    <v-row
      class="px-md-10 py-0 my-0"
      no-gutters
    >
      <v-col class="py-0">
        <v-card class="mb-5">
          <v-row no-gutters>
            <v-col cols="auto">
              <div class="px-5 px-md-10 pt-9">
                <h1
                  v-dompurify-html:plaintext="
                    `${formatName(pdp?.owner)}'s Plan`
                  "
                  class="display-1 ads-grey-01"
                />
                <p
                  v-dompurify-html:plaintext="`Created: ${createdDate}`"
                  class="mt-1 mb-6 body-1 ads-grey-02"
                />
                <h1 class="headline">Work Goals</h1>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-for="(goal, index) of workGoals"
            :key="`plan-read-only-goal-${goal?.id}`"
          >
            <v-col class="my-0 pb-0">
              <div class="mb-0">
                <div
                  class="goal-heading mb-10 px-5 px-md-10 py-5 title ads-grey-01"
                >
                  Goal
                  {{ index + 1 }}:
                  {{ goal.title ?? '' }}
                </div>
                <div class="pb-5 px-5 px-md-10">
                  <div class="divider">
                    <p class="goal-section-title">
                      <v-icon class="mr-2 ads-blue-2">mdi-star-outline</v-icon>
                      Description
                    </p>
                    <IncompleteFieldCard
                      v-if="
                        !goal?.description?.length && isPdpIncompleteArchived
                      "
                    />
                    <div
                      v-else
                      v-dompurify-html="goal?.description ?? ''"
                      class="goal-text mb-10 body-1"
                    />
                  </div>
                  <div class="">
                    <p class="goal-section-title">
                      <v-icon class="mr-2 ads-blue-2">mdi-calendar-edit</v-icon>
                      Strategies to achieve goals
                    </p>
                    <IncompleteFieldCard
                      v-if="
                        !goal?.strategies?.length && isPdpIncompleteArchived
                      "
                    />
                    <div
                      v-else
                      v-dompurify-html="`${goal.strategies ?? ''}`"
                      class="goal-text mb-5 body-1"
                    />
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="!workGoals?.length && isPdpIncompleteArchived">
            <v-col class="my-0 pb-0">
              <div class="pb-5 px-5 px-md-10">
                <IncompleteFieldCard v-if="!workGoals?.length" />
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-5" />
          <v-row>
            <v-col class="my-0 pb-0">
              <div class="mb-0">
                <div class="pb-5 px-5 px-md-10">
                  <p class="d-flex flex-row goal-section-title">
                    <v-icon class="mr-2 ads-blue-2">mdi-school-outline</v-icon>
                    Learning and development
                  </p>
                  <IncompleteFieldCard
                    v-if="
                      !section?.workLearningText?.length &&
                      isPdpIncompleteArchived
                    "
                  />
                  <div
                    v-else
                    v-dompurify-html="section?.workLearningText ?? ''"
                    class="goal-text body-1"
                  />
                </div>
                <div class="px-5 px-md-10">
                  <p class="mb-0 goal-section-title">
                    <v-icon class="mr-2 ads-blue-2">
                      mdi-notification-clear-all
                    </v-icon>
                    Professional Learning Themes
                  </p>
                  <div
                    v-for="workLearningTheme in currentWorkLearningThemes"
                    :key="`workLearningTheme.${workLearningTheme.id}`"
                  >
                    <v-card
                      v-if="workLearningTheme.markForDelete !== true"
                      :flat="true"
                      :outlined="false"
                      class="dark-border px-5 px-md-0 pt-md-5"
                    >
                      <v-row class="ma-0 ma-md-n3 flex-column flex-md-row">
                        <v-col class="px-0 px-md-3">
                          <v-card-title class="ma-0 pa-0">
                            <p
                              v-dompurify-html:plaintext="
                                workLearningTheme?.name
                                  ? workLearningTheme?.name
                                  : ''
                              "
                              class="subtitle-1"
                            />
                          </v-card-title>
                          <v-card-text class="ma-0 pa-0">
                            <p
                              v-dompurify-html="
                                workLearningTheme?.description
                                  ? workLearningTheme?.description
                                  : ''
                              "
                              class="mb-0 goal-text"
                            />
                          </v-card-text>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                  <IncompleteFieldCard
                    v-if="
                      !currentWorkLearningThemes?.length &&
                      isPdpIncompleteArchived
                    "
                    class="mt-4"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="pt-10"
              cols="auto"
            >
              <div class="px-5 px-md-10">
                <h1 class="headline">Career Goals (optional)</h1>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-for="(goal, index) of careerGoals"
            :key="`plan-read-only-goal-${goal?.id}`"
          >
            <v-col class="my-0 pb-0">
              <div class="mb-0">
                <div
                  class="goal-heading mb-10 px-5 px-md-10 py-5 title ads-grey-01"
                >
                  Goal
                  {{ index + 1 }}:
                  {{ goal.title }}
                </div>
                <div class="pb-5 px-5 px-md-10">
                  <div class="divider">
                    <p class="goal-section-title">
                      <v-icon class="mr-2 ads-blue-2">mdi-star-outline</v-icon>
                      Description
                    </p>
                    <IncompleteFieldCard
                      v-if="
                        !goal?.description?.length && isPdpIncompleteArchived
                      "
                    />
                    <div
                      v-else
                      v-dompurify-html="goal?.description ?? ''"
                      class="goal-text mb-10 body-1"
                    />
                  </div>
                  <div class="">
                    <p class="goal-section-title">
                      <v-icon class="mr-2 ads-blue-2">mdi-calendar-edit</v-icon>
                      Strategies to achieve goals
                    </p>
                    <IncompleteFieldCard
                      v-if="
                        !goal?.strategies?.length && isPdpIncompleteArchived
                      "
                    />
                    <div
                      v-else
                      v-dompurify-html="`${goal.strategies ?? ''}`"
                      class="goal-text mb-10 body-1"
                    />
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="!careerGoals?.length && isPdpIncompleteArchived">
            <v-col class="my-0 pb-0">
              <div class="pb-5 px-5 px-md-10">
                <IncompleteFieldCard v-if="!workGoals?.length" />
              </div>
            </v-col>
          </v-row>
          <v-divider
            v-if="careerGoals.length"
            class="py-5"
          />
          <v-row>
            <v-col class="my-0 pb-0">
              <div class="mb-0 mb-5">
                <div class="pb-5 px-5 px-md-10">
                  <p class="goal-section-title">
                    <v-icon class="mr-2 ads-blue-2">mdi-school-outline</v-icon>
                    Learning and development
                  </p>
                  <IncompleteFieldCard
                    v-if="
                      !section?.careerLearningText?.length &&
                      isPdpIncompleteArchived
                    "
                  />
                  <div
                    v-else
                    v-dompurify-html="`${section.careerLearningText ?? ''}`"
                    class="goal-text body-1"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      class="px-md-10 my-0"
      no-gutters
    >
      <v-col class="py-0">
        <EventLog
          v-if="section?.comments.length || isPdpIncompleteArchived"
          :comments="section.comments"
          :pdp="pdp"
          class="mt-10 px-0 px-md-10 py-8"
        />
        <IncompleteArchiveCard
          v-if="isCurrentSectionArchival && isPdpIncompleteArchived"
          :pdp="pdp"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formatDateTime, formatName } from '@/utils';
import { mapGetters } from 'vuex';
import NTSReviewReadOnly from '@/components/NTSReviewReadOnly.vue';
import EventLog from '@/components/EventLog.vue';
import { GOAL_TYPE, SECTION_TYPE, USER_TYPE } from '@/data/constants';
import IncompleteFieldCard from '@/components/print/IncompleteFieldCard.vue';
import { getIsPdpIncompleteArchived, getPdpSectionOnArchival } from '@/lib/pdp';
import IncompleteArchiveCard from '@/components/IncompleteArchiveCard.vue';

export default {
  name: 'NTSSectionReadOnly',
  components: {
    IncompleteArchiveCard,
    IncompleteFieldCard,
    EventLog,
    NTSReviewReadOnly,
  },
  props: {
    pdp: {
      type: Object,
      default: () => ({}),
    },
    section: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('lookups', ['learningThemes']),
    ...mapGetters(['loggedInUser']),
    isOwner() {
      return this.loggedInUser?.id === this.pdp?.owner?.id;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isPlanSection() {
      return this.section?.sectionType === SECTION_TYPE.plan;
    },
    isMidYearSection() {
      return this.section?.sectionType === SECTION_TYPE.midYear;
    },
    isSectionComplete() {
      return this.section?.complete;
    },
    currentWorkLearningThemes() {
      return this.section?.section_learning_theme_associations?.map(
        (workLearningTheme) =>
          this.learningThemes?.find(
            (theme) => theme.id === workLearningTheme?.learningtheme_id
          )
      );
    },
    workGoals() {
      return this.section?.goals
        ?.filter((goal) => goal.type === GOAL_TYPE.work)
        .sort((a, b) => a.id - b.id);
    },
    careerGoals() {
      return this.section?.goals
        ?.filter((goal) => goal.type === GOAL_TYPE.career)
        .sort((a, b) => a.id - b.id);
    },
    createdDate() {
      return (
        this.formatDateTime(
          this.section?.created_at ?? this.section?.created
        ) ?? ''
      );
    },
    supervisor() {
      return this.pdp?.pdp_user_associations?.find(
        (user) =>
          user.type === USER_TYPE.supervisor &&
          user.user_id === this.section?.supervisor_id
      )?.user;
    },
    manager() {
      return this.pdp?.pdp_user_associations?.find(
        (user) =>
          user.type === USER_TYPE.additionalSupervisor &&
          user.user_id === this.section?.manager_id
      )?.user;
    },
    principal() {
      return this.pdp?.pdp_user_associations?.find(
        (user) =>
          user.type === USER_TYPE.pdpPrincipal &&
          user.user_id === this.section?.principal_id
      )?.user;
    },
    isPdpArchived() {
      return !this.pdp?.active;
    },
    isPdpIncompleteArchived() {
      return getIsPdpIncompleteArchived(this.pdp);
    },
    pdpSectionOnArchival() {
      return getPdpSectionOnArchival(this.pdp);
    },
    isCurrentSectionArchival() {
      return this.pdpSectionOnArchival?.id === this.section.id;
    },
  },
  methods: {
    formatName,
    formatDateTime,
  },
};
</script>

<style lang="scss" scoped>
.goal-heading {
  background-color: rgba(108, 172, 228, 0.2);
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}

.goal-text {
  color: $ads-dark;
  white-space: pre-line;
  font-size: 1rem;
}

.divider::after {
  content: '';
  display: block;
  width: calc(100% + 80px);
  border-top: 1px solid $ads-light-20;
  margin: 16px -40px 32px;
}

.dark-border {
  border-color: $ads-dark-80;
}

.fill-height {
  height: 100%;
}

.image-col {
  // overflow: hidden; /* Hides overflow */
  position: relative; /* Enable positioning of absolutely positioned image */
}

.image-content {
  position: absolute;
  width: auto; /* Adjust width as necessary */
}

:deep .v-card {
  &__title {
    font-size: 24px;
    line-height: 30px;
    word-break: break-word;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
  }

  .v-image {
    bottom: -14px;
  }
}

.goal-section-title {
  color: #000;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
</style>

<template>
  <v-row
    :class="'banner ml-0 pb-6 ' + bannerClass"
    :style="{
      backgroundImage: overrideBackgroundImage
        ? 'url(' +
          backgroundImageUrl +
          ')' /* image will be served from the 'public' folder */
        : overrideBackgroundColour
          ? 'none'
          : '',
      backgroundColor: overrideBackgroundColour ? backgroundColour : '',
    }"
    v-bind="{ 'justify': centered ? 'center' : 'start' }"
  >
    <v-col class="px-0">
      <slot name="content">
        <v-container
          :class="contentClass"
          :fluid="!!$slots.rightPannel"
          class="px-0 d-flex justify-space-between"
        >
          <div class="d-flex align-center">
            <div class="pt-1">
              <slot name="icon">
                <v-icon
                  v-if="icon"
                  :size="iconSize"
                  alt=""
                  class="material-icons-outlined"
                >
                  {{ icon }}
                </v-icon>
              </slot>
            </div>
            <div class="d-flex flex-column">
              <slot name="chip">
                <div
                  class="d-flex flex-wrap align-center"
                  style="min-height: 24px"
                >
                  <Chip
                    v-if="hasChip"
                    :color="chipColor"
                    :text="chipText"
                    :textColor="chipTextColor"
                    class="ml-3 px-5 appChip"
                    small
                  />
                  <span class="ml-3 flex-shrink-1 chip__side-text">
                    {{ chipSideText }}
                  </span>
                </div>
              </slot>
              <slot name="title">
                <h1 class="appTitle">
                  {{ heading }}
                </h1>
              </slot>
              <slot name="subtitle">
                <p
                  v-if="subtitle"
                  class="appSubtitle"
                >
                  {{ subtitle }}
                </p>
              </slot>
            </div>
            <div class="align-self-center info-icon">
              <slot name="info">
                <v-tooltip
                  bottom
                  color="primary"
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-if="info"
                      :size="infoSize"
                      alt=""
                      class="material-icons-outlined"
                      color="blue"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ info }}
                    </v-icon>
                  </template>
                  <span>{{ infoMessage }}</span>
                </v-tooltip>
              </slot>
            </div>
          </div>
          <div>
            <slot name="rightPannel" />
          </div>
        </v-container>
      </slot>
    </v-col>
  </v-row>
</template>

<script>
import { Chip } from '@nswdoe/doe-ui-core';

export default {
  name: 'Banner',
  data() {
    return {
      overrideBackgroundImage: this.backgroundImageUrl != null,
      overrideBackgroundColour:
        this.backgroundImageUrl == null && this.backgroundColour,
    };
  },
  components: { Chip },
  props: {
    icon: {
      type: String,
      default: null,
    },
    hasChip: {
      type: Boolean,
      default: false,
    },
    chipText: {
      type: String,
      default: 'ARCHIVED PDP',
    },
    chipColor: {
      type: String,
      default: '#ebebeb',
    },
    chipTextColor: {
      type: String,
      default: '#333333',
    },
    info: {
      type: String,
      default: null,
    },
    infoSize: {
      type: String,
      default: '30',
    },
    infoMessage: {
      type: String,
      default:
        'This is a tooltip with default message. Please include your conveying message.',
    },
    iconSize: {
      type: String,
      default: '50',
    },
    heading: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    defaultBackground1: {
      type: Boolean,
      default: false,
    },
    defaultBackground2: {
      type: Boolean,
      default: false,
    },
    bgPositionLeft: {
      type: Boolean,
      default: false,
    },
    backgroundColour: {
      type: String,
      default: null,
    },
    bgPositionRight: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    backgroundImageUrl: {
      type: String,
      default: null,
    },
    contentClass: {
      type: String,
      default: 'pl-6',
    },
    chipSideText: {
      type: String,
      default: 'YYYY-MM-DD',
    },
  },
  computed: {
    bannerClass() {
      let className = '';
      if (this.defaultBackground1) {
        className = ' banner1';
      }

      if (this.defaultBackground2) {
        className = 'banner2';
      }

      if (this.bgPositionRight) {
        className += ' background--right';
      }

      if (this.bgPositionLeft) {
        className += ' background--left';
      }

      return className;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@nswdoe/doe-ui-core/styles/variables.scss';

.background--right {
  background-position: right;
}

.background--left {
  background-position: left;
}

.banner {
  background-image: url(./banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: $ads-navy;
  color: $ads-white;
}

.banner1 {
  background-image: url(./banner2.png);
  background-position: right;
}

.banner2 {
  background-image: url(./banner3.png);
  background-position: right;
}

.banner .col {
  padding-top: 30px;
  padding-bottom: 0;
}

.appSubtitle {
  font-size: 16px;
  padding: 0 14px;
  margin-bottom: 0;
}

.appTitle {
  font-size: 40px;
  font-weight: 600;
  display: inline-block;
  padding: 0 12px;
  vertical-align: bottom;
}

.appChip {
  text-align: center;
  font-family: -apple-system, 'Public Sans', 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  display: inline;
}

i.v-icon {
  color: $ads-white;
}

.info-icon {
  padding-bottom: 12px;

  i {
    font-size: 24px;
  }
}
</style>

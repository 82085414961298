<template>
  <v-container class="px-0 pa-md-7">
    <v-row class="mx-0 flex-column">
      <v-col
        class="px-5 px-md-0 no-break-inside"
        md="11"
      >
        <v-container class="px-0 pb-4">
          <v-row class="mx-0">
            <v-col class="pa-0">
              <p>
                A self-assessment provides an opportunity to reflect on teaching
                and/or leadership practice, and on progress towards achievement
                of your PDP goals.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col
        class="px-5 px-md-0"
        md="11"
      >
        <v-container class="pa-0">
          <v-row align="start">
            <v-icon
              :color="ADS_Colors.Blue_2"
              class="ml-3 mr-2"
            >
              mdi-clipboard-outline
            </v-icon>
            <v-col
              v-if="!midYearSection.complete"
              class="pa-0 no-break-inside"
            >
              <IncompleteFieldCard
                v-if="isIncompleteArchivedPdp && !formPdp?.midYearText"
              />
              <RichTextField
                v-else
                v-model="midYearText"
                :disabled="readOnly"
                :rules="[rules.stringFieldRequired]"
                label="Reflect on your progress towards achieving your professional goals."
                lazy
              />
            </v-col>
            <v-col
              v-if="midYearSection.complete"
              class="pa-0 ml-2 no-break-inside"
            >
              <div
                v-dompurify-html="
                  formPdp.midYearText ? formPdp.midYearText : ''
                "
                class="pa-0"
              />
            </v-col>
            <v-col
              v-if="!midYearSection.complete"
              class="text-center flex-shrink-1 flex-grow-0 flex-md-shrink-0"
              md="1"
            >
              <v-tooltip
                v-if="!readOnly"
                v-model="showTooltipMessage"
                bottom
                color="primary"
              >
                <template #activator="{ attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    @click="showTooltipMessage = !showTooltipMessage"
                  >
                    <v-icon
                      color="primary"
                      dark
                    >
                      help_outline
                    </v-icon>
                  </v-btn>
                </template>
                <ul class="ma-0 pa-2">
                  <li>Reflect on your teaching and/or leadership practice</li>
                  <li>Evaluate the impact of professional learning</li>
                  <li>
                    Comment on evidence you have collected to demonstrate your
                    progress
                  </li>
                  <li>
                    Identify any factors impacting your career development and
                    strategies for addressing these.
                  </li>
                </ul>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row
      v-if="
        !midYearSection?.complete &&
        !(isPrint && isIncompleteArchivedPdp && !formPdp?.midYearText)
      "
      class="mx-0"
    >
      <v-col
        class="pl-5 pl-md-0 d-flex flex-row align-center"
        data-test="save-self-assessment"
      >
        <AdsButton
          v-if="!readOnly"
          :disabled="isSaveButtonDisabled"
          buttonText="Save"
          secondary
          @click="save"
        />
        <SaveTimestamp
          v-if="isSaving || isSaved || !isSaveButtonDisabled"
          :updated="pdp.updated_at"
          :pending="!isSaveButtonDisabled"
          class="ml-4 ml-md-4"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="showNotifyBanner"
      class="mx-0"
    >
      <v-col
        class="px-0"
        md="11"
      >
        <v-card class="pa-5">
          <MarkComplete
            :section="midYearSection"
            :showBanner="showNotifyBanner"
            :showEditGoalsInfo="false"
            @onNotifyUser="handleNotifyUser"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-0 px-0 flex-column">
      <v-col
        v-if="midYearSection.comments?.length"
        class="px-0 px-md-0 no-break-inside"
      >
        <EventLog
          :comments="midYearSection.comments"
          :pdp="pdp"
          :section="midYearSection"
          class="px-0 px-md-0 elevation-0"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import validators from '@/lib/validators';
import { formatDateTime, formatName, getFinalSection } from '@/utils.ts';
import { AdsButton } from '@nswdoe/doe-ui-core';
import MarkComplete from './MarkComplete';
import { mapActions, mapGetters } from 'vuex';
import { equals } from 'ramda';
import RichTextField from '@/components/RichTextField';
import IncompleteFieldCard from '@/components/print/IncompleteFieldCard';
import { SECTION_TYPE, USER_TYPE } from '@/data/constants';
import {
  createComment,
  formatCommentMessage,
  formatCommentPrefix,
  resetNotification,
  resetSupervisorSignature,
} from '@/lib/pdp';
import EventLog from '@/components/EventLog.vue';
import SaveTimestamp from '@/components/SaveTimestamp.vue';

export default {
  name: 'TSProgressReport',
  components: {
    SaveTimestamp,
    EventLog,
    RichTextField,
    AdsButton,
    MarkComplete,
    IncompleteFieldCard,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      ADS_Colors,
      showTooltipMessage: false,
      rules: {
        stringFieldRequired: validators.stringFieldRequired,
      },
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'formPdp', 'pdp', 'isPdpArchived']),
    ...mapGetters('pdp', [
      'formPdpUser',
      'isPdpEqual',
      'saveTimeoutId',
      'isSaving',
      'isSaved',
    ]),
    sections() {
      return this.formPdp.sections;
    },
    midYearText: {
      get() {
        return this.formPdp.midYearText;
      },
      set(text) {
        this.formPdp.midYearText = text;
      },
    },
    midYearSection() {
      return this.formPdp?.sections.find((s) => s.sectionType === 'MID_YEAR');
    },
    midYearSupervisor() {
      return this.formPdpUser(SECTION_TYPE.midYear, USER_TYPE.supervisor);
    },
    isSaveButtonDisabled() {
      return !this.isMidYearTextValid || this.isMidYearTextEqual;
    },
    isMidYearTextValid() {
      return validators.stringFieldRequired(this.formPdp?.midYearText) === true;
    },
    isMidYearTextSavedValid() {
      return validators.stringFieldRequired(this.pdp?.midYearText) === true;
    },
    isMidYearTextEqual() {
      return equals(this.formPdp?.midYearText, this.pdp?.midYearText);
    },
    showNotifyBanner() {
      return (
        this.isMidYearTextSavedValid &&
        this.isMidYearTextValid &&
        !this.midYearSection.complete &&
        !this.midYearSection.notifySupervisor &&
        this.isPdpEqual
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isIncompleteArchivedPdp() {
      const finalSection = getFinalSection(this.formPdp?.sections);
      return this.isPdpArchived && !finalSection?.complete;
    },
  },
  watch: {
    midYearText: {
      async handler() {
        if (!this.isSaveButtonDisabled) {
          await this.saveTimeout();
        } else if (this.saveTimeoutId) {
          this.stopSaveTimeout();
        }
      },
      immediate: true,
    },
  },
  methods: {
    formatCommentMessage,
    formatCommentPrefix,
    ...mapActions('pdp', [
      'notifySupervisor',
      'savePdp',
      'startSaveTimeout',
      'stopSaveTimeout',
    ]),
    formatName,
    formatDateTime,
    async handleNotifyUser() {
      const notifyData = createComment(this.formPdp.id, this.midYearSection.id);
      await this.notifySupervisor(notifyData);
    },
    async save() {
      resetNotification(this.midYearSection);
      resetSupervisorSignature(this.midYearSection);
      await this.savePdp(this.formPdp);
    },
    async saveTimeout() {
      resetNotification(this.midYearSection);
      resetSupervisorSignature(this.midYearSection);
      await this.startSaveTimeout(USER_TYPE.owner);
    },
  },
  inject: {
    isPrint: {
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 959px) {
  .comments {
    font-size: 16px;
    word-break: break-word;
  }
}

.no-break-inside {
  break-inside: avoid;
}
</style>

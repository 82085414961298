<template>
  <div>
    <v-card>
      <v-row no-gutters>
        <v-col cols="auto">
          <div class="px-5 px-md-10 pt-9">
            <h1
              v-if="isMidYearSection"
              class="display-1 ads-grey-01"
              v-dompurify-html:plaintext="cardHeading"
            />
            <h1
              v-else-if="isFinalSection"
              class="display-1 ads-grey-01"
              v-dompurify-html:plaintext="cardHeading"
            />
            <p
              class="mt-1 mb-7 body-1 ads-grey-02"
              v-dompurify-html:plaintext="
                `Created: ${formatDateTime(section?.created_at)}`
              "
            />
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="my-0 pb-0">
          <div class="mb-0">
            <div
              v-if="isMidYearSection"
              class="goal-heading mb-10 px-5 px-md-10 py-5 title ads-grey-01"
            >
              Progress report
            </div>
            <div v-if="isMidYearSection">
              <IncompleteFieldCard
                v-if="!pdp?.midYearText && isPdpIncompleteArchived"
                class="mx-5 mx-md-10 mb-10"
              />
              <div
                v-else
                v-dompurify-html="pdp?.midYearText ? pdp?.midYearText : ''"
                class="pb-5 px-5 px-md-10 goal-text body-1 no-break-inside"
              />
            </div>
            <div
              v-if="isMidYearSection"
              class="no-break-inside"
            >
              <div
                class="goal-heading mb-10 px-5 px-md-10 py-5 title ads-grey-01"
              >
                Comments
              </div>
              <IncompleteFieldCard
                v-if="!section?.userComment && isPdpIncompleteArchived"
                class="mx-5 mx-md-10 mb-10"
              />
              <div
                v-else
                v-dompurify-html="section?.userComment ?? ''"
                class="pb-5 px-5 px-md-10 goal-text body-1 no-break-inside"
              />
            </div>
            <div
              v-else-if="isFinalSection"
              class="no-break-inside"
            >
              <div
                class="goal-heading mb-10 px-5 px-md-10 py-5 title ads-grey-01"
              >
                {{ this.isOwner ? 'Your Comment' : "Supervisee's Comment" }}
              </div>
              <IncompleteFieldCard
                v-if="!pdp?.annualText && isPdpIncompleteArchived"
                class="mx-5 mx-md-10 mb-10"
              />
              <div
                v-else
                v-dompurify-html="pdp?.annualText ?? ''"
                class="pb-5 px-5 px-md-10 goal-text body-1"
              />
              <div v-if="pdp?.finalComment || isPdpIncompleteArchived">
                <div
                  class="goal-heading mb-10 px-5 px-md-10 py-5 title ads-grey-01"
                >
                  {{
                    this.isOwner
                      ? 'Your Optional Comment'
                      : "Supervisee's Optional Comment"
                  }}
                </div>
                <IncompleteFieldCard
                  v-if="!pdp?.finalComment && isPdpIncompleteArchived"
                  class="mx-5 mx-md-10 mb-10"
                />
                <div
                  v-else
                  v-dompurify-html="pdp.finalComment ? pdp.finalComment : ''"
                  class="pb-5 px-5 px-md-10 goal-text body-1 no-break-inside"
                />
              </div>

              <div
                v-if="hasSupervisorComment || isPdpIncompleteArchived"
                class="no-break-inside"
              >
                <div
                  class="goal-heading mb-10 px-5 px-md-10 py-5 title ads-grey-01"
                >
                  {{
                    this.isSupervisor ? 'Your Comment' : "Supervisor's Comment"
                  }}
                </div>
                <IncompleteFieldCard
                  v-if="!section?.supervisorComment && isPdpIncompleteArchived"
                  class="mx-5 mx-md-10 mb-10"
                />
                <div
                  v-else
                  v-dompurify-html="section?.supervisorComment ?? ''"
                  class="pb-5 px-5 px-md-10 goal-text body-1 no-break-inside"
                ></div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { formatDateTime, formatName } from '@/utils';
import { mapGetters } from 'vuex';
import { findPdpUserByType, getIsPdpIncompleteArchived } from '@/lib/pdp';
import { COMMENT_TYPE, SECTION_TYPE, USER_TYPE } from '@/data/constants';
import IncompleteFieldCard from '@/components/print/IncompleteFieldCard.vue';

export default {
  name: 'NTSReviewReadOnly',
  components: {
    IncompleteFieldCard,
  },
  props: {
    pdp: {
      type: Object,
      default: () => ({}),
    },
    section: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    sectionSupervisor() {
      return findPdpUserByType(
        this.pdp?.pdp_user_associations,
        USER_TYPE.supervisor
      );
    },
    hasSupervisorComment() {
      const supervisorAnnualComment =
        this.section?.sectionType === SECTION_TYPE.final
          ? this.section?.comments?.find(
              (comment) =>
                comment.type === COMMENT_TYPE.section_comment_supervisor &&
                comment.text === this.section.supervisorComment
            )
          : undefined;
      return this.section?.supervisorComment && !!supervisorAnnualComment;
    },
    isMidYearSection() {
      return this.section.sectionType === SECTION_TYPE.midYear;
    },
    isFinalSection() {
      return this.section.sectionType === SECTION_TYPE.final;
    },
    isOwner() {
      return this.loggedInUser?.id === this.pdp.owner?.id;
    },
    isSupervisor() {
      return this.loggedInUser?.id === this.sectionSupervisor?.user_id;
    },
    cardHeading() {
      if (this.isMidYearSection) {
        if (this.isOwner) {
          return 'Your mid-year self-assessment';
        } else {
          return `${formatName(this.pdp?.owner)}'s mid-year self-assessment`;
        }
      } else if (this.isFinalSection) {
        if (this.isOwner) {
          return 'Your Annual Review';
        } else {
          return `${formatName(this.pdp?.owner)}'s Annual Review`;
        }
      }
      return '';
    },
    isPdpIncompleteArchived() {
      return getIsPdpIncompleteArchived(this.pdp);
    },
  },
  methods: {
    formatDateTime,
    formatName,
  },
};
</script>

<style scoped lang="scss">
.goal-heading {
  background-color: rgba(108, 172, 228, 0.2);
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}

.goal-text {
  color: $ads-dark;
  white-space: pre-line;
  font-size: 1rem;

  span {
    display: inline-block;
  }
}
</style>

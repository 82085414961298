<template>
  <tbody>
    <v-container>
      <TSUserBannerPrint
        :pdp="pdp"
        :user="pdp?.owner"
        backgroundColour="white"
        class="ts-user-banner--print"
      />
      <!-- PLAN SECTION -->
      <v-container class="px-0 pb-5">
        <h1 class="display-1 black--text">Plan</h1>
      </v-container>
      <TSPlanReadOnlyPrint
        :pdp="pdp"
        :section="planSection"
      />
      <!-- GOALS AND EVIDENCE -->
      <v-container class="px-0 py-5 page-break-before">
        <h1 class="display-1 black--text">Goals and evidence</h1>
      </v-container>
      <IncompleteFieldCard
        v-if="isIncompleteArchivedPdp && !midYearSection?.goals?.length"
      />
      <TSPdpGoalsPrint
        v-else
        :editable="false"
        :goals="midYearSection?.goals"
        :isSupervisor="isSupervisor"
        :section="midYearSection"
        expandAll
      />
      <!-- OBSERVATIONS -->
      <v-container class="pa-0 pt-5 page-break-before">
        <h1 class="display-1 black--text">Observations</h1>
      </v-container>
      <IncompleteFieldCard
        v-if="isIncompleteArchivedPdp && pdp?.observations?.length === 0"
        class="my-5"
      />
      <v-card
        v-else
        class="my-5 py-5"
      >
        <TSPdpObservationsPrint
          :editMode="false"
          :pdp="pdp"
        />
      </v-card>
      <!-- REVIEWS -->
      <TSReviewsPrint
        class="page-break-before"
        expandAll
        readOnly
      />
      <!-- ARCHIVE REASON CARD -->
      <IncompleteArchiveCard
        v-if="isIncompleteArchivedPdp"
        :pdp="pdp"
        class="my-5 page-break-before"
      />
      <!-- PERSONAL NOTES -->
      <PersonalNotesCard
        :comments="notes"
        class="my-10 py-0 page-break-before"
      />
    </v-container>
  </tbody>
</template>

<script>
import TSPlanReadOnlyPrint from '@/components/print/TSPlanReadOnlyPrint';
import TSPdpGoalsPrint from '@/components/print/TSPdpGoalsPrint';
import TSPdpObservationsPrint from '@/components/print/TSPdpObservationsPrint';
import TSReviewsPrint from '@/components/print/TSReviewsPrint';
import TSUserBannerPrint from '@/components/print/TSUserBannerPrint';
import PersonalNotesCard from '@/components/print/PersonalNotesCard';
import IncompleteFieldCard from '@/components/print/IncompleteFieldCard';
import IncompleteArchiveCard from '@/components/IncompleteArchiveCard';
import { getFinalSection } from '@/utils';
import { TAB_TYPES } from '@/data/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'TSArchivedPrintContent',
  components: {
    TSPlanReadOnlyPrint,
    TSPdpGoalsPrint,
    TSPdpObservationsPrint,
    TSReviewsPrint,
    PersonalNotesCard,
    TSUserBannerPrint,
    IncompleteFieldCard,
    IncompleteArchiveCard,
  },
  props: {
    pdp: {
      type: Object,
      default: () => ({}),
    },
    planSection: {
      type: Object,
      default: () => ({}),
    },
    midYearSection: {
      type: Object,
      default: () => ({}),
    },
    isSupervisor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TAB_TYPES,
      tabs: [
        TAB_TYPES.plan,
        TAB_TYPES.goalsAndEvidence,
        TAB_TYPES.observations,
        TAB_TYPES.reviews,
      ],
    };
  },
  computed: {
    ...mapGetters('pdp', ['personalNotes']),
    activeSection() {
      if (this.planSection?.complete && this.midYearSection?.complete) {
        return this.actionPdpFinalSection;
      }
      if (this.planSection?.complete) {
        return this.midYearSection;
      }
      return this.planSection;
    },
    // this is so GOALS & EVIDENCE tab is not blank when user prints before having defined plan goals
    midYearSectionGoals() {
      if (this.midYearSection?.goals?.length > 0) {
        return this.midYearSection?.goals;
      } else {
        return this.planSection?.goals;
      }
    },
    isIncompleteArchivedPdp() {
      const finalSection = getFinalSection(this.pdp?.sections);
      return !this.pdp?.active && !finalSection?.complete;
    },
    notes() {
      return this.personalNotes?.text;
    },
  },
  methods: {
    getTabIndexById(tabId) {
      return this.tabs?.findIndex((tab) => tab.id === tabId);
    },
  },
  provide: {
    isPrint: true,
  },
};
</script>

<style lang="scss" scoped>
.ts-user-banner--print {
  &.banner1 {
    color: $ads-grey-01;

    :deep .v-icon {
      color: $ads-grey-01;
    }
  }
}

.page-break-before {
  break-before: page;
}

.v-tabs {
  pointer-events: none;
}

:deep .quillWrapper {
  user-select: none;
}

:deep .goal-heading {
  background-color: $ads-secondary-grey;
}

:deep .v-icon {
  color: $ads-grey-01 !important;
}
</style>

import { render, staticRenderFns } from "./NTSPrintContent.vue?vue&type=template&id=619fa4b7&scoped=true"
import script from "./NTSPrintContent.vue?vue&type=script&lang=js"
export * from "./NTSPrintContent.vue?vue&type=script&lang=js"
import style0 from "./NTSPrintContent.vue?vue&type=style&index=0&id=619fa4b7&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "619fa4b7",
  null
  
)

export default component.exports
<template>
  <tbody>
    <TSUserBannerPrint
      :pdp="actionPdp"
      :user="actionPdp?.owner"
      backgroundColour="white"
      class="ts-user-banner--print"
    />
    <!-- PLAN SECTION -->
    <v-container class="px-0">
      <PrintTabs
        :selectedTab="getTabIndexById(TAB_TYPES.plan.id)"
        :tabs="tabs"
      />
    </v-container>
    <div v-if="canViewSection(TAB_TYPES.plan)">
      <OrientationBanner
        v-if="isCurrentSupervisor && !showTabs"
        :isSigningPrintBanner="isSigningPrintBanner"
        :pdp="actionPdp ?? {}"
        :section="activeSection"
        :supervisorName="supervisorName"
        class="pa-0 mt-3"
        isSupervisor
        readOnly
      />
      <TSPlanReadOnly
        :pdp="actionPdp"
        :section="planSection"
      />
    </div>
    <v-container
      v-else
      class="px-0"
    >
      <NoPermissionCard />
    </v-container>
    <!-- GOALS AND EVIDENCE -->
    <v-container class="px-0 page-break-before">
      <PrintTabs
        :selectedTab="getTabIndexById(TAB_TYPES.goalsAndEvidence.id)"
        :tabs="tabs"
      />
    </v-container>
    <v-container
      v-if="canViewSection(TAB_TYPES.goalsAndEvidence)"
      class="pa-0"
    >
      <v-card class="my-5">
        <v-container
          class="px-0 px-md-10 py-5"
          fluid
        >
          <TSPdpGoalsPrint
            :editable="false"
            :goals="midYearSection.goals"
            :owner="actionPdp?.owner"
            :section="midYearSection"
            :showEditToggle="false"
            :startEdit="false"
            :userIsPrincipal="ownerIsPrincipal"
            expandAll
            isSupervisor
          />
        </v-container>
      </v-card>
    </v-container>
    <v-container
      v-else
      class="px-0"
    >
      <NoPermissionCard />
    </v-container>

    <!-- OBSERVATIONS -->
    <v-container class="px-0 page-break-before">
      <PrintTabs
        :selectedTab="getTabIndexById(TAB_TYPES.observations.id)"
        :tabs="tabs"
      />
      <v-card
        v-if="canViewSection(TAB_TYPES.observations)"
        class="my-5 py-5 page-break"
      >
        <TSPdpObservationsPrint
          :editMode="false"
          :pdp="actionPdp"
        />
      </v-card>
      <NoPermissionCard
        v-else
        class="px-0"
      />
    </v-container>
    <!-- REVIEWS -->
    <v-container class="pa-0 page-break-before">
      <PrintTabs
        :selectedTab="getTabIndexById(TAB_TYPES.reviews.id)"
        :tabs="tabs"
      />
      <div v-if="canViewSection(TAB_TYPES.reviews)">
        <OrientationBanner
          v-if="
            planSection.complete &&
            (isCurrentSupervisor || finalSection.complete)
          "
          :isSigningPrintBanner="isSigningPrintBanner"
          :pdp="actionPdp ?? {}"
          :section="activeSection"
          :supervisorName="supervisorName"
          class="pa-0 mt-3"
          isSupervisor
          readOnly
        />
        <Wrapper
          :isMobile="isMobile"
          cardClass="py-5 px-10"
          class="mt-8 pa-0"
        >
          <h4 class="display-1 pt-5 mb-5 mx-5 mx-md-0">Reviews</h4>
          <AdsExpansionPanel
            v-model="reviewPanels"
            :items="[
              { id: 1, title: 'Mid-Year Self-Assessment' },
              { id: 2, title: 'Annual review' },
            ]"
            multiple
          >
            <template #[`content1`]>
              <v-container class="pa-0 pa-md-5">
                <v-card class="pa-5 elevation-0">
                  <v-card-title class="px-0 px-md-4 text-h6">
                    Review of progress
                  </v-card-title>
                  <div
                    v-dompurify-html="
                      actionPdp.midYearText ? actionPdp.midYearText : ''
                    "
                    class="px-0 px-md-4 text-body-1"
                  />
                  <v-row class="mx-0 px-0 flex-column mt-5">
                    <v-col class="px-0 px-md-0 no-break-inside">
                      <EventLog
                        :comments="midYearSection.comments"
                        :pdp="actionPdp"
                        :section="midYearSection"
                        class="px-0 px-md-0 elevation-0"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-container>
              <v-card
                v-if="!midYearSection.complete"
                class="pa-5"
                flat
              >
                <p class="ml-0 ml-md-5">
                  <strong>Mid Year Self-Assessment</strong>
                  has not yet been completed
                </p>
              </v-card>
            </template>
            <template #[`content2`]>
              <div v-if="isCurrentSupervisor || userIsSchoolPrincipal">
                <v-container class="pa-0 pa-md-5">
                  <v-card class="pa-5 elevation-0">
                    <v-card-title class="px-0 px-md-4 text-h6">
                      Annual review
                    </v-card-title>
                    <div
                      v-dompurify-html="
                        actionPdp.annualText ? actionPdp.annualText : ''
                      "
                      class="px-0 px-md-4 text-body-1"
                    />
                    <v-row class="mx-0 px-0 flex-column mt-5">
                      <v-col class="px-0 px-md-0 no-break-inside">
                        <EventLog
                          :comments="finalSection.comments"
                          :pdp="actionPdp"
                          :section="finalSection"
                          class="px-0 px-md-0 elevation-0"
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-container>
                <v-card
                  v-if="!finalSection?.complete"
                  class="pa-5"
                  flat
                >
                  <p class="ml-0 ml-md-5">
                    <strong>Annual review</strong>
                    has not yet been completed
                  </p>
                </v-card>
              </div>
              <v-container
                v-else
                class="pa-0 pa-md-5"
              >
                <NoPermissionCard />
              </v-container>
            </template>
          </AdsExpansionPanel>
        </Wrapper>
      </div>
      <NoPermissionCard
        v-else
        class="px-0"
      />
    </v-container>
    <!-- PERSONAL NOTES -->
    <PersonalNotesCard
      :comments="supervisorNotes"
      class="my-10 pa-0 page-break-before"
    />
  </tbody>
</template>

<script>
import { ADS_Colors, AdsExpansionPanel } from '@nswdoe/doe-ui-core';
import OrientationBanner from '@/components/OrientationBanner.vue';
import TSUserBannerPrint from '@/components/print/TSUserBannerPrint';
import TSPlanReadOnly from '@/components/TSPlanReadOnly.vue';
import TSPdpGoalsPrint from '@/components/print/TSPdpGoalsPrint.vue';
import TSPdpObservationsPrint from '@/components/print/TSPdpObservationsPrint.vue';
import PrintTabs from '@/components/print/PrintTabs.vue';
import NoPermissionCard from '@/components/print/NoPermissionCard.vue';
import PersonalNotesCard from '@/components/print/PersonalNotesCard';
import Wrapper from '@/components/Wrapper.vue';
import { formatDateTime, formatName } from '@/utils';
import { TAB_TYPES } from '@/data/constants';
import { includes } from 'ramda';
import { mapGetters } from 'vuex';
import EventLog from '@/components/EventLog.vue';

export default {
  name: 'TSSupervisorPrintContent',
  components: {
    EventLog,
    AdsExpansionPanel,
    OrientationBanner,
    TSUserBannerPrint,
    TSPlanReadOnly,
    TSPdpGoalsPrint,
    TSPdpObservationsPrint,
    PrintTabs,
    NoPermissionCard,
    PersonalNotesCard,
    Wrapper,
  },
  props: {
    actionPdp: {
      type: Object,
      default: () => ({}),
    },
    planSection: {
      type: Object,
      default: () => ({}),
    },
    midYearSection: {
      type: Object,
      default: () => ({}),
    },
    finalSection: {
      type: Object,
      default: () => ({}),
    },
    midYearSupervisor: {
      type: Object,
      default: () => ({}),
    },
    finalSupervisor: {
      type: Object,
      default: () => ({}),
    },
    activeSection: {
      type: Object,
      default: () => ({}),
    },
    isCurrentSupervisor: {
      type: Boolean,
      required: true,
    },
    isPlanSupervisor: {
      type: Boolean,
      required: true,
    },
    isMidYearSupervisor: {
      type: Boolean,
      required: true,
    },
    supervisorName: {
      type: String,
      default: '',
    },
    showTabs: {
      validator: (value) => {
        return value === null || typeof value === 'boolean';
      },
      required: true,
    },
    selectedTab: {
      type: String,
      required: true,
    },
    owner: {
      type: Object,
      default: () => {
        return {};
      },
    },
    userIsPrincipal: {
      type: Boolean,
      required: true,
    },
    isSigningPrintBanner: {
      type: Boolean,
      required: true,
    },
    supervisorNotes: {
      type: String,
      default: undefined,
      required: false,
    },
  },
  data() {
    return {
      ADS_Colors,
      TAB_TYPES,
      tabs: [
        TAB_TYPES.plan,
        TAB_TYPES.goalsAndEvidence,
        TAB_TYPES.observations,
        TAB_TYPES.reviews,
      ],
      reviewPanels: [0, 1],
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    availableTabs() {
      return [
        ...(this.isCurrentSupervisor ||
        this.isPlanSupervisor ||
        this.userIsPrincipal
          ? [TAB_TYPES.plan]
          : []),
        ...(this.isCurrentSupervisor ||
        this.isMidYearSupervisor ||
        this.userIsPrincipal
          ? [
              TAB_TYPES.goalsAndEvidence,
              TAB_TYPES.observations,
              TAB_TYPES.reviews,
            ]
          : []),
      ];
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    ownerIsPrincipal() {
      return this.actionPdp?.owner?.isPrincipal;
    },
    userIsSchoolPrincipal() {
      return (
        (this.selectedTab === TAB_TYPES.school.id ||
          this.selectedTab === TAB_TYPES.archived.id) &&
        this.loggedInUser?.isPrincipal &&
        this.loggedInUser?.schoolCode ===
          this.actionPdp?.owner_school?.schoolCode
      );
    },
  },
  methods: {
    getTabIndexById(tabId) {
      return this.tabs.findIndex((tab) => tab.id === tabId);
    },
    canViewSection(tab) {
      return includes(tab, this.availableTabs);
    },
    formatName,
    formatDateTime(date) {
      return formatDateTime(date);
    },
  },
  provide: {
    isPrint: true,
  },
};
</script>

<style lang="scss" scoped>
.page-break-before {
  break-before: page;
}

.v-tabs {
  pointer-events: none;
}

.ts-user-banner--print {
  &.banner1 {
    color: $ads-grey-01;

    :deep .v-icon {
      color: $ads-grey-01;
    }
  }
}

:deep .v-expansion-panel {
  break-inside: avoid;
}
</style>

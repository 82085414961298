var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"mt-5 px-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',{staticClass:"px-md-7 pr-md-16 goal-content",attrs:{"no-gutters":""}},[(_vm.editMode)?_c('v-col',{staticClass:"px-0"},[_c('p',{staticClass:"goal-section-title"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v(" mdi-star-outline ")]),_vm._v(" Goal ")],1),_c('AdsTextField',{staticClass:"ml-9 no-break-inside",attrs:{"counter":false,"data-test":`goal-title-input-${_vm.currentGoal?.index}`,"disabled":_vm.readOnly,"label":"Title (optional)","persistent-placeholder":"","placeholder":"Title (optional)"},model:{value:(_vm.currentGoal.title),callback:function ($$v) {_vm.$set(_vm.currentGoal, "title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"currentGoal.title"}}),_c('RichTextField',{staticClass:"ml-9 no-break-inside",attrs:{"disabled":_vm.readOnly,"placeholder":_vm.type === _vm.GOAL_TYPE.work && !_vm.readOnly
                ? 'Please enter between 50-500 characters, approx. 10-100 words, for each goal.'
                : '',"rules":_vm.validation
                ? [
                    _vm.rules.stringLengthRequired(
                      _vm.WORK_GOAL_DESCRIPTION_MIN_LENGTH
                    ),
                    _vm.rules.maxStringLengthRule(
                      _vm.WORK_GOAL_DESCRIPTION_MAX_LENGTH
                    ),
                  ]
                : [],"label":"Description"},model:{value:(_vm.currentGoal.description),callback:function ($$v) {_vm.$set(_vm.currentGoal, "description", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"currentGoal.description"}})],1):_vm._e(),(!_vm.editMode)?_c('v-col',[_c('strong',{staticClass:"title"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v(" mdi-star-outline ")]),_vm._v(" Description ")],1),(_vm.currentGoal?.description)?_c('v-card',{staticClass:"mx-10 my-5 no-break-inside"},[_c('v-card-text',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:plaintext",value:(_vm.currentGoal?.description),expression:"currentGoal?.description",arg:"plaintext"}],staticClass:"px-10 py-5 ma-0 goal-text"})],1):_vm._e()],1):_vm._e()],1),_c('v-divider',{staticClass:"mb-6"}),_c('v-row',{staticClass:"px-md-7 pr-md-16 goal-content",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-0 px-0 no-break-inside"},[_c('p',{staticClass:"goal-section-title no-break-inside"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v(" mdi-calendar-edit ")]),_vm._v(" Strategies to achieve goals ")],1),(!_vm.isSupervisor && _vm.editMode)?_c('p',{staticClass:"ml-9 ads-dark no-break-inside"},[_vm._v(" What needs to happen for these goals to be met – What actions, by when and who is responsible? ")]):_vm._e(),_c('RichTextField',{staticClass:"ml-9 no-break-inside",attrs:{"disabled":_vm.readOnly,"rules":_vm.validation
                ? [_vm.rules.maxStringLengthRule(_vm.GOAL_STRATEGIES_MAX_LENGTH)]
                : [],"label":"Add details"},model:{value:(_vm.currentGoal.strategies),callback:function ($$v) {_vm.$set(_vm.currentGoal, "strategies", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"currentGoal.strategies"}})],1),(!_vm.editMode)?_c('v-col',{staticClass:"mt-5 no-break-inside"},[_c('strong',{staticClass:"title no-break-inside"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v(" mdi-star-outline ")]),_vm._v(" Strategies to achieve goals ")],1),(_vm.currentGoal?.strategies)?_c('v-card',{staticClass:"mx-10 my-5 no-break-inside"},[_c('v-card-text',{staticClass:"px-10 py-5 ma-0"},[_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:plaintext",value:(_vm.currentGoal?.strategies),expression:"currentGoal?.strategies",arg:"plaintext"}],staticClass:"goal-text"})])],1):_vm._e()],1):_vm._e()],1),(_vm.editMode)?_c('v-row',{staticClass:"goal-buttons",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-2 pr-0 mb-10"},[_c('div',{staticClass:"d-flex flex-row align-center ml-16"},[_c('AdsButton',{attrs:{"disabled":_vm.isSaveButtonDisabled,"buttonText":"Save","data-test":"save-goal","depressed":"","primary":""},on:{"click":_vm.saveGoal}}),(_vm.canDeleteGoal)?_c('AdsButton',{staticClass:"delete-btn ml-8",attrs:{"buttonText":"Remove this goal","color":"primary-red","depressed":"","icon":"mdi-delete-outline","primary":""},on:{"click":function($event){return _vm.handleDeleteGoal()}}}):_vm._e(),(_vm.isSaving || _vm.isSaved || !_vm.isSaveButtonDisabled)?_c('SaveTimestamp',{staticClass:"ml-4 ml-md-4",attrs:{"updated":_vm.pdp.updated_at,"pending":!_vm.isSaveButtonDisabled}}):_vm._e()],1)])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
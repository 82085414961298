<template>
  <v-card class="no-break-inside">
    <v-row
      class="px-5 px-md-0"
      no-gutters
    >
      <v-col>
        <p>
          <b class="title">
            <v-icon
              class="mr-2"
              color="success"
            >
              mdi-message-reply-text-outline
            </v-icon>
            {{ label }}
          </b>
        </p>
      </v-col>
    </v-row>
    <v-card
      v-for="(comment, index) in formattedComments"
      :key="`comment-${index}-${comment?.time}`"
      class="mx-0 mx-md-0 pa-4 mb-5"
    >
      <p
        v-if="comment.text"
        v-dompurify-html:sanitize="comment.text"
        class="pa-0 ma-0"
      />
      <p
        v-dompurify-html:sanitize="
          `<strong>${comment?.role} ${comment?.name} </strong>${
            comment?.action
          } this ${
            comment?.action === 'archived' ? 'PDP' : 'section'
          } on <strong>${comment?.time}</strong>`
        "
        class="pa-0 ma-0"
      />
    </v-card>
  </v-card>
</template>

<script>
import { formatComments } from '@/lib/pdp';
import { PDP_TYPE } from '@/data/constants';

export default {
  name: 'EventLog',
  props: {
    pdp: {
      type: Object,
      default: () => null,
    },
    comments: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    label() {
      if (this.pdp.type === PDP_TYPE.ts) {
        return 'Comments';
      } else return 'Timeline';
    },
    formattedComments() {
      return formatComments(this.comments);
    },
  },
};
</script>

<style lang="scss" scoped></style>

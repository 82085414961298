<template>
  <tbody>
    <v-container class="nts-user-banner--print">
      <UserBanner
        :user="loggedInUser"
        :pdp="pdp"
        isPrint
        backgroundColour="white"
      />
    </v-container>
    <v-container>
      <!-- PLAN SECTION -->
      <h1 class="display-1 black--text">Plan</h1>
      <div class="page-break">
        <NTSSectionReadOnlyPrint
          class="mt-10"
          :pdp="pdp"
          :section="planSection"
        />
      </div>
      <!-- IMPLEMENT SECTION -->
      <h1 class="my-10 display-1 black--text">Implement</h1>
      <div class="page-break">
        <NTSMidYearReviewPrint
          :valid="valid"
          readOnly
        />
      </div>
      <!-- REVIEW SECTION -->
      <div class="page-break">
        <h1 class="my-10 display-1 black--text">Review</h1>
        <NTSAnnualReview
          :valid="valid"
          readOnly
        />
      </div>
      <!-- PERSONAL NOTES -->
      <PersonalNotesCard
        class="my-10 py-0 page-break-before"
        :comments="notes"
      />
    </v-container>
  </tbody>
</template>

<script>
import NTSSectionReadOnlyPrint from '@/components/print/NTSSectionReadOnlyPrint';
import NTSMidYearReviewPrint from '@/components/print/NTSMidYearReviewPrint';
import NTSAnnualReview from '@/components/NTSAnnualReview';
import UserBanner from '@/components/UserBanner';
import PersonalNotesCard from '@/components/print/PersonalNotesCard';
import { mapGetters } from 'vuex';
import { TAB_TYPES } from '@/data/constants';
export default {
  name: 'NTSArchivedPrintContent',
  components: {
    NTSSectionReadOnlyPrint,
    NTSMidYearReviewPrint,
    NTSAnnualReview,
    UserBanner,
    PersonalNotesCard,
  },
  props: {
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TAB_TYPES,
      tabs: [TAB_TYPES.plan, TAB_TYPES.implement, TAB_TYPES.review],
    };
  },
  computed: {
    ...mapGetters('pdp', {
      planSection: 'pdpPlanSection',
      personalNotes: 'personalNotes',
    }),
    ...mapGetters(['pdp', 'formPdp', 'loggedInUser']),
    showStepper() {
      if (this.pdp) return !this.formPdp?.onboardingComplete;
      return undefined;
    },
    showPlanEditor() {
      return this.pdp?.onboardingComplete && !this.planSection?.complete;
    },
    notes() {
      return this.personalNotes?.text;
    },
  },
  methods: {
    getTabIndexById(tabId) {
      return this.tabs.findIndex((tab) => tab.id === tabId);
    },
  },
};
</script>

<style scoped lang="scss">
:deep .v-banner__wrapper {
  border-bottom: none !important;
}
.page-break {
  break-after: page;
}
:deep .nts-user-banner--print {
  user-select: none;

  .v-card {
    background-color: white;
    span {
      color: $ads-grey-01;
    }
  }
  &.banner1 {
    color: $ads-grey-01;
    :deep .v-icon {
      color: $ads-grey-01;
    }
  }
  button {
    display: none;
  }
  .v-avatar {
    background-color: $ads-white;
    margin-left: 0 !important;
  }
  .initials-icon,
  .appTitle,
  .user-role__text {
    color: $ads-grey-01;
  }
  .supervisors-box {
    &__card {
      background-color: $ads-white;
      margin-right: 0 !important;
    }
    &__text {
      color: $ads-grey-01;
    }
  }
  .user-banner.container {
    padding: 0;
  }
}
:deep .goal-buttons,
:deep .nts-add-goal-button,
:deep .save-learning-and-development-button,
:deep .save-annual-review-btn {
  display: none;
}
:deep .ql-editor {
  min-height: auto;
}
:deep .goal-heading {
  background-color: $ads-secondary-grey;
}
:deep .v-icon {
  color: $ads-grey-01 !important;
}

@media print {
  .container.nts-user-banner--print {
    padding: 0 !important;
  }
}
</style>

<template>
  <v-card class="pa-8">
    <div>
      <p>
        <strong class="title">
          <v-icon
            class="mr-2"
            color="success"
          >
            mdi-message-reply-text-outline
          </v-icon>
          PDP Supervisor's Comments
        </strong>
      </p>
    </div>
    <p>
      Include achievements, any challenges, what worked, and any areas for
      future development.
    </p>
    <RichTextField
      v-model.trim="supervisorComment"
      :disabled="readOnly"
      :placeholder="!readOnly ? 'Add a comment...' : null"
      :rules="[rules.stringLengthRequired(ANNUAL_REVIEW_MIN_LENGTH)]"
      label="Add details"
    />
    <div class="d-flex flex-row align-center">
      <AdsButton
        :disabled="!hasCommentChanged"
        buttonText="Save"
        icon="mdi-arrow-right"
        @click.prevent="handleSaveAnnualReviewSupervisorComment"
      />
      <SaveTimestamp
        v-if="isSaving || isSaved || hasCommentChanged"
        :pending="hasCommentChanged"
        :updated="originalActionPdp.updated_at"
        class="ml-4 d-flex flex-row align-center"
      />
    </div>

    <v-card
      v-if="displayNotifyPanel"
      class="mt-8 pa-10"
    >
      <p>
        Confirm you are ready to make your annual review comments available to:
      </p>
      <div class="d-flex mb-7">
        <InitialsIcon
          :givenName="superviseeDetails.displayedName"
          class="mr-4"
          size="42px"
        />
        <div>
          <div>
            <strong>{{ superviseeDetails.displayedName }}</strong>
          </div>
          <strong>{{ superviseeDetails.role }}</strong>
        </div>
      </div>
      <AdsButton
        buttonText="Proceed"
        icon="mdi-arrow-right"
        type="submit"
        @click.prevent="handleProceed"
      />
    </v-card>
  </v-card>
</template>

<script>
import { AdsButton, InitialsIcon } from '@nswdoe/doe-ui-core';
import validators from '@/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import { equals } from 'ramda';
import { formatName } from '@/utils';
import {
  ANNUAL_REVIEW_MAX_LENGTH,
  ANNUAL_REVIEW_MIN_LENGTH,
  COMMENT_TYPE,
  USER_TYPE,
} from '@/data/constants';
import {
  createComment,
  resetPrincipalSignature,
  resetSupervisorSignature,
  resetUserSignature,
} from '@/lib/pdp';
import RichTextField from '@/components/RichTextField.vue';
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import SaveTimestamp from '@/components/SaveTimestamp.vue';

export default {
  name: 'NTSSupervisorAnnualReviewComment',
  components: {
    SaveTimestamp,
    RichTextField,
    AdsButton,
    InitialsIcon,
  },
  data() {
    return {
      ADS_Colors,
      rules: {
        maxStringLength: validators.maxStringLength,
        stringLengthRequired: validators.stringLengthRequired,
        maxStringLengthRule: validators.maxStringLengthRule,
      },
      ANNUAL_REVIEW_MIN_LENGTH,
      ANNUAL_REVIEW_MAX_LENGTH,
    };
  },
  props: {
    isPanelOn: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    supervisorComment: {
      handler() {
        if (this.hasCommentChanged && this.isPdpLoaded) {
          this.autosave();
        } else if (this.saveTimeoutId) {
          this.stopAutosave();
        }
        this.$emit('proceed', false);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['actionPdp', 'originalActionPdp', 'isPdpLoaded']),
    ...mapGetters('pdp', [
      'actionPdpFinalSection',
      'originalActionPdpFinalSection',
      'saveTimeoutId',
      'isSaving',
      'isSaved',
    ]),
    supervisorComment: {
      get() {
        return this.actionPdpFinalSection.supervisorComment;
      },
      set(value) {
        this.actionPdpFinalSection.supervisorComment = value;
      },
    },
    superviseeDetails() {
      const supervisee = this.actionPdp?.owner;
      return {
        givenName: supervisee?.staffGivenName,
        surname: supervisee?.staffFamilyName,
        displayedName: formatName(supervisee),
        role: this.actionPdp?.userPosition,
      };
    },
    hasCommentChanged() {
      return (
        !(
          !this.originalActionPdpFinalSection?.supervisorComment &&
          !this.actionPdpFinalSection?.supervisorComment
        ) &&
        !equals(
          this.originalActionPdpFinalSection?.supervisorComment,
          this.actionPdpFinalSection?.supervisorComment
        )
      );
    },
    displayNotifyPanel() {
      return (
        this.actionPdpFinalSection?.supervisorComment &&
        !this.hasCommentChanged &&
        !this.isPanelOn &&
        this.actionPdpFinalSection?.notifySupervisor &&
        !this.actionPdpFinalSection.supervisorSignature
      );
    },
  },
  methods: {
    ...mapActions('pdp', [
      'saveActionPdp',
      'startSaveTimeout',
      'stopSaveTimeout',
      'commentOnPdp',
    ]),
    resetSectionSignatures() {
      resetSupervisorSignature(this.actionPdpFinalSection);
      resetPrincipalSignature(this.actionPdpFinalSection);
      resetUserSignature(this.actionPdpFinalSection);
    },
    async handleSaveAnnualReviewSupervisorComment() {
      this.resetSectionSignatures();
      await this.saveActionPdp(this.actionPdp);
      this.$emit('proceed', false);
    },
    async handleProceed() {
      await this.handleSupervisorComment();
      this.$emit('proceed', true);
    },
    async handleSupervisorComment() {
      const signData = createComment(
        this.actionPdp.id,
        this.actionPdpFinalSection.id,
        COMMENT_TYPE.section_comment_supervisor,
        this.supervisorComment
      );
      await this.commentOnPdp(signData);
    },
    async autosave() {
      this.resetSectionSignatures();
      await this.startSaveTimeout(USER_TYPE.supervisor);
      this.$emit('proceed', false);
    },
    stopAutosave() {
      this.stopSaveTimeout();
    },
  },
};
</script>

<template>
  <v-container class="pa-0 px-md-10 my-10">
    <SignaturePanel
      v-if="
        isMidyearReviewEqual &&
        showSignaturePanel &&
        !formPdpMidYearSection.complete &&
        !isPdpArchived
      "
      :disableSign="!isSectionValid"
      :owner="formPdp?.owner"
      :section="formPdpMidYearSection"
      :users="formPdp?.pdp_user_associations"
      class="mb-10"
      @signed="handleSignedForm"
    />
    <v-card
      v-if="!formPdpMidYearSection.complete && !isPdpArchived"
      class="py-10 px-0"
    >
      <div class="py-4 px-md-10 display-1 ads-grey-01">
        Mid-year self-assessment
      </div>
      <v-row class="px-md-10">
        <v-col class="ads-grey-01">
          <p>
            Reflect on your progress against your goals (PLAN Parts A and B).
          </p>
          <p>
            Work with your PDP Supervisor to ensure you are on track with your
            plan and/or to identify any barriers, challenges or successes.
          </p>
        </v-col>
        <v-col>
          <v-card
            class="px-6 pt-5 pb-md-0 rounded-12"
            color="primary-teal"
            elevation="0"
          >
            <v-row
              class="d-flex"
              no-gutters
            >
              <v-col
                class="flex-grow-1 pb-7 pb-md-8 pr-2"
                cols="9"
              >
                <v-card-title
                  class="pa-0 pb-3 font-weight-bold primary-blue--text"
                >
                  Useful links
                </v-card-title>
                <v-card-text class="d-flex px-0 grey-01--text font-weight-bold">
                  <a
                    class="subtitle-1"
                    href="https://education.nsw.gov.au/inside-the-department/human-resources/performance/non-teaching-staff-in-schools/meaningful-conversations"
                    target="_blank"
                  >
                    Meaningful conversations to help us be at our best
                    <v-icon class="ml-2 ads-primary-blue">
                      mdi-exit-to-app
                    </v-icon>
                  </a>
                </v-card-text>
              </v-col>
              <v-col
                v-if="!isMobile"
                class="d-flex justify-end align-end image-col"
              >
                <v-img
                  class="fill-height image-content"
                  max-height="115px"
                  max-width="116px"
                  src="../../public/gender-neutral-clipboard.svg"
                ></v-img>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <div class="mt-6 px-md-10">
        <div>
          <p class="mysa-section-title">
            <v-icon
              class="mr-2"
              color="success"
            >
              mdi-content-paste
            </v-icon>
            Progress Report
          </p>
        </div>
        <p class="body-1">
          What has been achieved? What is still to be completed for each
          identified goal? Think about your development activities, have you
          addressed the capabilities identified?
        </p>
        <RichTextField
          v-model.trim="midYearText"
          :disabled="readOnly"
          :rules="[rules.stringLengthRequired(NTS_PROGRESS_REPORT_MIN_LENGTH)]"
          label="Add details"
          lazy
          placeholder="Please enter between 50 characters or more, approx. 10-500 words."
          @validated="handleMidYearTextValidated"
        />
        <SaveTimestamp
          v-if="isSaving || isSaved || !this.isMidyearReviewEqual"
          :updated="pdp.updated_at"
          :pending="!this.isMidyearReviewEqual"
          class="d-flex flex-row align-center mb-4"
        />
      </div>
      <v-divider />
      <div class="mt-6 px-md-10">
        <div>
          <p class="mysa-section-title">
            <v-icon
              class="mr-2"
              color="success"
            >
              mdi-message-reply-text-outline
            </v-icon>
            Comments
          </p>
        </div>
        <p class="body-1">
          Briefly note if there are any changes to goals and associated
          activities, goals that are no longer required and any new goals, and
          reasons for the changes. Identify any factors impacting achievement of
          goals and options for addressing these.
        </p>
        <RichTextField
          v-model.trim="reviewComment"
          :disabled="readOnly"
          label="Add details"
        />
      </div>
      <div class="px-md-10 d-flex flex-row">
        <AdsButton
          :disabled="isMidyearReviewEqual"
          buttonText="Update"
          icon="mdi-arrow-right"
          @click="handleUpdateMidYearReviewDetails"
        />
        <SaveTimestamp
          v-if="isSaving || isSaved || !this.isMidyearReviewEqual"
          :updated="pdp.updated_at"
          :pending="!this.isMidyearReviewEqual"
          class="ml-4 d-flex flex-row align-center"
        />
      </div>
    </v-card>
    <v-card
      v-if="showNotifySupervisor"
      class="mt-10 pa-10"
    >
      <p class="body-1 ads-grey-01">
        Confirm you are ready to make your mid-year self-assessment available
        to:
      </p>
      <div
        v-for="pdpUser in pdpUsers"
        :key="pdpUser.id"
        class="d-flex mb-7"
      >
        <InitialsIcon
          :givenName="pdpUser.displayedName"
          class="mr-md-4"
          size="42px"
        ></InitialsIcon>
        <div class="body-1 ads-grey-01">
          <div>
            <strong>{{ pdpUser.displayedName }}</strong>
          </div>
          <div>
            {{ pdpUser.pdpRole }}
          </div>
        </div>
      </div>
      <p class="mb-8 body-1 ads-grey-01">
        You will still be able to make changes to your mid-year self-assessment
        until it is signed.
      </p>
      <AdsButton
        buttonText="Notify supervisor"
        icon="mdi-arrow-right"
        type="submit"
        @click.prevent="handleNotifySupervisor"
      />
    </v-card>
    <v-row
      v-if="!formPdpFinalSection?.complete && !isPdpArchived"
      class="flex-column"
      no-gutters
    >
      <v-col v-if="formPdpMidYearSection?.complete">
        <NTSReviewReadOnly
          :pdp="formPdp"
          :section="formPdpMidYearSection"
        />
      </v-col>
      <v-col>
        <v-card class="pa-10 my-10">
          <NTSGoals
            :readOnly="readOnly"
            :section="formPdpMidYearSection"
            :type="GOAL_TYPE.work"
            validation
            @autosave="autosave"
            @goalsAltered="handleUpdateGoals"
            @stopAutosave="stopAutosave"
          />
          <h4 class="display-1 mt-10 mb-5 mx-md-0">Career goals</h4>
          <NTSGoals
            :readOnly="readOnly"
            :section="formPdpMidYearSection"
            :type="GOAL_TYPE.career"
            validation
            @autosave="autosave"
            @goalsAltered="handleUpdateGoals"
            @stopAutosave="stopAutosave"
          />
        </v-card>
      </v-col>
      <v-col>
        <EventLog
          v-if="formPdpMidYearSection?.comments?.length || isPdpArchived"
          :comments="formPdpMidYearSection.comments"
          :pdp="pdp"
          class="px-0 px-md-10 py-8"
        />
      </v-col>
    </v-row>
    <v-row
      v-else
      :class="{ 'mx-n10': !readOnly }"
      class="my-10 mx-n10"
      no-gutters
    >
      <v-col>
        <NTSSectionReadOnly
          v-if="!readOnly"
          :pdp="formPdp"
          :section="formPdpMidYearSection"
        />
        <NTSSectionReadOnlyPrint
          v-else
          :pdp="formPdp"
          :section="formPdpMidYearSection"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AdsButton, InitialsIcon } from '@nswdoe/doe-ui-core';
import { mapActions, mapGetters } from 'vuex';
import { equals } from 'ramda';
import SignaturePanel from '@/components/SignaturePanel.vue';
import NTSGoals from '@/components/NTSGoals.vue';
import { getDisplayUsers } from '@/utils';
import NTSReviewReadOnly from '@/components/NTSReviewReadOnly.vue';
import NTSSectionReadOnly from '@/components/NTSSectionReadOnly.vue';
import NTSSectionReadOnlyPrint from '@/components/print/NTSSectionReadOnlyPrint.vue';
import EventLog from '@/components/EventLog.vue';
import validators from '@/lib/validators';
import {
  COMMENT_TYPE,
  COMMENTS_MAX_LENGTH,
  COMMENTS_MIN_LENGTH,
  GOAL_TYPE,
  NTS_PROGRESS_REPORT_MAX_LENGTH,
  NTS_PROGRESS_REPORT_MIN_LENGTH,
  USER_TYPE,
} from '@/data/constants';
import {
  isLearningThemeAssociationsValid,
  isNtsGoalsValid,
} from '@/lib/dataValidation';
import {
  createComment,
  resetNotification,
  resetPrincipalSignature,
  resetSupervisorSignature,
  resetUserSignature,
} from '@/lib/pdp';
import RichTextField from '@/components/RichTextField.vue';
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import SaveTimestamp from '@/components/SaveTimestamp.vue';

export default {
  name: 'NTSMidYearReview',
  components: {
    SaveTimestamp,
    RichTextField,
    NTSReviewReadOnly,
    AdsButton,
    InitialsIcon,
    SignaturePanel,
    EventLog,
    NTSGoals,
    NTSSectionReadOnly,
    NTSSectionReadOnlyPrint,
  },
  props: {
    valid: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMidYearTextValid: false,
      GOAL_TYPE,
      ADS_Colors,
      rules: {
        maxStringLength: validators.maxStringLength,
        stringLengthRequired: validators.stringLengthRequired,
      },
      NTS_PROGRESS_REPORT_MIN_LENGTH,
      NTS_PROGRESS_REPORT_MAX_LENGTH,
      COMMENTS_MIN_LENGTH,
      COMMENTS_MAX_LENGTH,
    };
  },
  computed: {
    ...mapGetters([
      'formPdp',
      'pdp',
      'isPdpArchived',
      'isPdpIncompleteArchived',
    ]),
    ...mapGetters('pdp', [
      'pdpMidYearSection',
      'formPdpMidYearSection',
      'formPdpFinalSection',
      'isPdpEqual',
      'saveTimeoutId',
      'isSaving',
      'isSaved',
    ]),
    midYearText: {
      get() {
        return this.formPdp.midYearText;
      },
      set(value) {
        this.formPdp.midYearText = value;
      },
    },
    pdpUsers() {
      return getDisplayUsers(this.pdp.pdp_user_associations);
    },
    reviewComment: {
      get() {
        return this.formPdpMidYearSection?.userComment || '';
      },
      set(value) {
        this.formPdpMidYearSection.userComment = value;
      },
    },
    showSignaturePanel() {
      return this.formPdpMidYearSection?.notifySupervisor && this.isPdpEqual;
    },
    showNotifySupervisor() {
      return (
        !this.formPdpMidYearSection?.notifySupervisor &&
        this.isPdpEqual &&
        this.isMidYearTextValid &&
        !this.isPdpArchived
      );
    },
    originalComment() {
      return this.pdpMidYearSection?.userComment || '';
    },
    isMidyearReviewEqual() {
      return (
        equals(this.originalComment, this.reviewComment) &&
        equals(this.pdp?.midYearText, this.formPdp?.midYearText)
      );
    },
    isSectionValid() {
      return (
        this.isMidYearTextValid &&
        isNtsGoalsValid(this.formPdpMidYearSection) &&
        isLearningThemeAssociationsValid(
          this.formPdpMidYearSection?.section_learning_theme_associations
        )
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    supervisor() {
      return this.pdp?.pdp_user_associations?.find(
        (user) =>
          user.type === USER_TYPE.supervisor &&
          user.user_id === this.formPdpMidYearSection?.supervisor_id
      )?.user;
    },
    manager() {
      return this.pdp?.pdp_user_associations?.find(
        (user) =>
          user.type === USER_TYPE.additionalSupervisor &&
          user.user_id === this.formPdpMidYearSection?.manager_id
      )?.user;
    },
    principal() {
      return this.pdp?.pdp_user_associations?.find(
        (user) =>
          user.type === USER_TYPE.pdpPrincipal &&
          user.user_id === this.formPdpMidYearSection?.principal_id
      )?.user;
    },
  },
  watch: {
    midYearText: {
      async handler() {
        if (!this.isMidyearReviewEqual) {
          this.resetSectionNotification();
          this.autosave();
        } else if (this.saveTimeoutId) {
          this.stopAutosave();
        }
      },
      immediate: true,
    },
    reviewComment: {
      async handler() {
        if (!this.isMidyearReviewEqual) {
          this.resetSectionNotification();
          this.autosave();
        } else if (this.saveTimeoutId) {
          this.stopAutosave();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('pdp', [
      'savePdp',
      'notifySupervisor',
      'startSaveTimeout',
      'stopSaveTimeout',
    ]),
    resetSectionNotification() {
      resetSupervisorSignature(this.formPdpMidYearSection);
      resetPrincipalSignature(this.formPdpMidYearSection);
      resetUserSignature(this.formPdpMidYearSection);
      resetNotification(this.formPdpMidYearSection);
    },
    async handleUpdateMidYearReviewDetails() {
      this.resetSectionNotification();
      await this.savePdp(this.formPdp);
    },
    async handleNotifySupervisor() {
      if (this.formPdpMidYearSection) {
        const notifyData = createComment(
          this.formPdp.id,
          this.formPdpMidYearSection.id,
          COMMENT_TYPE.section_notify_supervisor,
          this.reviewComment
        );
        await this.notifySupervisor(notifyData);
        this.$emit('validate');
      }
      this.$vuetify.goTo(0);
    },
    async handleUpdateGoals() {
      await this.savePdp(this.formPdp);
      this.$emit('validate');
    },
    async autosave() {
      await this.startSaveTimeout(USER_TYPE.owner);
      this.$emit('validate');
    },
    stopAutosave() {
      this.stopSaveTimeout();
    },
    async handleSignedForm(sectionToBeUpdated) {
      this.$emit('signed', sectionToBeUpdated);
    },
    handleMidYearTextValidated(value) {
      this.isMidYearTextValid = value;
    },
  },
  emits: ['signed', 'validate'],
};
</script>

<style lang="scss" scoped>
.v-divider {
  min-width: 100%;
}

.goal-heading {
  background-color: rgba(108, 172, 228, 0.2);
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}

.goal-text {
  color: $ads-dark;
  white-space: pre-line;
  font-size: 1rem;
}

:deep .v-messages:not(.error--text) {
  color: $ads-navy;
  font-size: 16px;
}

.rounded-12 {
  border-radius: 12px;
}

.fill-height {
  height: 100%;
}

.image-col {
  position: relative; /* Enable positioning of absolutely positioned image */
}

.image-content {
  position: absolute;
  width: auto; /* Adjust width as necessary */
}

:deep .v-card {
  &__title {
    font-size: 24px;
    line-height: 30px;
    word-break: break-word;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
  }

  .v-image {
    bottom: -14px;
  }
}

.mysa-section-title {
  color: #000;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
</style>

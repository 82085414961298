import { LookupState, RootState } from '@/store/types';
import { LearningTheme } from '@/schema';
import { ActionContext } from 'vuex';
import api from '@/api';

export default {
  namespaced: true,
  state: {
    learningThemes: undefined,
  } as LookupState,
  mutations: {
    SET_LEARNINGTHEMES(state: LookupState, learningThemes: LearningTheme[]) {
      state.learningThemes = learningThemes;
    },
  },
  actions: {
    async setLookups({ commit }: ActionContext<RootState, RootState>) {
      commit('SET_IS_LOADING', true, { root: true });
      const lookups = await api.lookups.getLookups();
      if (lookups) {
        commit(
          'SET_LEARNINGTHEMES',
          lookups?.learningThemes?.sort((a, b) => {
            switch ('Other') {
              case a.name:
                return 1;
              case b.name:
                return -1;
              default:
                return 0;
            }
          })
        );
      }
      commit('SET_IS_LOADING', false, { root: true });
    },
  },
  getters: {
    learningThemes: (state: LookupState) => {
      return state.learningThemes;
    },
  },
};

<template>
  <v-container class="my-10 pa-0">
    <SignaturePanel
      v-if="showSignaturePanel"
      :disableSign="!isSectionValid"
      :owner="formPdp?.owner"
      :section="formPdpPlanSection"
      :users="formPdp?.pdp_user_associations"
      class="pa-0 mt-10"
      @signed="handleSignedForm"
    />
    <v-card class="pa-10 mt-9">
      <v-form
        ref="planEditorForm"
        v-model="isFormValid"
        lazy-validation
      >
        <NTSGoals
          :editable="true"
          :readOnly="readOnly"
          :section="formPdpPlanSection"
          :type="GOAL_TYPE.work"
          :userIsPrincipal="loggedInUser.isPrincipal"
          validation
          @autosave="autosave"
          @goalsAltered="resetForm"
          @stopAutosave="stopAutosave"
        />
        <div class="mt-12">
          <h2 class="mb-3">Career Goals (optional)</h2>
          <NTSGoals
            :editable="true"
            :readOnly="readOnly"
            :section="formPdpPlanSection"
            :type="GOAL_TYPE.career"
            :userIsPrincipal="loggedInUser.isPrincipal"
            planEditor
            validation
            @autosave="autosave"
            @goalsAltered="resetForm"
            @stopAutosave="stopAutosave"
          />
        </div>
      </v-form>
    </v-card>
    <v-card
      v-if="showNotifySupervisor"
      class="mt-8 pa-10"
    >
      <p class="body-1 ads-grey-01">
        Confirm you are ready to make your PDP available to:
      </p>
      <div
        v-for="pdpUser in pdpUsers"
        :key="pdpUser.id"
        class="d-flex mb-7"
      >
        <InitialsIcon
          :class="readOnly ? 'mr-4' : 'mr-md-4'"
          :givenName="pdpUser.displayedName"
          size="42px"
        ></InitialsIcon>
        <div class="ads-grey-01">
          <div>
            <strong>{{ pdpUser.displayedName }}</strong>
          </div>
          <div>
            {{ pdpUser.pdpRole }}
          </div>
        </div>
      </div>
      <p class="mb-8 body-1 ads-grey-01">
        You will still be able to make changes to your plan after it has been
        submitted. Once your PDP has been signed off by both you and your PDP
        supervisor, you can proceed to the
        <strong>Implement</strong>
        phase.
      </p>
      <AdsButton
        buttonText="Notify supervisor"
        icon="mdi-arrow-right"
        type="submit"
        @click="handleNotifySupervisor"
      />
    </v-card>
    <EventLog
      :comments="formPdpPlanSection.comments"
      :pdp="pdp"
      class="my-10 px-0 px-md-10 py-8"
    />
  </v-container>
</template>

<script>
import NTSGoals from '@/components/NTSGoals.vue';
import { mapActions, mapGetters } from 'vuex';
import { AdsButton, InitialsIcon } from '@nswdoe/doe-ui-core';
import SignaturePanel from '@/components/SignaturePanel.vue';
import { equals } from 'ramda';
import { getDisplayUsers } from '@/utils';
import { GOAL_TYPE, USER_TYPE } from '@/data/constants';
import {
  createComment,
  resetNotification,
  resetPrincipalSignature,
  resetSupervisorSignature,
  resetUserSignature,
} from '@/lib/pdp';
import {
  isLearningThemeAssociationsValid,
  isNtsGoalsValid,
} from '@/lib/dataValidation';
import EventLog from '@/components/EventLog.vue';

export default {
  name: 'NTSPlanEditor',
  components: {
    EventLog,
    SignaturePanel,
    NTSGoals,
    AdsButton,
    InitialsIcon,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      GOAL_TYPE,
      isFormValid: true,
      isValidatedOnStartup: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'formPdp', 'pdp']),
    ...mapGetters('formValidation', ['allGoalsValid', 'validationGoals']),
    ...mapGetters('pdp', ['formPdpPlanSection', 'isPdpEqual']),
    pdpUsers() {
      return getDisplayUsers(this.pdp.pdp_user_associations);
    },
    showSignaturePanel() {
      return this.formPdpPlanSection?.notifySupervisor && this.isPdpEqual;
    },
    showNotifySupervisor() {
      return !this.formPdpPlanSection?.notifySupervisor && this.isPdpEqual;
    },
    hasPdpBeenUpdated() {
      return equals(this.pdp, this.formPdp);
    },
    workGoals() {
      return this.formPdpPlanSection.goals.filter(
        (item) => item.type === GOAL_TYPE.work
      );
    },
    isSectionValid() {
      return (
        isNtsGoalsValid(this.formPdpPlanSection) &&
        isLearningThemeAssociationsValid(
          this.formPdpPlanSection?.section_learning_theme_associations
        )
      );
    },
  },
  watch: {
    isFormValid: {
      handler() {
        if (this.$refs.planEditorForm && !this.isValidatedOnStartup) {
          this.validateForm();
          this.isValidatedOnStartup = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('pdp', [
      'savePdp',
      'notifySupervisor',
      'startSaveTimeout',
      'stopSaveTimeout',
    ]),
    async handleNotifySupervisor() {
      if (!this.readOnly) {
        if (this.formPdpPlanSection) {
          const notifyData = createComment(
            this.formPdp.id,
            this.formPdpPlanSection.id
          );
          await this.notifySupervisor(notifyData);
          this.validateForm();
        }
      }
      this.$vuetify.goTo(0);
    },
    async handleSignedForm(sectionToBeUpdated) {
      this.$emit('planSigned', sectionToBeUpdated);
    },
    validateForm() {
      this.$refs.planEditorForm.validate();
    },
    async resetForm() {
      if (this.formPdpPlanSection) {
        resetSupervisorSignature(this.formPdpPlanSection);
        resetPrincipalSignature(this.formPdpPlanSection);
        resetUserSignature(this.formPdpPlanSection);
        resetNotification(this.formPdpPlanSection);
        await this.savePdp(this.formPdp);
        this.validateForm();
      }
    },
    async autosave() {
      if (this.formPdpPlanSection) {
        resetSupervisorSignature(this.formPdpPlanSection);
        resetPrincipalSignature(this.formPdpPlanSection);
        resetUserSignature(this.formPdpPlanSection);
        resetNotification(this.formPdpPlanSection);
        await this.startSaveTimeout(USER_TYPE.owner);
        this.validateForm();
      }
    },
    stopAutosave() {
      this.stopSaveTimeout();
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 959px) {
  :deep .v-expansion-panel {
    &-header.panel {
      padding-left: 20px;
      padding-right: 20px;
    }

    .v-divider {
      max-width: none;
      margin-left: -20px;
      margin-right: -20px;
    }
  }
}
</style>

<template>
  <div>
    <span v-if="isSaving">
      <v-progress-circular
        :size="20"
        :width="3"
        color="grey"
        indeterminate
      />
      <span class="ml-2 ads-grey-02">Saving</span>
    </span>
    <span v-else-if="pending">
      <v-icon
        :color="ADS_Colors.Info_Blue_Dark"
        class=""
      >
        mdi-check-circle
      </v-icon>
      <span class="ads-grey-02">
        Pending - Last Saved
        {{ formatDateTimeSlash(updated) }}
      </span>
    </span>
    <span v-else-if="isSaved">
      <v-icon
        :color="ADS_Colors.Success_Green"
        class=""
      >
        mdi-check-circle
      </v-icon>
      <span class="ads-grey-02">
        Saved
        {{ formatDateTimeSlash(updated) }}
      </span>
    </span>
  </div>
</template>
<script>
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import { mapGetters } from 'vuex';
import { formatDateTimeSlash } from '@/utils';

export default {
  name: 'SaveTimestamp',
  props: {
    pending: {
      type: Boolean,
      default: false,
    },
    updated: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      ADS_Colors,
    };
  },
  computed: {
    ...mapGetters('pdp', ['isSaving', 'isSaved']),
  },
  methods: {
    formatDateTimeSlash,
  },
};
</script>

<template>
  <Banner
    :chipColor="ADS_Colors.White"
    :chipSideText="archiveDate"
    :chipText="pdpPhase"
    :chipTextColor="ADS_Colors.Custom_Navy_3"
    :hasChip="!!pdp"
    :heading="formatName(user)"
    :user="user"
    class="banner1 pa-0 ma-0"
    contentClass=""
    v-bind="$attrs"
  >
    <template #icon>
      <InitialsIcon
        :givenName="user?.staffGivenName"
        :size="isMobile ? '60px' : '90px'"
        :surname="user?.staffFamilyName"
        class="ml-md-6"
      />
    </template>
    <template #subtitle>
      <div class="ml-3 user-role__text">
        {{ userPosition }}
      </div>
      <div class="ml-3 user-role__text">
        <strong>School:</strong>
        {{ schoolLabel }}
      </div>
      <div
        v-if="!!pdp?.date_commenced"
        class="ml-3 user-role__text"
      >
        <strong>Date Commenced:</strong>
        {{ formatDate(pdp.date_commenced) }}
      </div>
      <div
        v-if="!!pdp?.date_completed"
        class="ml-3 user-role__text"
      >
        <strong>Date Completed:</strong>
        {{ formatDate(pdp.date_completed) }}
      </div>
      <div
        v-if="isOnBoardingComplete && isMobile"
        class="mt-2 pa-0 d-flex flex-column justify-center align-center"
      >
        <AdsButton
          v-if="isOwner && !pdp.finalCommentLocked"
          buttonText="Edit details"
          class="ads-tertiary-blue-background ads-grey-01 height-36"
          icon="mdi-account-edit-outline"
          @click="editPdpUsers"
        />
      </div>
    </template>
    <template
      v-if="
        ((!isMobile || isPrint) && isPdpSupervised) || isPdpIncompleteArchived
      "
      #rightPannel
    >
      <v-card class="mr-15 d-flex flex-column right-panel">
        <div class="py-3 px-5">
          <v-row
            class="flex-nowrap"
            no-gutters
          >
            <v-col
              class="d-flex flex-column mr-3"
              cols="auto"
            >
              <span
                v-for="item in cardContent"
                :key="item.label"
                class="font-weight-bold"
              >
                {{ item.label }}:
              </span>
            </v-col>
            <v-col class="d-flex flex-column">
              <span
                v-for="item in cardContent"
                :key="item.label"
                class="overflow-visible"
              >
                {{ item.value }}
              </span>
            </v-col>
          </v-row>
        </div>
        <div
          v-if="isOnBoardingComplete"
          class="mb-3 pa-0 d-flex flex-column justify-center align-center"
        >
          <AdsButton
            v-if="isOwner && !pdp?.finalCommentLocked && pdp.active"
            buttonText="Edit details"
            class="ads-tertiary-blue-background ads-grey-01 height-36"
            icon="mdi-account-edit-outline"
            @click="editPdpUsers"
          />
        </div>
      </v-card>
    </template>
  </Banner>
</template>

<script>
import { AdsButton, InitialsIcon } from '@nswdoe/doe-ui-core';
import Banner from '@/components/ADS-customised/Banner/Banner.vue';
import { formatDate, formatName, getPositions } from '@/utils';
import { mapGetters } from 'vuex';
import { PDP_TYPE, ROLES, USER_TYPE } from '@/data/constants';
import { findPdpUserByType, getIsPdpIncompleteArchived } from '@/lib/pdp';
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';

export default {
  name: 'UserBanner',
  components: {
    Banner,
    InitialsIcon,
    AdsButton,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    pdp: {
      type: Object,
      default: undefined,
    },
    isPrint: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ADS_Colors,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    cardContent() {
      return [
        {
          label: ROLES.pdpSupervisor,
          value: this.supervisorLabel,
        },
        ...(this.isPdpNts
          ? [
              {
                label: ROLES.pdpAdditionalSupervisor,
                value: this.managerLabel,
              },
            ]
          : []),
        ...(this.isPdpNts
          ? [
              {
                label: ROLES.pdpPrincipal,
                value: this.pdpPrincipalLabel,
              },
            ]
          : []),
        {
          label: 'PDP Year',
          value: this.pdp?.year,
        },
      ];
    },
    isPdpTs() {
      return this.pdp?.type === PDP_TYPE.ts;
    },
    isPdpNts() {
      return this.pdp?.type === PDP_TYPE.nts;
    },
    isOwner() {
      return this.loggedInUser?.id === this.pdp?.owner?.id;
    },
    userPosition() {
      return getPositions(this.user?.staffEmployment);
    },
    schoolLabel() {
      return this.pdp?.owner_school?.schoolFullName ?? 'Not assigned';
    },
    supervisorLabel() {
      return this.getPdPUserLabel(USER_TYPE.supervisor);
    },
    managerLabel() {
      return this.getPdPUserLabel(USER_TYPE.additionalSupervisor);
    },
    pdpPrincipalLabel() {
      return this.getPdPUserLabel(USER_TYPE.pdpPrincipal);
    },
    isOnBoardingComplete() {
      return this.pdp?.onboardingComplete;
    },
    displayArchiveChip() {
      if (this.pdp === undefined) return false;
      return !this.pdp?.active;
    },
    archiveDate() {
      return !this.pdp?.active && this.pdp?.archived_at
        ? `Archived ${formatDate(this.pdp?.archived_at, 'DD/MM/YYYY')}`
        : '';
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isPdpIncompleteArchived() {
      return getIsPdpIncompleteArchived(this.pdp);
    },
    isPdpSupervised() {
      return this.pdp?.pdp_user_associations.length;
    },
    pdpPhase() {
      const stage = !this.pdp?.active
        ? 'ARCHIVED PDP'
        : this.pdp?.phase?.stage.toUpperCase();
      return `${stage}${this.pdp?.phase?.status?.length ? '\u00A0-\u00A0' : ''}${this.pdp?.phase?.status?.toUpperCase()}`;
    },
  },
  methods: {
    formatName,
    formatDate,
    getPdPUserLabel(pdpUserType) {
      const user = findPdpUserByType(
        this.pdp?.pdp_user_associations,
        pdpUserType
      )?.user;
      if (user) {
        return formatName(user);
      }
      return 'Not assigned';
    },
    editPdpUsers() {
      this.$emit('editPdpUsers');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/variables';

.v-btn {
  &.ads-tertiary-blue-background {
    background-color: $ads-tertiary-blue !important;
    border-color: $ads-tertiary-blue !important;
  }

  &.height-36 {
    height: 36px !important;
  }
}

.v-card {
  background-color: rgba(64, 126, 201, 0.3);

  span {
    white-space: nowrap;
  }
}

:deep .initials-icon {
  font-size: 24px !important;
}

.right-panel {
  color: $ads-white;
}
</style>

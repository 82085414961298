/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
import axios from 'axios';
import Vue from 'vue'; // Import Vue
import dayjs from 'dayjs';
import { configure } from '@/api/http';

export async function refreshAuthToken() {
  const refreshToken = localStorage.getItem('refresh_token');
  if (!refreshToken) {
    throw new Error('No refresh token available');
  }
  const clientId = process.env.VUE_APP_PDP_AUTH_CLIENT_ID;
  const tokenEndpoint = process.env.VUE_APP_PDP_TOKEN_URL;
  if (!(clientId && tokenEndpoint)) {
    throw new Error('No client id or token endpoint defined');
  }

  try {
    const requestBody = new URLSearchParams();
    requestBody.append('client_id', clientId);
    requestBody.append('grant_type', 'refresh_token');
    requestBody.append('refresh_token', refreshToken);

    const response = await axios.post(tokenEndpoint, requestBody.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const { access_token, refresh_token, token_type, id_token } = response.data;
    if (access_token) {
      Vue.prototype.$OAuth.setToken(
        access_token,
        id_token,
        token_type,
        refresh_token
      );
      configure(access_token);
    } else {
      Vue.prototype.$OAuth.getAuth();
    }
  } catch (error) {
    console.error('Failed to refresh token', error);
    Vue.prototype.$OAuth.getAuth();
    throw error;
  }
}

const isTokenExpired = () => {
  const expiry = parseInt(`${localStorage.getItem('token_expiry')}000`);
  return dayjs(new Date(expiry)).isBefore(dayjs());
};

export const tokenRotation = async () => {
  if (isTokenExpired()) {
    await refreshAuthToken();
  }
};

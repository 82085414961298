<template>
  <v-container class="px-0 pa-md-7">
    <v-row class="ma-0 px-5 px-md-0">
      <v-col
        class="px-0"
        md="11"
      >
        <v-container class="pa-0">
          <v-row class="mx-0">
            <v-col class="pa-0 mb-4">
              <p>
                Towards the end of the annual cycle, you will participate in a
                discussion with your PDP supervisor to review progress towards
                achieving your goals. This will include an agreed, written
                assessment and should inform the development of your next PDP.
              </p>
              <p>
                You should finalise your evidence and complete at least two
                observations of classroom/leadership practice before organising
                a meeting with your PDP supervisor.
              </p>
            </v-col>
          </v-row>
          <v-row
            align="start"
            class="mx-0 no-break-inside"
          >
            <v-icon
              :color="ADS_Colors.Blue_2"
              class="ml-0 mr-2"
            >
              mdi-clipboard-outline
            </v-icon>
            <v-col
              v-if="isIncompleteArchivedPdp && !formPdp?.annualText"
              class="pa-0 no-break-inside"
            >
              <IncompleteFieldCard />
            </v-col>
            <v-col
              v-else-if="!finalSection?.complete"
              class="pa-0"
            >
              <RichTextField
                v-model="formPdp.annualText"
                :disabled="readOnly"
                :rules="[rules.stringFieldRequired]"
                label="Written assessment"
                lazy
              />
            </v-col>
            <v-col
              v-else
              class="pa-0 ml-2 my-0"
            >
              <div
                v-dompurify-html="formPdp.annualText ? formPdp.annualText : ''"
                class="pa-0"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row
      v-if="
        !finalSection?.complete &&
        !(isPrint && isIncompleteArchivedPdp && !formPdp?.annualText)
      "
      class="mx-0"
    >
      <v-col
        class="pl-5 pl-md-0 d-flex flex-row align-center"
        cols="12"
        data-test="save-annual-review"
      >
        <AdsButton
          v-if="!readOnly"
          :disabled="isSaveButtonDisabled"
          buttonText="Save"
          data-test="save-button"
          secondary
          @click="save"
        />
        <SaveTimestamp
          v-if="isSaving || isSaved || !isSaveButtonDisabled"
          :updated="pdp.updated_at"
          :pending="!isSaveButtonDisabled"
          class="ml-4 ml-md-4"
        />
      </v-col>
    </v-row>
    <v-row class="mx-0">
      <v-col
        v-if="showNotifyBanner"
        class="px-0"
        md="11"
      >
        <v-card class="pa-5">
          <MarkComplete
            :section="finalSection"
            :showBanner="showNotifyBanner"
            :showEditGoalsInfo="false"
            data-test="mark-complete"
            @onNotifyUser="handleNotifyUser"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-0 px-0 flex-column my-0">
      <v-col
        v-if="finalSection.comments?.length"
        class="px-0 px-md-0 no-break-inside"
      >
        <EventLog
          :comments="finalSection.comments"
          :pdp="pdp"
          class="px-0 px-md-0 elevation-0"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import validators from '@/lib/validators';
import { formatDateTime, formatName, getFinalSection } from '@/utils.ts';
import { AdsButton } from '@nswdoe/doe-ui-core';
import MarkComplete from './MarkComplete';
import { mapActions, mapGetters } from 'vuex';
import { equals } from 'ramda';
import RichTextField from '@/components/RichTextField';
import IncompleteFieldCard from '@/components/print/IncompleteFieldCard';
import {
  createComment,
  findSectionByType,
  resetNotification,
  resetSupervisorSignature,
} from '@/lib/pdp';
import { SECTION_TYPE, USER_TYPE } from '@/data/constants';
import EventLog from '@/components/EventLog.vue';
import SaveTimestamp from '@/components/SaveTimestamp.vue';

export default {
  name: 'TSAnnualReview',
  components: {
    SaveTimestamp,
    EventLog,
    RichTextField,
    AdsButton,
    MarkComplete,
    IncompleteFieldCard,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      ADS_Colors,
      savedMessage: false,
      rules: {
        stringFieldRequired: validators.stringFieldRequired,
      },
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'formPdp', 'pdp', 'isPdpArchived']),
    ...mapGetters('pdp', [
      'formPdpUser',
      'isPdpEqual',
      'saveTimeoutId',
      'isSaving',
      'isSaved',
    ]),
    annualText: {
      get() {
        return this.formPdp.annualText;
      },
      set(text) {
        this.formPdp.annualText = text;
      },
    },
    midYearSection() {
      return findSectionByType(this.formPdp, SECTION_TYPE.midYear);
    },
    finalSection() {
      return findSectionByType(this.formPdp, SECTION_TYPE.final);
    },
    finalSupervisor() {
      return this.formPdpUser(SECTION_TYPE.final, USER_TYPE.supervisor);
    },
    isSaveButtonDisabled() {
      return !this.isAnnualTextValid || this.isAnnualTextEqual;
    },
    isAnnualTextValid() {
      return validators.stringFieldRequired(this.formPdp.annualText) === true;
    },
    isAnnualTextSavedValid() {
      return validators.stringFieldRequired(this.pdp.annualText) === true;
    },
    isAnnualTextEqual() {
      return equals(this.formPdp?.annualText, this.pdp?.annualText);
    },
    showNotifyBanner() {
      return (
        this.midYearSection.complete &&
        !this.finalSection.notifySupervisor &&
        this.isAnnualTextSavedValid &&
        this.isAnnualTextValid &&
        !this.finalSection.complete &&
        this.isPdpEqual
      );
    },
    isIncompleteArchivedPdp() {
      const finalSection = getFinalSection(this.formPdp?.sections);
      return this.isPdpArchived && !finalSection?.complete;
    },
  },
  watch: {
    isSaveButtonDisabled: {
      async handler() {
        if (!this.isSaveButtonDisabled) {
          await this.saveTimeout();
        } else if (this.saveTimeoutId) {
          this.stopSaveTimeout();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('pdp', [
      'notifySupervisor',
      'savePdp',
      'startSaveTimeout',
      'stopSaveTimeout',
    ]),
    formatName,
    formatDateTime,
    async handleNotifyUser() {
      const notifyData = createComment(this.formPdp.id, this.finalSection.id);
      await this.notifySupervisor(notifyData);
    },
    async save() {
      resetNotification(this.finalSection);
      resetSupervisorSignature(this.finalSection);
      await this.savePdp(this.formPdp);
    },
    async saveTimeout() {
      resetNotification(this.finalSection);
      resetSupervisorSignature(this.finalSection);
      await this.startSaveTimeout(USER_TYPE.owner);
    },
  },
  inject: {
    isPrint: {
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 959px) {
  .comments {
    font-size: 16px;
    word-break: break-word;
  }
}

.no-break-inside {
  break-inside: avoid;
}
</style>

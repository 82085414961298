<template>
  <div>
    <UserBanner
      :pdp="pdp"
      :user="loggedInUser"
      elevation="0"
      @editPdpUsers="showEditor = true"
    />
    <Ribbon
      :pdp="formPdp"
      @print="getNotes"
      @save="archivePdp"
      @selected="pdpSelected"
    >
      <template #printContent>
        <TSPrintContent
          v-if="formPdp?.active"
          :midYearSection="midYearSection"
          :pdp="formPdp"
          :planSection="planSection"
        />
        <TSArchivedPrintContent
          v-else
          :midYearSection="midYearSection"
          :pdp="formPdp"
          :planSection="planSection"
        />
      </template>
    </Ribbon>
    <Dialog
      :openDialog="
        pdp?.active && pdp?.onboardingComplete && !pdp?.owner_school_id
      "
      maxWidth="55em"
      persistent
    >
      <template #text>
        <UpdateSchoolModal
          class="px-12"
          @archive="archivePdp"
          @save="updateSchool"
        />
      </template>
    </Dialog>
    <Dialog
      :openDialog="pdp?.active && pdp?.year && !pdp?.yearSelected"
      maxWidth="55em"
      persistent
    >
      <template #text>
        <UpdateYearModal
          class="px-12"
          @save="updateYear"
        />
      </template>
    </Dialog>
    <Dialog
      :displayCloseBtn="true"
      :openDialog="showEditor"
      maxWidth="55em"
      @close="showEditor = false"
    >
      <template #text>
        <TSPdpDetails
          :openDialog="showEditor"
          class="ma-0 px-7"
          @saved="editPdpDetails"
        />
      </template>
    </Dialog>
    <!-- Expired PDP Dialog -->
    <Dialog
      :displayCloseBtn="false"
      :openDialog="showExpiredPdpDialog"
      maxWidth="60%"
      persistent
    >
      <template #text>
        <PdpUpdateDialog
          :openDialog="showExpiredPdpDialog"
          class="ma-0 px-7"
          @closeExpiredPdpDialog="closeExpiredPdpDialog"
        />
      </template>
    </Dialog>
    <!-- ... Expired PDP Dialog -->
    <Wrapper
      v-if="showStepper"
      :isMobile="isMobile"
      cardClass="mt-10 pa-0"
      class="px-0 px-sm-3"
    >
      <TSStepper
        :showStepper="showStepper"
        @reloadOwnedPdp="reloadOwnedPdp"
      />
    </Wrapper>
    <TSPlanEditor
      v-else-if="showPlanEditor"
      @planSigned="handlePlanSigned"
    />
    <TSMyPdpTabs
      v-else-if="showTabs"
      :selectedTab="selectedTab"
      :showTabs="showTabs"
      @tabSigned="handleTabSigned"
    />
    <portal
      v-if="formPdp"
      selector=".v-application--wrap"
    >
      <div class="notes-wrapper">
        <NotesBar
          v-model="notes"
          :archived="!formPdp?.active"
          :edit="formPdp?.active"
          :open="notesOpen"
          @closeNotes="closeNotes"
          @openNotes="openNotes"
          @updateNotes="updateNotes"
        />
      </div>
    </portal>
  </div>
</template>

<script>
import NotesBar from '@/components/NotesBar';
import UserBanner from '@/components/UserBanner';
import TSPdpDetails from '@/components/TSPdpDetails.vue';
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import { mapActions, mapGetters } from 'vuex';
import { Dialog } from '@nswdoe/doe-ui-core/index';
import TSStepper from '@/components/TSStepper';
import TSMyPdpTabs from '@/components/TSMyPdpTabs';
import TSPlanEditor from '@/components/TSPlanEditor';
import TSPrintContent from '@/components/print/TSPrintContent';
import TSArchivedPrintContent from '@/components/print/TSArchivedPrintContent';
import Ribbon from '@/components/Ribbon';
import Wrapper from '@/components/Wrapper';
import { setNotifySupervisor } from '@/views/MyPdp/index.lib';
import { COMMENT_TYPE, SECTION_TYPE, USER_TYPE } from '@/data/constants';
import { createComment } from '@/lib/pdp';
import UpdateSchoolModal from '@/components/UpdateSchoolModal';
import PdpUpdateDialog from '@/components/PdpUpdateDialog';
import { Portal } from '@linusborg/vue-simple-portal';
import UpdateYearModal from '@/components/UpdateYearModal.vue';

export default {
  name: 'TSMyPdpView',
  components: {
    UpdateYearModal,
    UpdateSchoolModal,
    TSPlanEditor,
    TSMyPdpTabs,
    TSStepper,
    Dialog,
    NotesBar,
    UserBanner,
    TSPdpDetails,
    TSPrintContent,
    TSArchivedPrintContent,
    Ribbon,
    Wrapper,
    PdpUpdateDialog,
    Portal,
  },
  props: {
    selectedTab: {
      type: String,
      default: '',
    },
    showExpiredPdpDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      USER_TYPE,
      ADS_Colors,
      showEditor: false,
      inputSupervisor: {
        supervisor: {},
        supervisorLocationName: '',
      },
      notesOpen: false,
    };
  },
  computed: {
    ...mapGetters([
      'loggedInUser',
      'formPdp',
      'pdp',
      'isPdpLoaded',
      'isPdpArchived',
    ]),
    ...mapGetters('pdp', {
      planSection: 'formPdpPlanSection',
      midYearSection: 'formPdpMidYearSection',
      finalSection: 'formPdpFinalSection',
      personalNotes: 'personalNotes',
    }),
    ...mapGetters('formValidation', ['allGoalsValid', 'validationGoals']),
    notes: {
      get() {
        return this.personalNotes?.text;
      },
      set(value) {
        this.personalNotes.text = value;
      },
    },
    showStepper() {
      return (
        !!this.formPdp &&
        !this.isPdpArchived &&
        !this.formPdp?.onboardingComplete
      );
    },
    showPlanEditor() {
      return (
        this.formPdp?.onboardingComplete &&
        !this.planSection?.complete &&
        !this.isPdpArchived
      );
    },
    showTabs() {
      return this.planSection?.complete || this.isPdpArchived;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    ...mapActions('pdp', [
      'createUserPdp',
      'savePdp',
      'getOwnedPdp',
      'getPersonalNotes',
      'updatePersonalNotes',
      'signPdp',
    ]),
    pdpSelected(id) {
      this.$emit('selected', id);
    },
    async editPdpDetails() {
      const yearSaved =
        this.formPdp.year && this.formPdp.year !== this.pdp.year;
      await this.savePdp(this.formPdp);
      if (yearSaved) {
        this.reloadOwnedPdp();
      }
      this.showEditor = !this.showEditor;
    },
    reloadOwnedPdp() {
      this.$emit('reloadOwnedPdp');
    },
    async handleNotifyUser() {
      if (this.planSection) {
        this.formPdp.sections = setNotifySupervisor(
          this.planSection,
          this.formPdp.sections
        );
        await this.$store.dispatch('pdp/savePdp', this.formPdp);
      }
    },
    async getNotes() {
      if (
        !this.personalNotes ||
        (this.personalNotes && this.personalNotes.pdp_id !== this.formPdp.id)
      ) {
        await this.getPersonalNotes(this.formPdp.id);
      }
    },
    async openNotes() {
      await this.getNotes();
      this.notesOpen = true;
      this.$emit('notesOpen', this.notesOpen);
    },
    async updateNotes() {
      await this.updatePersonalNotes(this.personalNotes);
      this.notesOpen = false;
      this.$emit('notesOpen', this.notesOpen);
    },
    async closeNotes() {
      this.notesOpen = false;
      this.$emit('notesOpen', this.notesOpen);
    },
    async handlePlanSigned(sectionToBeUpdated) {
      const signData = createComment(
        this.formPdp.id,
        sectionToBeUpdated.id,
        COMMENT_TYPE.section_sign_user
      );
      await this.signPdp(signData);
      this.gtmTrackSign(sectionToBeUpdated.sectionType);
    },
    async handleTabSigned(sectionToBeUpdated, comment) {
      const signData = createComment(
        this.formPdp.id,
        sectionToBeUpdated.id,
        COMMENT_TYPE.section_sign_user,
        comment
      );
      await this.signPdp(signData);
      this.gtmTrackSign(sectionToBeUpdated.sectionType);
      if (sectionToBeUpdated.sectionType === SECTION_TYPE.final) {
        await this.archivePdp();
      }
    },
    gtmTrackSign(sectionType) {
      this.$gtm.trackEvent({
        event: null,
        category: 'MyPdp',
        action: 'Supervisee Sign Pdp',
        label: 'OrientationBanner',
        value: `${sectionType}`,
      });
    },
    validateOnChange(step) {
      this.$refs.stepForm[step - 1].validate();
    },
    async updateSchool() {
      await this.savePdp(this.formPdp);
    },
    async updateYear() {
      const yearSaved =
        this.formPdp.year && this.formPdp.year !== this.pdp.year;
      await this.savePdp(this.formPdp);
      if (yearSaved) {
        this.reloadOwnedPdp();
      }
    },
    async archivePdp() {
      this.formPdp.active = false;
      await this.savePdp(this.formPdp);
      this.$emit('archive');
    },
    closeExpiredPdpDialog() {
      this.$emit('handleRefresh');
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.v-stepper__step__step) {
  font-size: 1.15rem;
  font-weight: bold;
  height: 26px;
  width: 26px;
}

hr {
  border: none;
  height: 1px;
  color: $ads-grey-03;
}

@media screen and (max-width: 959px) {
  :deep .row.banner .col {
    padding-top: 12px;
  }
}
</style>

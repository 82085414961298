<template>
  <div>
    <v-row
      v-if="isSupervisor"
      id="goals"
      class="mb-2"
      justify="space-between"
    >
      <v-col>
        <h4 class="display-1 mx-5 mx-md-0">Professional goals</h4>
      </v-col>
    </v-row>
    <v-row
      v-if="!isSupervisor && allowEdit"
      class="ma-0 py-0 px-5 px-md-0"
    >
      <v-col class="ma-0 pa-0">
        <p class="body-1">
          The focus of the planning phase is to create an individual PDP to
          guide your work for the year ahead. You will set three to five
          professional goals, identify professional learning activities and
          determine the evidence that will show your progress towards your
          goals.
        </p>
        <p class="body-1">
          Aligning your goals to the
          <a
            href="https://education.nsw.gov.au/inside-the-department/human-resources/performance/school-teachers/teaching-standards"
            target="_blank"
          >
            Australian Professional Standards for Teachers
          </a>
          is key to developing your professional knowledge, practice and
          engagement. Your planned goals must take into account your
          accreditation requirements, where applicable.
        </p>
        <p class="body-1 font-weight-bold">
          Use the SMART structure to write more effective goals:
        </p>
        <ul
          class="pa-0 body-1 mb-4"
          style="list-style-type: none"
        >
          <li>
            <b>S</b>
            — Specific
          </li>
          <li>
            <b>M</b>
            — Measurable
          </li>
          <li>
            <b>A</b>
            — Achievable
          </li>
          <li>
            <b>R</b>
            — Relevant
          </li>
          <li>
            <b>T</b>
            — Time-bound
          </li>
        </ul>
        <p class="body-2 text-md-body-1">
          <a
            :href="
              userIsPrincipal
                ? 'https://education.nsw.gov.au/inside-the-department/human-resources/performance/school-teachers/school-leaders/goal-setting '
                : 'https://education.nsw.gov.au/inside-the-department/human-resources/performance/school-teachers/classroom-teachers/goal-setting'
            "
            target="_blank"
          >
            Learn more about setting SMART goals
          </a>
        </p>
        <Alert
          alertClass="info-background-banner"
          class="flex-grow-1 my-4"
          inPage
          text="Your privacy is important"
          type="info"
        >
          <template #optional>
            <p class="mt-2 mb-0 mb-md-4 negative-margin-left">
              Only your PDP supervisor and principal can see your PDP. Nobody
              else can search or access your PDP.
            </p>
            <p class="mt-2 mb-0 mb-md-4 negative-margin-left">
              A
              <a
                href="https://education.nsw.gov.au/content/dam/main-education/inside-the-department/human-resources/media/documents/performance/Digtial_PDP_SDD_pack-data_security_fact_sheet.pdf"
                target="_blank"
              >
                fact sheet
              </a>
              is available for more information about the security, privacy and
              confidentiality of your data in the Digital PDP.
            </p>
          </template>
        </Alert>
      </v-col>
    </v-row>
    <v-row
      v-for="(goal, goalIndex) of goals"
      :key="`section-goal-${goal.id}`"
      class="ma-0 pa-0"
      no-gutters
    >
      <v-col>
        <div class="mb-0">
          <div class="goal-heading mb-10 px-5 px-md-10 py-5">
            Goal
            {{ goalIndex + 1 }}:
            {{ goal.title }}
          </div>
          <div class="px-5 px-md-10">
            <TSPdpGoal
              :id="`pdpGoal-${goal.id}`"
              :key="`pdpGoal-${goal.id}`"
              :canDeleteGoal="canDeleteGoal"
              :canUploadEvidence="canUploadEvidence"
              :editMode="allowEdit"
              :goal="goal"
              :goalIndex="goalIndex"
              :isSupervisor="isSupervisor"
              :owner="owner"
              :section="section"
              class="px-5 px-md-7"
              @deleted="updatePanels(goalIndex, 'delete')"
              @saved="updatePanels(goalIndex, 'save')"
              @validated="goalChange"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <AdsButton
      v-if="goals && goals.length < MAX_TS_GOALS && allowEdit"
      buttonText="Add another goal"
      class="mt-8 pl-2 ml-5 ml-md-0"
      icon="add_circle_outline"
      secondary
      @click="handleAddNewGoal"
    />
    <p
      v-if="goals && allowEdit"
      class="mt-6 mb-8 ml-4 ml-md-0"
    >
      <v-icon class="mr-1">info_outline</v-icon>
      You added
      {{ goalsInText }}
      goals (maximum five).
    </p>
  </div>
</template>

<script>
import { AdsButton, Alert } from '@nswdoe/doe-ui-core';
import TSPdpGoal from '@/components/TSPdpGoal.vue';
import { mapGetters } from 'vuex';
import { MAX_TS_GOALS, MIN_TS_GOALS, SECTION_TYPE } from '@/data/constants';
import { resetNotification, resetSupervisorSignature } from '@/lib/pdp';
import { numberToWord } from '@/utils';

export default {
  name: 'TSPdpGoalsPrint',
  components: {
    AdsButton,
    TSPdpGoal,
    Alert,
  },
  props: {
    section: {
      type: Object,
      default: null,
    },
    showEditToggle: {
      type: Boolean,
      default: false,
    },
    isSupervisor: {
      type: Boolean,
      default: false,
    },
    userIsPrincipal: {
      type: Boolean,
      default: false,
    },
    goals: {
      type: Array,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    expandAll: {
      type: Boolean,
      default: false,
    },
    owner: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      editMode: false,
      goalPanelIndex: [0],
      MIN_TS_GOALS,
      MAX_TS_GOALS,
    };
  },
  watch: {
    allowEdit(newVal) {
      this.editMode = newVal;
    },
    goals: {
      handler(newValue) {
        newValue.forEach((goal) => {
          if (goal.id) {
            this.$store.commit('formValidation/addGoal', {
              id: `pdpGoal-${goal.id}`,
              isValid: !!(
                goal.description && goal.description.trim().length >= 100
              ),
            });
          }
        });
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['formPdp']),
    panelExpandedItems: {
      get() {
        let panelExpandedItems = [0];
        if (this.expandAll) {
          for (let i = 1; i < this.goals.length; i++) {
            panelExpandedItems.push(i);
          }
        }
        return panelExpandedItems;
      },
      set(value) {
        this.goalPanelIndex = value;
      },
    },
    canUploadEvidence() {
      return this.section.sectionType === SECTION_TYPE.midYear;
    },
    canDeleteGoal() {
      return this.section.goals.length > MIN_TS_GOALS;
    },
    allowEdit() {
      if (this.editable === false) {
        return false;
      }
      if (this.section?.sectionType === SECTION_TYPE.plan) {
        return !this.section.complete;
      } else {
        const finalSection = this.formPdp?.sections.find(
          (s) => s.sectionType === SECTION_TYPE.final
        );
        return !finalSection?.complete;
      }
    },
    goalsInText() {
      return numberToWord(this.goals.length);
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    handleInput() {
      this.$emit('input', this.currentSupervisor);
    },
    async handleAddNewGoal(event) {
      event.target.blur();
      const goal = {
        index: this.section.goals.length + 1,
        section_id: this.section.id,
      };
      this.formPdp.sections = this.formPdp.sections.map((section) => {
        if (section.id === this.section.id) {
          section.goals.push(goal);
          if (section.sectionType === SECTION_TYPE.plan) {
            section = resetSupervisorSignature(section);
            section = resetNotification(section);
          }
        }
        return section;
      });
      await this.$store.dispatch('pdp/savePdp', this.formPdp).then(() => {
        this.goalPanelIndex.push(this.section.goals.length - 1);
      });
    },
    setCurrentPanels() {
      this.goalPanelIndex = [0];
    },
    updatePanels(index, mode) {
      if (mode === 'save') {
        setTimeout(() => {
          this.goalPanelIndex.splice(
            this.goalPanelIndex.indexOf(index),
            1,
            index + 1
          );
          const el = document.getElementById(`expansion-header-${index}`);
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, 100);
      } else if (mode === 'delete') {
        setTimeout(() => {
          const el = document.getElementById(`expansion-header-${index + 1}`);
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, 100);
      }
    },
    goalChange() {
      this.$emit('validated');
    },
  },
};
</script>

<style lang="scss" scoped>
.full-width {
  width: 100%;
}

.goal-heading {
  background-color: rgba(108, 172, 228, 0.2);
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}

:deep .info-background-banner {
  background-color: rgba(108, 172, 228, 0.2) !important;
  border: none !important;
  // colour for info banner text
  &.theme--light.v-sheet {
    color: $ads-grey-01;
  }
}

@media screen and (max-width: 959px) {
  :deep .v-alert__wrapper {
    margin-left: 16px;
  }
  :deep .v-icon.v-alert__icon {
    display: none;
  }
  :deep .v-expansion-panel-header {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('p',{staticClass:"d-flex flex-row ml-sm-1 body-1 ml-1 mr-1 mr-sm-0 align-center"},[_vm._v(" Filter by: "),(_vm.archived)?_c('ChipFilter',{staticClass:"ml-3",attrs:{"items":_vm.filterYear,"name":"Year","type":"checkbox"},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}}):_vm._e(),_c('ChipFilter',{staticClass:"ml-3",attrs:{"items":_vm.filterRoles,"name":"Role","type":"checkbox"},model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}}),(_vm.tasks || _vm.team || _vm.school)?_c('ChipFilter',{staticClass:"ml-3",attrs:{"items":_vm.filterPhases,"name":"Phase","type":"checkbox"},model:{value:(_vm.selectedPhase),callback:function ($$v) {_vm.selectedPhase=$$v},expression:"selectedPhase"}}):_vm._e(),(_vm.tasks || _vm.team || _vm.school)?_c('ChipFilter',{staticClass:"ml-3",attrs:{"items":_vm.filterActions,"name":"Action","type":"checkbox"},model:{value:(_vm.selectedAction),callback:function ($$v) {_vm.selectedAction=$$v},expression:"selectedAction"}}):_vm._e()],1)])],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('AdsDataTable',{staticClass:"default-text",attrs:{"footer-props":{
          'items-per-page-options': _vm.TABLE_ITEMS_PER_PAGE_OPTIONS,
        },"headers":_vm.TABLE_HEADERS,"search-label":"Find by name","options":_vm.options,"items":_vm.pdps,"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(
              _vm.type === _vm.TAB_TYPES.tasks.id && _vm.pdps?.length === 0 && !_vm.isLoading
            )?_c('v-container',{staticClass:"my-16"},[_c('FeatureIcon',{staticClass:"mx-auto",attrs:{"iconSize":"80","size":"80","color":_vm.ADS_Colors.Success_Green,"backgroundColor":"transparent","icon":"mdi-check-circle-outline"}}),_c('div',{staticClass:"ads-grey-01 no-tasks-message mt-5"},[_c('p',{staticClass:"text-center mb-0"},[_vm._v("You have no outstanding tasks.")])])],1):_vm._e(),(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"table-row-divider@1"}}):_vm._e()]},proxy:true},{key:`item.updated_at`,fn:function({ item }){return [_c('div',{staticClass:"table-item ma-0 py-5 flex-row"},[_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:plaintext",value:(
                _vm.formatDate(item.updated_at ?? item.created_at)
              ),expression:"\n                formatDate(item.updated_at ?? item.created_at)\n              ",arg:"plaintext"}],staticClass:"ma-0"})])]}},{key:`item.archived_at`,fn:function({ item }){return [_c('div',{staticClass:"table-item ma-0 py-5 flex-row"},[_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:plaintext",value:(_vm.formatDate(item.archived_at)),expression:"formatDate(item.archived_at)",arg:"plaintext"}],staticClass:"ma-0"})])]}},{key:`item.owner.staffPreferredName`,fn:function({ item }){return [_c('div',{staticClass:"d-flex align-start"},[_c('div',{staticClass:"py-5"},[_c('InitialsIcon',{staticClass:"mr-md-4",attrs:{"givenName":_vm.formatName(item.owner),"size":"44px"}})],1),_c('div',{staticClass:"d-flex flex-column justify-start table-item ma-0 py-5",staticStyle:{"height":"auto"}},[_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:plaintext",value:(_vm.formatName(item.owner)),expression:"formatName(item.owner)",arg:"plaintext"}],staticClass:"ma-0 font-weight-bold"}),_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:plaintext",value:(
                  _vm.getPositions(item.owner?.staffEmployment)
                ),expression:"\n                  getPositions(item.owner?.staffEmployment)\n                ",arg:"plaintext"}],staticClass:"ma-0"})])])]}},{key:`item.role`,fn:function({ item }){return [_c('div',{staticClass:"table-item ma-0 py-5 flex-row"},[_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:plaintext",value:(item.role),expression:"item.role",arg:"plaintext"}],staticClass:"ma-0"})])]}},{key:`item.year`,fn:function({ item }){return [_c('div',{staticClass:"table-item ma-0 py-5 flex-row"},[(!item.yearSelected && _vm.type !== _vm.TAB_TYPES.archived.id)?_c('p',[_c('v-icon',{attrs:{"color":_vm.ADS_Colors.Warning_Orange}},[_vm._v("mdi-alert")]),_vm._v(" Pending input ")],1):_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:plaintext",value:(item.year),expression:"item.year",arg:"plaintext"}],staticClass:"ma-0"})])]}},{key:`item.phase.stage`,fn:function({ item }){return [_c('div',{staticClass:"table-item ma-0 py-5 flex-row"},[_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:sanitize",value:(
                `<strong>
                              ${
                                item.phase?.status?.length === 0
                                  ? ''
                                  : `${item.phase?.stage}: `
                              }
                            </strong>
                            ${
                              item.phase?.status?.length === 0
                                ? item.phase?.stage
                                : item.phase?.status
                            }`
              ),expression:"\n                `<strong>\n                              ${\n                                item.phase?.status?.length === 0\n                                  ? ''\n                                  : `${item.phase?.stage}: `\n                              }\n                            </strong>\n                            ${\n                              item.phase?.status?.length === 0\n                                ? item.phase?.stage\n                                : item.phase?.status\n                            }`\n              ",arg:"sanitize"}]})])]}},{key:`item.action`,fn:function({ item }){return [_c('div',{staticClass:"table-item ma-0 py-5 flex-row"},[(_vm.archived)?_c('AdsButton',{staticClass:"body-1 font-weight-bold",attrs:{"button-text":item.action,"secondary":""},on:{"click":function($event){return _vm.handleActionClick(item)}}}):(item.action === 'Submission pending')?_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v(" Submission pending ")]):_c('Chip',{staticClass:"body-1 font-weight-bold",attrs:{"color":_vm.getActionColour(item.action),"data-testid":"handleAction","text":item.action},on:{"click":function($event){return _vm.handleActionClick(item)}}})],1)]}}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
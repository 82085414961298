import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import moduleUser from './modules/user';
import moduleSchoolData from './modules/schoolData';
import modulePdpData from './modules/pdpData';
import moduleFormValidation from './modules/formValidation';
import moduleDialog from './modules/dialog';
import moduleConfig from './modules/config';
import { RootState } from './types';
import { User } from '@/schema';
import api from '@/api';
import moduleLookups from './modules/lookups';
import { SECTION_TYPE } from '@/data/constants';
import { getIsPdpIncompleteArchived } from '@/lib/pdp';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    user: moduleUser,
    pdp: modulePdpData,
    school: moduleSchoolData,
    formValidation: moduleFormValidation,
    lookups: moduleLookups,
    dialog: moduleDialog,
    config: moduleConfig,
  },
  state: {
    isLoading: false,
    loggedInUser: undefined,
  } as RootState,
  mutations: {
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_LOGGED_IN_USER(state, user: User) {
      state.loggedInUser = user;
    },
  },
  // these getters must be removed from the root state into their own modules
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    loggedInUser(state) {
      return state.loggedInUser;
    },
    isPdpUpdated(state) {
      return state.pdp?.isExpired;
    },
    ownedPdp(state) {
      return state.pdp?.ownedPdp;
    },
    pdp(state) {
      return state.pdp?.pdp;
    },
    isPdpArchived(state) {
      return state.pdp?.pdp?.active === false;
    },
    isPdpIncompleteArchived(state) {
      return getIsPdpIncompleteArchived(state?.pdp?.pdp);
    },
    isActionPdpArchived(state) {
      return state.pdp?.actionPdp?.active === false;
    },
    isActionPdpIncompleteArchived(state) {
      return getIsPdpIncompleteArchived(state?.pdp?.actionPdp);
    },
    formPdp(state) {
      return state.pdp?.formPdp;
    },
    actionPdp(state) {
      return state.pdp?.actionPdp;
    },
    originalActionPdp(state) {
      return state.pdp?.originalActionPdp;
    },
    isPdpLoaded(state) {
      return state.pdp?.isLoaded;
    },
    taskPdp(state) {
      return state.pdp?.taskPdp ?? undefined;
    },
    superviseePdp(state) {
      return state.pdp?.superviseePdp ?? undefined;
    },
    schoolPdp(state) {
      return state.pdp?.schoolPdp ?? undefined;
    },
    archiveManagementPdp(state) {
      return state.pdp?.archiveManagementPdp ?? undefined;
    },
    taskPdpLength(state) {
      return state.pdp?.taskPdpLength ?? 0;
    },
    superviseePdpLength(state) {
      return state.pdp?.superviseePdpLength ?? 0;
    },
    schoolPdpLength(state) {
      return state.pdp?.schoolPdpLength ?? 0;
    },
    archiveManagementPdpLength(state) {
      return state.pdp?.archiveManagementPdpLength ?? 0;
    },
    isMidYearSectionComplete: (state) => {
      return state.pdp?.formPdp?.sections?.find(
        (s) => s.sectionType === SECTION_TYPE.midYear
      )?.complete;
    },
    config: (state) => {
      return state.config;
    },
  },
  actions: {
    async setLoggedInUser({ commit }) {
      commit('SET_IS_LOADING', true);
      try {
        const loginResponse = await api.user.getUserLogin();
        if (loginResponse) {
          commit('SET_LOGGED_IN_USER', loginResponse);
          commit('SET_IS_LOADING', false);
        }
      } catch (e) {
        commit('SET_IS_LOADING', false);
      }
    },
    setIsLoading({ commit }, loading) {
      commit('SET_IS_LOADING', loading);
    },
  },
};
export default new Vuex.Store<RootState>(store);

<template>
  <v-row no-gutters>
    <v-col :cols="openDialog ? 'auto' : 7">
      <component
        :is="openDialog ? 'v-form' : 'div'"
        ref="dialogForm"
        v-model="isValid"
        lazy-validation
      >
        <div
          v-if="openDialog"
          class="mb-5"
        >
          <h3 class="title px-0 pb-6">Edit details</h3>
          <v-card
            class="px-6 mb-6"
            :color="ADS_Colors.PRIMARY_TEAL"
            elevation="0"
            rounded="lg"
          >
            <v-card-text class="px-0 body-1 grey-01--text">
              If your
              <b>PDP Supervisor/Additional Supervisor/PDP Principal</b>
              is not appearing in the list below, ask them to access the Digital
              PDP using their own credentials
            </v-card-text>
          </v-card>
          <p>Edit your PDP Year</p>
        </div>
        <v-select
          v-model="ownerDetails.year"
          :items="selectableYears"
          :rules="[rules.numberFieldRequired]"
          item-text="value"
          item-value="value"
          label="PDP Year"
          outlined
          placeholder="Select"
          persistent-placeholder
          return-object
          validate-on-blur
          class="mt-1 mb-4"
          :messages="pdpYearMessage"
        />
        <AdsTextField
          v-model="ownerDetails.position"
          label="Your position/role"
          :rules="[rules.stringFieldRequired]"
          validate-on-blur
        />
        <p v-if="openDialog">Edit your school</p>
        <p v-else>Add your school or work location</p>
        <p>
          If you don't work in a school, add 'Education office' as your work
          location.
        </p>
        <v-combobox
          v-model="ownerDetails.school"
          label="Your school name"
          :items="searchedSchoolsList"
          :rules="[rules.schoolFieldRequired]"
          :search-input.sync="searchOwnerSchool"
          item-text="schoolFullName"
          item-value="id"
          placeholder="Type here"
          clearable
          return-object
          outlined
          validate-on-blur
          :disabled="formPdpFinalSection?.complete"
        />
        <p class="mt-n4 mb-9">
          <b>Note:</b>
          As the school leader, your principal can view all the PDPs in the
          school
        </p>
        <p
          v-if="openDialog"
          class="body-1 black--text mb-5"
        >
          Edit your PDP supervisor's details here
        </p>
        <p
          v-else
          class="body-1 black--text mb-5"
        >
          Add your PDP supervisor’s details here
        </p>
        <v-combobox
          v-model="selectedSupervisor"
          label="PDP Supervisor"
          :items="supervisors"
          :rules="[supervisorFromListMandatory]"
          :search-input.sync="searchSupervisor"
          item-text="staffDoEEmailAddress"
          item-value="id"
          placeholder="Start typing to search"
          clearable
          return-object
          outlined
          validate-on-blur
          :disabled="formPdpFinalSection?.complete"
        />
        <AdsTextField
          v-model="supervisorPosition"
          label="PDP Supervisor position/role"
          :rules="[rules.stringFieldRequired]"
          validate-on-blur
        />
        <v-combobox
          v-model="selectedSupervisorSchool"
          label="PDP Supervisor's work location"
          :items="searchedSchoolsList"
          :rules="[rules.schoolFieldRequired]"
          :search-input.sync="searchSupervisorSchool"
          item-text="schoolFullName"
          item-value="id"
          placeholder="Type here"
          clearable
          return-object
          outlined
          validate-on-blur
        />
        <p
          v-if="openDialog"
          class="mb-5 body-1 black--text"
        >
          Edit your PDP principal's details here
        </p>
        <p
          v-else
          class="mb-5 body-1 black--text"
        >
          Your principal can view your PDP anytime. Signing is optional. If your
          principal wants to sign (in addition to your PDP supervisor) add their
          name below.
        </p>
        <v-combobox
          ref="principalEmail"
          label="PDP Principal"
          v-model="selectedPrincipal"
          :items="principals"
          :search-input.sync="searchPrincipal"
          item-text="staffDoEEmailAddress"
          item-value="id"
          :rules="[pdpPrincipalEmptyOrFromList]"
          placeholder="Start typing to search"
          clearable
          outlined
          validate-on="input"
          :disabled="formPdpFinalSection?.complete"
        />
        <p
          v-if="openDialog"
          class="mb-5 body-1 black--text"
        >
          Edit your additional supervisor's details here
        </p>
        <p
          v-else
          class="mb-5 body-1 black--text"
        >
          If your PDP supervisor is different than your day to day supervisor,
          add your additional supervisor details here. This will give them
          access to view your PDP as well.
        </p>
        <v-combobox
          ref="additionalSupervisorEmail"
          label="Additional Supervisor"
          v-model="selectedAdditionalSupervisor"
          :items="additionalSupervisors"
          :search-input.sync="searchAdditionalSupervisor"
          item-text="staffDoEEmailAddress"
          item-value="id"
          :rules="[additionalSupervisorMandatory]"
          placeholder="Start typing to search"
          clearable
          outlined
          validate-on-blur
        />
        <AdsTextField
          v-model="additionalSupervisorPosition"
          label="Additional Supervisor role"
          :rules="[additionalSupervisorDetailsMandatory]"
          validate-on-blur
        />
        <v-combobox
          v-model="selectedAdditionalSupervisorSchool"
          label="Additional Supervisor location"
          :items="searchedSchoolsList"
          :rules="[additionalSupervisorSchoolMandatory]"
          :search-input.sync="searchAdditionalSupervisorSchool"
          item-text="schoolFullName"
          item-value="id"
          placeholder="Type here"
          clearable
          return-object
          outlined
          validate-on-blur
        />
        <v-card
          v-if="openDialog"
          class="px-6 mb-6"
          color="#e8f5fd"
          elevation="0"
          rounded="lg"
        >
          <v-card-text class="px-0 body-1 grey-01--text">
            <h3 class="mt-3 title px-0 pb-2">Acknowledgement of changes</h3>
            I acknowledge that if I have updated my school, PDP Supervisor or
            PDP Principal details, then I have also updated all other fields
            associated with this change
            <div class="mt-6 d-flex flex-row justify-space-between">
              <v-btn
                class="confirm-box d-flex column align-content-start text-left"
                outlined
                @click="confirm"
              >
                <v-checkbox :input-value="confirmed" />
                I confirm
              </v-btn>
              <AdsButton
                buttonText="Save & Close"
                :disabled="disabledConfirmed"
                icon="mdi-arrow-right"
                @click="submitPdp"
              />
            </div>
          </v-card-text>
        </v-card>
        <AdsButton
          v-else
          buttonText="Proceed"
          :disabled="disableSaveButton"
          icon="mdi-arrow-right"
          @click="submitPdp"
        />
      </component>
    </v-col>
  </v-row>
</template>

<script>
import { AdsTextField, AdsButton } from '@nswdoe/doe-ui-core';
import { mapState, mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';
import { equals } from 'ramda';
import validators from '@/lib/validators';
import {
  USER_TYPE,
  ADDITIONAL_SUPERVISOR_ERROR_MESSAGE,
  SUPERVISOR_ERROR_MESSAGE,
  PDP_PRINCIPAL_ERROR_MESSAGE,
} from '@/data/constants';
import { findPdpUserByType, setUserData } from '@/lib/pdp';
import { VForm } from 'vuetify/lib/components';
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import { selectablePdpYears } from '@/utils';

export default {
  name: 'NTSPdpDetails',
  components: { AdsTextField, AdsButton, VForm },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    formValid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ADS_Colors,
      ownerDetails: {
        year: null,
        position: null,
        school: null,
      },
      searchOwnerSchool: null,
      searchSupervisorSchool: null,
      searchAdditionalSupervisorSchool: null,
      searchSupervisor: null,
      searchPrincipal: null,
      searchAdditionalSupervisor: null,
      selectedSupervisorSchool: null,
      selectedAdditionalSupervisorSchool: null,
      selectedSupervisor: null,
      supervisorLocation: null,
      supervisorPosition: null,
      selectedPrincipal: null,
      selectedAdditionalSupervisor: null,
      additionalSupervisorLocation: null,
      additionalSupervisorPosition: null,
      rules: {
        numberFieldRequired: validators.numberFieldRequired,
        supervisorFieldRequired: validators.userFieldRequired,
        stringFieldRequired: validators.stringFieldRequired,
        schoolFieldRequired: validators.schoolFieldRequired,
        emptyOrUserFromListRequired: validators.emptyOrUserFromListRequired,
      },
      isValid: true,
      confirmed: false,
    };
  },
  computed: {
    ...mapGetters(['formPdp', 'isPdpLoaded', 'ownedPdp']),
    ...mapGetters('pdp', ['formPdpPlanSection', 'formPdpFinalSection']),
    ...mapState({
      searchedUsersList: (state) =>
        state.user.usersByIdentifier.filter(
          (user) => user.id !== state.loggedInUser.id
        ),
      searchedSchoolsList: (state) => state.school.schoolsByFullName,
    }),
    selectableYears() {
      const yearSet = [
        ...new Set([
          new Date().getFullYear() - 1,
          new Date().getFullYear(),
          new Date().getFullYear() + 1,
          ...(this.formPdp?.year ? [this.formPdp?.year] : []),
        ]),
      ].sort((a, b) => a - b);
      return selectablePdpYears(this.ownedPdp, yearSet, true);
    },
    pdpYearMessage() {
      switch (true) {
        // case this.isPlanComplete:
        //   return 'PDP year cannot be edited following Plan phase sign off';
        case this.rules.numberFieldRequired(this.ownerDetails.year) !== true &&
          (!this.formValid || !this.isValid):
          return 'Please select from the list';
        default:
          return 'Select the year for this PDP';
      }
    },
    isPlanComplete() {
      return this.formPdpPlanSection?.complete;
    },
    disableSaveButton() {
      return !(
        this.rules.numberFieldRequired(this.ownerDetails.year) === true &&
        this.rules.stringFieldRequired(this.ownerDetails.position) === true &&
        this.rules.schoolFieldRequired(this.ownerDetails.school) === true &&
        this.supervisorFromListMandatory(this.selectedSupervisor) === true &&
        this.rules.stringFieldRequired(this.supervisorPosition) === true &&
        this.rules.schoolFieldRequired(this.selectedSupervisorSchool) ===
          true &&
        this.additionalSupervisorMandatory(
          this.selectedAdditionalSupervisor
        ) === true &&
        this.additionalSupervisorDetailsMandatory(
          this.additionalSupervisorPosition
        ) === true &&
        this.additionalSupervisorSchoolMandatory(
          this.selectedAdditionalSupervisorSchool
        ) === true
      );
    },
    disabledConfirmed() {
      return !(this.disableSaveButton === false && this.confirmed);
    },
    currentSupervisor() {
      return findPdpUserByType(
        this.formPdp?.pdp_user_associations,
        USER_TYPE.supervisor
      );
    },
    currentPrincipal() {
      return findPdpUserByType(
        this.formPdp?.pdp_user_associations,
        USER_TYPE.pdpPrincipal
      );
    },
    currentAdditionalSupervisor() {
      return findPdpUserByType(
        this.formPdp?.pdp_user_associations,
        USER_TYPE.additionalSupervisor
      );
    },
    supervisors() {
      return this.searchedUsersList.filter((user) => {
        return (
          user.id !== this.selectedPrincipal?.id &&
          user.id !== this.selectedAdditionalSupervisor?.id
        );
      });
    },
    additionalSupervisors() {
      return this.searchedUsersList.filter((user) => {
        return (
          user.id !== this.selectedPrincipal?.id &&
          user.id !== this.selectedSupervisor?.id
        );
      });
    },
    principals() {
      return this.searchedUsersList.filter((user) => {
        return (
          user.id !== this.selectedSupervisor?.id &&
          user.id !== this.selectedAdditionalSupervisor?.id
        );
      });
    },
    pdpUsersToSet() {
      return [
        {
          userType: USER_TYPE.supervisor,
          userId: this.selectedSupervisor?.id,
          school_id: this.selectedSupervisorSchool?.id,
          position: this.supervisorPosition,
        },
        {
          userType: USER_TYPE.additionalSupervisor,
          userId: this.selectedAdditionalSupervisor?.id,
          school_id: this.selectedAdditionalSupervisorSchool?.id,
          position: this.additionalSupervisorPosition,
        },
        {
          userType: USER_TYPE.pdpPrincipal,
          userId: this.selectedPrincipal?.id,
        },
      ];
    },
  },
  methods: {
    ...mapActions('pdp', ['savePdp']),
    ...mapActions('user', ['searchUsersByEmail']),
    ...mapActions('school', ['searchSchoolsByFullName']),
    async runUserSearch(newValue, oldValue) {
      if (
        newValue &&
        newValue.trim().length > 1 &&
        !equals(newValue, oldValue) &&
        oldValue
      ) {
        const debouncedSearch = debounce(async () => {
          await this.searchUsersByEmail(newValue);
        }, 150);
        await debouncedSearch();
      }
    },
    async runSchoolSearch(newValue, oldValue) {
      if (
        newValue &&
        newValue.trim().length > 1 &&
        !equals(newValue, oldValue) &&
        oldValue
      ) {
        const debouncedSearch = debounce(async () => {
          await this.searchSchoolsByFullName(newValue);
        }, 150);
        await debouncedSearch();
      }
    },
    additionalSupervisorMandatory() {
      if (
        (this.additionalSupervisorPosition &&
          this.additionalSupervisorPosition.trim().length > 0) ||
        this.selectedAdditionalSupervisorSchool?.id
      ) {
        return this.rules.supervisorFieldRequired(
          this.selectedAdditionalSupervisor,
          ADDITIONAL_SUPERVISOR_ERROR_MESSAGE
        );
      } else {
        return this.rules.emptyOrUserFromListRequired(
          this.selectedAdditionalSupervisor,
          ADDITIONAL_SUPERVISOR_ERROR_MESSAGE
        );
      }
    },
    additionalSupervisorDetailsMandatory() {
      if (
        this.selectedAdditionalSupervisor?.id ||
        (this.additionalSupervisorPosition &&
          this.additionalSupervisorPosition.trim().length > 0) ||
        this.selectedAdditionalSupervisorSchool?.id
      ) {
        return this.rules.stringFieldRequired(
          this.additionalSupervisorPosition
        );
      } else {
        return true;
      }
    },
    additionalSupervisorSchoolMandatory() {
      if (
        this.selectedAdditionalSupervisor?.id ||
        (this.additionalSupervisorPosition &&
          this.additionalSupervisorPosition.trim().length > 0) ||
        this.selectedAdditionalSupervisorSchool?.id
      ) {
        return this.rules.schoolFieldRequired(
          this.selectedAdditionalSupervisorSchool
        );
      } else return true;
    },
    setPdpUserData() {
      const pdp = setUserData(
        this.formPdp,
        this.pdpUsersToSet,
        this.ownerDetails
      );
      this.formPdp.year = pdp.year;
      this.formPdp.userPosition = pdp.userPosition;
      this.formPdp.owner_school_id = pdp.owner_school_id;
      this.formPdp.pdp_user_associations = pdp.pdp_user_associations;
      this.formPdp.supervisorComments = pdp.supervisorComments;
      this.formPdp.pdpPrincipalComments = pdp.pdpPrincipalComments;
      this.formPdp.sections = pdp.sections;
    },
    async submitPdp() {
      if (this.openDialog) {
        const validated = this.$refs.dialogForm.validate();
        if (!validated) {
          return;
        }
      }
      this.setPdpUserData();
      this.$emit(this.openDialog ? 'editPdpUsers' : 'proceed');
    },
    supervisorFromListMandatory(selectedSupervisor) {
      return validators.userFieldRequired(
        selectedSupervisor,
        SUPERVISOR_ERROR_MESSAGE
      );
    },
    pdpPrincipalEmptyOrFromList(selectedPdpPrincipal) {
      return validators.emptyOrUserFromListRequired(
        selectedPdpPrincipal,
        PDP_PRINCIPAL_ERROR_MESSAGE
      );
    },
    confirm() {
      this.confirmed = !this.confirmed;
    },
    setSearchData() {
      if (this.isPdpLoaded) {
        this.ownerDetails = {
          year: this.formPdp.year,
          position: this.formPdp.userPosition,
          school: this.formPdp.owner_school,
        };
        this.selectedSupervisor = this.currentSupervisor?.user;
        this.supervisorPosition = this.currentSupervisor?.position;
        this.supervisorLocation = this.currentSupervisor?.location;
        this.selectedPrincipal = this.currentPrincipal?.user;
        this.selectedAdditionalSupervisor =
          this.currentAdditionalSupervisor?.user;
        this.additionalSupervisorPosition =
          this.currentAdditionalSupervisor?.position;
        this.additionalSupervisorLocation =
          this.currentAdditionalSupervisor?.location;
        this.selectedSupervisorSchool = this.currentSupervisor?.school;
        this.selectedAdditionalSupervisorSchool =
          this.currentAdditionalSupervisor?.school;
        this.confirmed = false;
      }
    },
  },
  watch: {
    isPdpLoaded: {
      handler() {
        this.setSearchData();
      },
      immediate: true,
    },
    openDialog: {
      handler() {
        if (this.openDialog) {
          this.setSearchData();
        }
      },
      immediate: true,
    },
    searchOwnerSchool(newValue, oldValue) {
      this.runSchoolSearch(newValue, oldValue);
    },
    searchSupervisorSchool(newValue, oldValue) {
      this.runSchoolSearch(newValue, oldValue);
    },
    searchAdditionalSupervisorSchool(newValue, oldValue) {
      this.runSchoolSearch(newValue, oldValue);
    },
    searchSupervisor(newValue, oldValue) {
      this.runUserSearch(newValue, oldValue);
    },
    searchPrincipal(newValue, oldValue) {
      this.runUserSearch(newValue, oldValue);
    },
    searchAdditionalSupervisor(newValue, oldValue) {
      this.runUserSearch(newValue, oldValue);
    },
  },
  emits: ['proceed', 'editPdpUsers'],
};
</script>
<style scoped lang="scss">
@import '../scss/variables';

.confirm-box {
  background-color: white;
  border: $ads-navy-dark-1 solid 2px !important;
}
</style>

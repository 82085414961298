import { SectionType } from '@/schema';

export const PDP_ROUTES = {
  myPdp: {
    base: {
      path: '/my-pdp',
      name: 'My PDP',
    },
    pdpId: {
      path: '/my-pdp/:pdpId',
      name: 'My PDP-Id',
    },
    selectedTab: {
      path: '/my-pdp/:pdpId/:selectedTab',
      name: 'My PDP-Tab',
    },
  },
  management: {
    base: {
      path: '/management',
      name: 'Management',
    },
    selectedYear: {
      path: '/management/:selectedYear',
      name: 'Management-Year',
    },
    selectedTab: {
      path: '/management/:selectedYear/:selectedTab',
      name: 'Management-Tab',
    },
    pdpId: {
      path: '/management/:selectedYear/:selectedTab/:pdpId',
      name: 'Supervisor Action',
    },
    actionTab: {
      path: '/management/:selectedYear/:selectedTab/:pdpId/:actionTab',
      name: 'Supervisor Action-Tab',
    },
  },
  pdpGuide: {
    base: {
      path: '/pdp-guide',
      name: 'Pdp Guide',
    },
  },
  login: {
    base: {
      path: '/',
      name: 'Login',
    },
  },
  usefulLinks: {
    base: {
      path: '/useful-links',
      name: 'Useful links',
    },
  },
  support: {
    base: {
      path: '/support',
      name: 'Support',
    },
  },
  notFound: {
    base: {
      path: '*',
      name: 'NotFound',
    },
  },
  notAuthorized: {
    base: {
      path: '/not-authorized',
      name: 'NotAuthorized',
    },
  },
};

export const QPLUS_ROUTES = {
  status: { base: { path: '/status' } },
  fullDetails: { base: { path: '/full-details' } },
  tasks: { base: { path: '/tasks' } },
  resources: { base: { path: '/resources' } },
  documentsAndLinks: { base: { path: '/documents-and-links' } },
  stayConnected: { base: { path: '/stay-connected' } },
  supportFeedback: {
    base: {
      path: '/support-feedback',
      name: 'Support and Feedback',
    },
  },
};

export const MAX_WORK_LEARNING_THEMES = 4;
export const MIN_WORK_GOALS = 3;
export const MAX_WORK_GOALS = 5;
export const NTS_MAX_GOALS = 5;
export const MIN_TS_GOALS = 3;
export const MAX_TS_GOALS = 5;
export const MIN_CAREER_GOALS = 0;
export const WORK_GOAL_DESCRIPTION_MIN_LENGTH = 50;
export const WORK_GOAL_DESCRIPTION_MAX_LENGTH = 500;
export const TS_GOAL_DESCRIPTION_MIN_LENGTH = 100;
export const GOAL_STRATEGIES_MAX_LENGTH = 750;
export const LEARNING_DETAILS_MAX_LENGTH = 5000;
export const NTS_PROGRESS_REPORT_MIN_LENGTH = 50;
export const NTS_PROGRESS_REPORT_MAX_LENGTH = 5000;
export const COMMENTS_MIN_LENGTH = 50;
export const COMMENTS_MAX_LENGTH = 5000;
export const SUPERVISEE_COMMENTS_MAX_LENGTH = 1500;
export const ANNUAL_REVIEW_MIN_LENGTH = 1;
export const ANNUAL_REVIEW_MAX_LENGTH = 5000;

export const PDP_REFRESH_RATE = Number(process.env.PDP_REFRESH_RATE as string)
  ? Number(process.env.PDP_REFRESH_RATE as string)
  : 3000;
export const PDP_SAVE_TIMEOUT = Number(
  process.env.VUE_APP_PDP_SAVE_TIMEOUT as string
)
  ? Number(process.env.VUE_APP_PDP_SAVE_TIMEOUT as string)
  : 5000;

export const TABLE_ITEMS_PER_PAGE_OPTIONS = [10, 25, 50, -1];

export const ROLES = {
  pdpSupervisor: 'PDP Supervisor',
  pastPdpSupervisor: 'Past PDP Supervisor',
  pdpAdditionalSupervisor: 'PDP Additional Supervisor',
  pastPdpAdditionalSupervisor: 'Past PDP Additional Supervisor',
  pdpPrincipal: 'PDP Principal',
  pastPdpPrincipal: 'Past PDP Principal',
  principal: 'Principal',
  owner: 'Owner',
};

export const ACTIONS = {
  inactive: 'INACTIVE',
  none: 'NONE',
  meetSign: 'MEET / SIGN',
  viewSign: 'VIEW / SIGN',
  view: 'VIEW',
  pending: 'Submission pending',
};

export const PDP_TYPE: {
  ts: 'TS';
  nts: 'NTS';
} = {
  ts: 'TS',
  nts: 'NTS',
};

export const SECTION_TYPE: {
  plan: SectionType;
  midYear: SectionType;
  final: SectionType;
} = {
  plan: 'PLAN',
  midYear: 'MID_YEAR',
  final: 'FINAL',
};
export const GOAL_TYPE: {
  work: 'WORK';
  career: 'CAREER';
} = {
  work: 'WORK',
  career: 'CAREER',
};

export const USER_TYPE = {
  owner: 'OWNER',
  supervisor: 'SUPERVISOR',
  additionalSupervisor: 'MANAGER',
  pdpPrincipal: 'PDP_PRINCIPAL',
};

export const PHASE = {
  inactive: {
    stage: 'Inactive',
    status: 'PDP not started',
  },
  complete: {
    stage: 'Complete',
    status: '',
  },
  annualReviewInProgress: {
    stage: 'Review',
    status: 'Annual review in progress',
  },
  selfAssessmentInProgress: {
    stage: 'Review',
    status: 'Self-assessment in progress',
  },
  reviewInProgress: {
    stage: 'Review',
    status: 'In progress',
  },
  implementInProgress: {
    stage: 'Implement',
    status: 'In progress',
  },
  planInProgress: {
    stage: 'Plan',
    status: 'In progress',
  },
  archivedComplete: {
    stage: 'Complete',
    status: '',
  },
  archivedInComplete: {
    stage: 'Incomplete',
    status: '',
  },
  unknown: {
    stage: '',
    status: '',
  },
};

export const ALLOWED_FILE_EXTENSIONS: string[] = [
  '.png',
  '.jpg',
  '.jpeg',
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
];

export const TAB_TYPES = {
  plan: {
    id: 'plan',
    tab: 'Plan',
  },
  goalsAndEvidence: {
    id: 'goals-and-evidence',
    tab: 'Goals & Evidence',
  },
  observations: {
    id: 'observations',
    tab: 'Observations',
  },
  implement: {
    id: 'implement',
    tab: 'Implement',
  },
  review: {
    id: 'review',
    tab: 'Review',
  },
  reviews: {
    id: 'reviews',
    tab: 'Reviews',
  },
  tasks: {
    id: 'tasks',
    tab: 'Tasks',
  },
  team: {
    id: 'team',
    tab: 'My Team',
  },
  school: {
    id: 'school',
    tab: 'My School',
  },
  archived: {
    id: 'archived',
    tab: 'Archived Pdps',
  },
};

export const MANAGEMENT_QUERY_TYPE = {
  tasks: TAB_TYPES.tasks.id,
  team: TAB_TYPES.team.id,
  school: TAB_TYPES.school.id,
  archived: TAB_TYPES.archived.id,
  stats: 'stats',
};

export const PANEL_TYPES = {
  midYear: {
    id: 'mid-year',
    title: 'Self-assessment',
  },
  annual: {
    id: 'annual',
    title: 'Annual review',
  },
};

export const SIGN_BUTTON_STATE = {
  signed: {
    action: 'signed',
    text: 'Signed',
  },
  awaitReview: {
    action: 'await',
    text: 'Please review form',
  },
  inactive: {
    action: 'inactive',
    text: 'Awaiting notification',
  },
  sign: {
    action: 'sign',
    text: 'Sign',
  },
  awaitSign: {
    action: 'await',
    text: 'Awaiting signature',
  },
  pending: {
    action: 'await',
    text: 'Pending',
  },
  default: {
    action: '',
    text: '',
  },
};

export const ADDITIONAL_SUPERVISOR_ERROR_MESSAGE =
  'Please select from the list. If your Additional Supervisor is not appearing in the list below, you need to ask them to access the Digital PDP';
export const SUPERVISOR_ERROR_MESSAGE =
  'Please select from the list. If your PDP Supervisor is not appearing in the list below, you need to ask them to access the Digital PDP';
export const PDP_PRINCIPAL_ERROR_MESSAGE =
  'Please select from the list. If your PDP Principal is not appearing in the list below, you need to ask them to access the Digital PDP';

export const CHART_TYPE = {
  school: 'SCHOOL',
  team: 'TEAM',
};

export const COMMENT_TYPE = {
  personalNotes: 'PERSONAL_NOTES',
  section_notify_supervisor: 'SECTION_NOTIFY_SUPERVISOR',
  section_sign_user: 'SECTION_SIGN_USER',
  section_sign_supervisor: 'SECTION_SIGN_SUPERVISOR',
  section_sign_pdp_principal: 'SECTION_SIGN_PDP_PRINCIPAL',
  section_comment_supervisor: 'SECTION_COMMENT_SUPERVISOR',
  section_comment_user: 'SECTION_COMMENT_USER',
};

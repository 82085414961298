import { Employment, Pdp, PdpUser, Section, User } from './schema';
import {
  ACTIONS,
  PDP_TYPE,
  PHASE,
  ROLES,
  SECTION_TYPE,
  USER_TYPE,
} from '@/data/constants';
import { findPdpUserByType } from '@/lib/pdp';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
const tz = 'Australia/Sydney';

export const toSentenceCase = (str: string): string => {
  const lowerCase = str.toLowerCase();
  return `${lowerCase.charAt(0).toUpperCase()}${lowerCase.slice(1)}`;
};

export const PLAN_SUPERVISER_NOTIFIED = 1;
export const PLAN_USER_SIGNED = 3;
export const PLAN_SUPERVISOR_SIGNED = 2;
export const MID_YEAR_SUPERVISER_NOTIFIED = 4;
export const MID_YEAR_USER_SIGNED = 6;
export const MID_YEAR_SUPERVISOR_SIGNED = 5;
export const FINAL_SUPERVISER_NOTIFIED = 7;
export const FINAL_SUPERVISOR_SIGNED = 8;
export const FINAL_USER_SIGNED = 9;

export const getPlanSection = (sections: Partial<Section>[] | Section[]) =>
  sections.find((s) => s.sectionType === SECTION_TYPE.plan);

export const getMidYearSection = (sections: Partial<Section>[] | Section[]) =>
  sections.find((s) => s.sectionType === SECTION_TYPE.midYear);

export const getFinalSection = (sections: Partial<Section>[] | Section[]) =>
  sections.find((s) => s.sectionType === SECTION_TYPE.final);

export const mapSectionsToIndex = (sections: Section[]): number => {
  const planSection = getPlanSection(sections);
  const midYearSection = getMidYearSection(sections);
  const finalSection = getFinalSection(sections);

  if (finalSection?.userSignature) {
    return FINAL_USER_SIGNED;
  }
  if (finalSection?.supervisorSignature) {
    return FINAL_SUPERVISOR_SIGNED;
  }
  if (finalSection?.notifySupervisor) {
    return FINAL_SUPERVISER_NOTIFIED;
  }
  if (midYearSection?.userSignature) {
    return MID_YEAR_USER_SIGNED;
  }
  if (midYearSection?.supervisorSignature) {
    return MID_YEAR_SUPERVISOR_SIGNED;
  }
  if (midYearSection?.notifySupervisor) {
    return MID_YEAR_SUPERVISER_NOTIFIED;
  }
  if (planSection?.userSignature) {
    return PLAN_USER_SIGNED;
  }
  if (planSection?.supervisorSignature) {
    return PLAN_SUPERVISOR_SIGNED;
  }
  if (planSection?.notifySupervisor) {
    return PLAN_SUPERVISER_NOTIFIED;
  }
  return 0;
};

export const calculatePhase = (
  pdp: Partial<Pdp> | undefined
): {
  stage: string;
  status: string;
} => {
  if (!pdp?.sections) {
    return PHASE.inactive;
  }
  const planSection = getPlanSection(pdp?.sections);
  const midYearSection = getMidYearSection(pdp?.sections);
  const finalSection = getFinalSection(pdp?.sections);

  if (pdp?.type === PDP_TYPE.ts) {
    switch (true) {
      case !pdp.active && finalSection?.complete:
        return PHASE.archivedComplete;
      case !pdp.active && !finalSection?.complete:
        return PHASE.archivedInComplete;
      case pdp.active && finalSection?.complete:
        return PHASE.complete;
      case finalSection?.notifySupervisor:
      case midYearSection?.complete:
        return PHASE.annualReviewInProgress;
      case midYearSection?.notifySupervisor:
        return PHASE.selfAssessmentInProgress;
      case planSection?.complete:
        return PHASE.implementInProgress;
      case planSection?.notifySupervisor:
        return PHASE.planInProgress;
      default:
        return PHASE.planInProgress;
    }
  } else if (pdp?.type === PDP_TYPE.nts) {
    switch (true) {
      case !pdp.active && finalSection?.complete:
        return PHASE.archivedComplete;
      case !pdp.active && !finalSection?.complete:
        return PHASE.archivedInComplete;
      case pdp.active && finalSection?.complete:
        return PHASE.complete;
      case finalSection?.notifySupervisor:
        return PHASE.reviewInProgress;
      case midYearSection?.complete:
        return PHASE.reviewInProgress;
      case midYearSection?.notifySupervisor:
        return PHASE.implementInProgress;
      case planSection?.complete:
        return PHASE.implementInProgress;
      case planSection?.notifySupervisor:
        return PHASE.planInProgress;
      default:
        return PHASE.planInProgress;
    }
  } else return PHASE.unknown;
};

export const calculateTotalFileSize = (pdp?: Pdp): number => {
  const midYearSection = pdp?.sections?.find(
    (section) => section.sectionType === 'MID_YEAR'
  );
  let totalEvidenceSize = 0;
  midYearSection?.goals?.forEach((goal) => {
    if (goal.evidenceFiles) {
      totalEvidenceSize += goal.evidenceFiles.reduce((acc, curr) => {
        return acc + curr.size;
      }, 0);
    }
  });
  const totalObservationSize =
    pdp?.observations?.reduce((acc, curr) => {
      return acc + curr.size;
    }, 0) ?? 0;
  return totalEvidenceSize + totalObservationSize;
};

interface Message {
  heading: string;
  text?: string[];
  icon?: string;
  isSigningBanner?: boolean;
  isActionBanner?: boolean;
  buttonText?: string;
  hasCommentBox?: boolean;
  subText?: string;
}

export const getOrientationBannerMessageForSupervisee = (
  sections: Section[],
  isSigningBanner: boolean
): Message => {
  const planSection = getPlanSection(sections);
  const midYearSection = getMidYearSection(sections);
  const finalSection = getFinalSection(sections);

  if (finalSection?.complete) {
    return {
      heading: 'Your PDP is complete',
      text: [
        'You can prepare for your next PDP cycle by considering carrying forward, revising or creating new goals to reflect achievements and progress you have made in the current year and your ongoing career development.',
      ],
    };
  }

  if (finalSection?.notifySupervisor && finalSection.supervisorSignature) {
    return {
      'heading': 'Your PDP supervisor has signed - Finalise  your PDP',
      'text': [
        'Sign now to complete your PDP. Once signed, your PDP will no longer be editable and will be archived. You can still view it via the dropdown at the top of this page.',
      ],
      isActionBanner: !isSigningBanner,
      isSigningBanner: isSigningBanner,
      'buttonText': 'Sign & Archive',
      hasCommentBox: true,
    };
  }

  if (finalSection?.notifySupervisor) {
    return {
      'heading': 'Have a meeting with your PDP supervisor',
      'text': [
        'You have indicated you are ready for your annual review. Remember to organise a time to meet with your PDP supervisor to review your progress towards achieving your professional goals.',
        'Once you have met, your PDP supervisor will sign.',
      ],
    };
  }

  if (midYearSection?.complete) {
    return {
      'heading': 'Continue implementing your PDP',
      'text': [
        'Implement the planned strategies, support and professional learning that will lead to the achievement of your goals. You can also make goal adjustments or add new strategies or support structures.',
      ],
    };
  }

  if (midYearSection?.notifySupervisor && midYearSection.supervisorSignature) {
    return {
      'heading': 'Your PDP supervisor has signed',
      'text': [
        'Your PDP supervisor has acknowledged your self-assessment. Sign now to complete this part.',
      ],
      isActionBanner: !isSigningBanner,
      isSigningBanner: isSigningBanner,
      'buttonText': 'Sign',
      hasCommentBox: true,
    };
  }

  if (midYearSection?.notifySupervisor) {
    return {
      'heading': 'Continue implementing your PDP',
      'text': [
        'You have indicated that you have completed the self-assessment. You may wish to discuss it with your PDP supervisor and use it as part of the annual review process.',
      ],
    };
  }

  if (planSection?.complete) {
    // You are now in Implement Phase
    return {
      heading: 'Implement',
      text: [
        'Implement the planned strategies, support and professional learning that will lead to the achievement of your goals. You can also make goal adjustments as required in consultation with your PDP supervisor.',
      ],
    };
  }

  if (
    planSection?.notifySupervisor &&
    planSection.supervisorSignature &&
    !planSection.userSignature
  ) {
    return {
      'heading': 'Your PDP supervisor has signed',
      'text': [
        'Your PDP supervisor has signed their acknowledgement. Your sign-off will move you to implement phase.',
        'If you edit your goals, you will be prompted to re-notify your PDP supervisor.',
        'You will be able to adjust your goals throughout the performance and development cycle to suit your needs, in consultation with your PDP supervisor.',
      ],
      'isActionBanner': !isSigningBanner,
      'buttonText': 'Sign',
      'isSigningBanner': isSigningBanner,
    };
  }
  if (
    planSection?.notifySupervisor &&
    !planSection.supervisorSignature &&
    !planSection.userSignature
  ) {
    return {
      heading: 'Have a meeting with your PDP supervisor',
      text: [
        'You have indicated that you are ready to discuss your PDP with your PDP supervisor. Remember to organise a time to have a meeting together.',
        'The plan phase of the PDP is finalised at this meeting when both parties have signed. You will be notified once your PDP supervisor has signed. In the meantime, feel free to make any further adjustments to your PDP below.',
      ],
      icon: 'mdi-account-multiple-outline',
    };
  }

  // if (!planSection?.notifySupervisor) {
  //   return {
  //     'heading':
  //       'Welcome to your digital Performance and Development Plan (PDP)',
  //     'text': [
  //       'Once your goals are drafted and youre ready to share them, you can notify your PDP supervisor.',
  //       'Head to the <a href="https://education.nsw.gov.au/inside-the-department/human-resources/performance/performance-tools/digital-pdp">Digital PDP page</a> on the intranet to find key resources to help you complete your PDP.',
  //     ],
  //   };
  // }

  return { heading: "I'm message zero" };
};

export const getOrientationBannerMessageForSupervisor = (
  pdp: Pdp,
  sections: Section[],
  isSigningBanner: boolean,
  userName: string
): Message => {
  if (!sections)
    return {
      heading: "I'm message zero",
      icon: 'mdi-timer-outline',
    };

  const planSection = getPlanSection(sections);
  const midYearSection = getMidYearSection(sections);
  const finalSection = getFinalSection(sections);

  if (finalSection?.complete) {
    return {
      'heading': `${userName}'s ${pdp.year} PDP is complete`,
    };
  }

  if (
    finalSection?.notifySupervisor &&
    finalSection?.supervisorSignature &&
    !finalSection.userSignature
  ) {
    return {
      heading: 'Waiting for supervisee to sign',
      icon: 'mdi-timer-outline',
    };
  }

  if (finalSection?.notifySupervisor && !finalSection.supervisorSignature) {
    return {
      heading: 'Annual Review completed',
      text: [
        `${userName} is ready to meet to discuss their PDP. Once you've had your discussion, select <strong>We've met</strong> and complete the acknowledgement.`,
        'Completing this step allows the supervisee to also complete their acknowledgement of the meeting and complete the PDP cycle for the year.',
      ],
      icon: 'mdi-account-multiple-outline',
      isActionBanner: !isSigningBanner,
      isSigningBanner: isSigningBanner,
      buttonText: "We've met",
      hasCommentBox: true,
      subText:
        'Please note, if the supervisee resubmits their annual review, you will be able to view your previously entered comments and submit new feedback.',
    };
  }

  if (
    midYearSection?.notifySupervisor &&
    midYearSection.supervisorSignature &&
    !midYearSection.userSignature
  ) {
    return {
      'heading': 'Waiting for supervisee to sign',
      'icon': 'mdi-timer-outline',
    };
  }

  if (midYearSection?.notifySupervisor && !midYearSection.supervisorSignature) {
    return {
      heading: 'Acknowledgement of self-assessment',
      text: ['Confirm that you have sighted the PDP.'],
      isSigningBanner: isSigningBanner,
      isActionBanner: !isSigningBanner,
      icon: 'mdi-thumb-up-outline',
      buttonText: 'Submit',
      hasCommentBox: true,
      subText:
        'Please note, if the supervisee resubmits their self-assessment, you will be able to view your previously entered comments and submit new feedback.',
    };
  }

  if (!midYearSection?.notifySupervisor && planSection?.complete) {
    return {
      heading: 'Waiting for submission from supervisee',
      icon: 'mdi-timer-outline',
    };
  }

  if (
    planSection?.notifySupervisor &&
    planSection.supervisorSignature &&
    !planSection.userSignature
  ) {
    return {
      'heading': 'Waiting for submission from supervisee',
      'icon': 'mdi-timer-outline',
    };
  }

  if (planSection?.notifySupervisor && !planSection.supervisorSignature) {
    if (isSigningBanner) {
      return {
        heading: 'Acknowledgement of meeting',
        text: [
          `Confirm that you have met and discussed the PDP with ${userName}, then select <strong>Submit</strong>`,
        ],
        isSigningBanner: true,
        icon: 'mdi-thumb-up-outline',
      };
      // return this.messages[23];
    } else {
      return {
        heading: "Ready to meet - 'plan phase'",
        text: [
          `${userName} is ready to meet to discuss their PDP. Once you've had your discussion, select <strong>We've met</strong> and complete the acknowledgement.`,
          `Completing this step allows ${userName} to also complete their acknowledgement of the meeting and progress to the 'implement phase'.`,
        ],
        isActionBanner: !isSigningBanner,
        icon: 'mdi-account-multiple-outline',
        isSigningBanner: isSigningBanner,
        buttonText: "We've met",
      };
      // return this.messages[11];
    }
  }

  if (!planSection?.notifySupervisor) {
    return {
      heading: 'Waiting for submission from staff',
      icon: 'mdi-timer-outline',
    };
    // return messages[16];
  }

  return {
    heading: 'Waiting for submission from staff',
    icon: 'mdi-timer-outline',
  };
};

export const formatDate = (date?: string | number, format?: string) => {
  if (date && typeof date === 'number') {
    return dayjs(date).format(format ?? 'DD-MM-YYYY');
  } else if (date && typeof date === 'string')
    return dayjs
      .utc(date)
      .tz(tz)
      .format(format ?? 'DD-MM-YYYY');
};

export const formatDateTime = (date?: string | number) => {
  if (date) return dayjs.utc(date).tz(tz).format('DD-MM-YYYY hh:mm:ss a');
};

export const formatDateTimeSlash = (date?: string | number) => {
  if (date) return dayjs.utc(date).tz(tz).format('DD/MM/YYYY hh:mm a');
};

export const isDateBefore = (date1: string, date2: string) => {
  return dayjs(date1).isBefore(dayjs(date2));
};

export const isNonEmptyString = (value?: string): boolean => {
  if (typeof value === 'string') {
    return value.trim().length !== 0;
  } else return false;
};

export const calculateAction = (
  pdp: Partial<Pdp>,
  role: string | undefined
) => {
  if (!pdp.sections || !role) {
    return ACTIONS.inactive;
  }
  const planSection = getPlanSection(pdp.sections);
  const midYearSection = getMidYearSection(pdp.sections);
  const finalSection = getFinalSection(pdp.sections);

  if (!pdp.active) {
    return ACTIONS.view;
  }
  if (pdp.type === PDP_TYPE.ts) {
    switch (role) {
      case ROLES.pastPdpSupervisor:
      case ROLES.pastPdpAdditionalSupervisor:
      case ROLES.pastPdpPrincipal:
        return ACTIONS.view; // PDP-1026 Change 'None' to 'View' as a temporary solution
      case ROLES.pdpSupervisor:
        switch (true) {
          case planSection?.notifySupervisor &&
            !planSection?.supervisorSignature &&
            !planSection?.complete:
          case finalSection?.notifySupervisor &&
            !finalSection?.supervisorSignature &&
            !finalSection?.complete:
            return ACTIONS.meetSign;
          case midYearSection?.notifySupervisor &&
            !midYearSection?.supervisorSignature &&
            !midYearSection.complete:
            return ACTIONS.viewSign;
          default:
            return ACTIONS.view; // PDP-1026 Change 'None' to 'View' as a temporary solution
        }
      case ROLES.principal:
        return ACTIONS.view; // PDP-1026 Change 'None' to 'View' as a temporary solution
      default:
        return ACTIONS.view; // PDP-1026 Change 'None' to 'View' as a temporary solution
    }
  } else if (pdp.type === PDP_TYPE.nts) {
    switch (role) {
      case ROLES.pastPdpSupervisor:
      case ROLES.pastPdpAdditionalSupervisor:
      case ROLES.pastPdpPrincipal:
        return ACTIONS.view; // PDP-1026 Change 'None' to 'View' as a temporary solution
      case ROLES.pdpSupervisor:
        switch (true) {
          case planSection?.notifySupervisor &&
            !planSection?.supervisorSignature &&
            !planSection?.complete:
          case finalSection?.notifySupervisor &&
            !finalSection?.supervisorSignature &&
            !finalSection?.complete:
          case midYearSection?.notifySupervisor &&
            !midYearSection?.supervisorSignature &&
            !midYearSection.complete:
            return ACTIONS.viewSign;
          default:
            return ACTIONS.view; // PDP-1026 Change 'None' to 'View' as a temporary solution
        }
      case ROLES.pdpPrincipal:
        switch (true) {
          case planSection?.notifySupervisor &&
            !planSection?.principalSignature &&
            !pdp.finalCommentLocked:
          case finalSection?.notifySupervisor &&
            !finalSection?.principalSignature &&
            !pdp.finalCommentLocked:
          case midYearSection?.notifySupervisor &&
            !midYearSection?.principalSignature &&
            !pdp.finalCommentLocked:
            return ACTIONS.viewSign;
          default:
            return ACTIONS.view; // PDP-1026 Change 'None' to 'View' as a temporary solution
        }
      case ROLES.pdpAdditionalSupervisor:
        return ACTIONS.view; // PDP-1026 Change 'None' to 'View' as a temporary solution
      case ROLES.principal:
        return ACTIONS.view; // PDP-1026 Change 'None' to 'View' as a temporary solution
      default:
        return ACTIONS.view; // PDP-1026 Change 'None' to 'View' as a temporary solution
    }
  }
};

export const getRole = (
  phase: {
    stage: string;
    status: string;
  },
  users: PdpUser[] | undefined,
  userId: number,
  type: string,
  school: boolean
) => {
  if (school && phase === PHASE.inactive) return ROLES.principal;
  else if (users) {
    if (type === PDP_TYPE.ts) {
      const isActiveSupervisor = !!users.find(
        (user) =>
          user.user_id === userId &&
          user.type === USER_TYPE.supervisor &&
          user.active
      );
      const isPastSupervisor = !!users.filter(
        (user) =>
          user.user_id === userId &&
          user.type === USER_TYPE.supervisor &&
          !user.active
      ).length;
      switch (true) {
        case isActiveSupervisor: {
          return ROLES.pdpSupervisor;
        }
        case isPastSupervisor && !school: {
          return ROLES.pastPdpSupervisor;
        }
        case school: {
          return ROLES.principal;
        }
      }
    }

    if (type === PDP_TYPE.nts) {
      const isActiveSupervisor = !!users.find(
        (user) =>
          user.user_id === userId &&
          user.type === USER_TYPE.supervisor &&
          user.active
      );
      const isActiveManager = !!users.find(
        (user) =>
          user.user_id === userId &&
          user.type === USER_TYPE.additionalSupervisor &&
          user.active
      );
      const isActivePrincipal = !!users.find(
        (user) =>
          user.user_id === userId &&
          user.type === USER_TYPE.pdpPrincipal &&
          user.active
      );
      const isPastSupervisor = !!users.filter(
        (user) =>
          user.user_id === userId &&
          user.type === USER_TYPE.supervisor &&
          !user.active
      ).length;
      const isPastManager = !!users.filter(
        (user) =>
          user.user_id === userId &&
          user.type === USER_TYPE.additionalSupervisor &&
          !user.active
      ).length;
      const isPastPrincipal = !!users.filter(
        (user) =>
          user.user_id === userId &&
          user.type === USER_TYPE.pdpPrincipal &&
          !user.active
      ).length;

      switch (true) {
        case isActiveSupervisor: {
          return ROLES.pdpSupervisor;
        }
        case isActiveManager: {
          return ROLES.pdpAdditionalSupervisor;
        }
        case isActivePrincipal: {
          return ROLES.pdpPrincipal;
        }
        case isPastSupervisor && !school: {
          return ROLES.pastPdpSupervisor;
        }
        case isPastManager && !school: {
          return ROLES.pastPdpAdditionalSupervisor;
        }
        case isPastPrincipal && !school: {
          return ROLES.pastPdpPrincipal;
        }
        case school: {
          return ROLES.principal;
        }
      }
    }
  } else if (school) return ROLES.principal;
};

export const mapPdpsForPresentation = (
  pdps: Partial<Pdp>[],
  userId: number,
  school = false,
  schoolCode = ''
) => {
  const filteredPdps = pdps?.filter((item) => item.user_id !== userId);
  const pdpIds = [
    ...new Set(filteredPdps.map((pdp) => pdp.id as unknown as number)),
  ];
  const nonDuplicates: Partial<Pdp>[] = [];
  for (const pdpId of pdpIds) {
    const foundPdp = filteredPdps.find(
      (item) => (item.id as unknown as number) === pdpId
    );
    nonDuplicates.push(...(foundPdp ? [foundPdp] : []));
  }
  return nonDuplicates
    ?.map((pdp: Partial<Pdp>) => {
      const phase = calculatePhase(pdp);
      const role = getRole(
        phase,
        pdp.pdp_user_associations,
        userId,
        pdp.type as string,
        school ? pdp.owner_school?.schoolCode === schoolCode : false
      );
      return {
        ...pdp,
        phase: phase,
        action: calculateAction(pdp, pdp.role),
        role: role,
      };
    })
    .filter((pdp) => {
      if (school) {
        return pdp.user_id !== userId;
      } else {
        switch (pdp.role) {
          case ROLES.pdpSupervisor:
          case ROLES.pdpAdditionalSupervisor:
          case ROLES.pdpPrincipal:
          case ROLES.principal:
            return true;
          case ROLES.pastPdpSupervisor:
            return pdp.sections?.some(
              (section) => section.supervisor_id === userId
            );
          case ROLES.pastPdpAdditionalSupervisor:
            return pdp.sections?.some(
              (section) => section.manager_id === userId
            );
          case ROLES.pastPdpPrincipal:
            return pdp.sections?.some(
              (section) => section.principal_id === userId
            );
        }
      }
    });
};

export const formatName = (user: User | undefined) => {
  return user?.staffPreferredName && user?.staffPreferredName !== ''
    ? `${user?.staffPreferredName}`
    : `${user?.staffGivenName} ${user?.staffMiddleName ?? ''} ${
        user?.staffFamilyName
      }`;
};

export const getPositions = (employment: Employment[]) => {
  if (employment?.length) {
    const positionSet = new Set(
      employment.map((employment) => employment.staffPositionName)
    );
    const positionsArray = Array.from(positionSet);
    return positionsArray.length ? positionsArray.join(', ') : 'No Position';
  } else return 'No Position';
};

export const getDisplayRole = (pdpUser: PdpUser) => {
  switch (pdpUser.type) {
    case USER_TYPE.supervisor:
      return 'Supervisor';
    case USER_TYPE.additionalSupervisor:
      return 'Additional Supervisor';
    case USER_TYPE.pdpPrincipal:
      return 'Principal';
  }
};

export const numberToWord = (number: number) => {
  const single = [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
  ];
  return single[number];
};

export const areArraysEqual = (arr1: Array<never>, arr2: Array<never>) => {
  if (Array.isArray(arr1) && Array.isArray(arr2)) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);
    for (const element of set1) {
      if (!set2.has(element)) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
};

export const areStringsEqual = (string1: string, string2: string) => {
  if ((!string1 || string1 === '') && (!string2 || string2 === '')) {
    return true;
  }
  return string1 === string2;
};

export const getDisplayUsers = (users: PdpUser[]) => {
  const userTypes = [
    USER_TYPE.supervisor,
    USER_TYPE.additionalSupervisor,
    USER_TYPE.pdpPrincipal,
  ];
  const pdpUsers: PdpUser[] = [];
  for (const userType of userTypes) {
    const user = findPdpUserByType(users, userType);
    if (user) {
      pdpUsers.push(user);
    }
  }
  return pdpUsers.map((pdpUser: PdpUser) => {
    return {
      givenName: pdpUser.user?.staffGivenName,
      surname: pdpUser.user?.staffFamilyName,
      displayedName: pdpUser.user ? formatName(pdpUser.user) : '',
      pdpRole: getDisplayRole(pdpUser),
    };
  });
};
export const calculateStats = (
  pdps: Pdp[],
  initial: {
    plan: number;
    implement: number;
    review: number;
    inactive?: number;
    complete: number;
  }
) => {
  const pastRoles = [
    ROLES.pastPdpSupervisor,
    ROLES.pastPdpAdditionalSupervisor,
    ROLES.pastPdpPrincipal,
  ];
  const result = initial;
  if (pdps) {
    pdps.forEach((pdp: Pdp) => {
      if (pdp.role && !pastRoles.includes(pdp.role)) {
        switch (pdp.phase?.stage) {
          case PHASE.planInProgress.stage:
            result.plan += 1;
            break;
          case PHASE.implementInProgress.stage:
            result.implement += 1;
            break;
          case PHASE.reviewInProgress.stage:
            result.review += 1;
            break;
          case PHASE.complete.stage:
            result.complete += 1;
            break;
          case PHASE.inactive.stage:
            if (typeof result.inactive === 'number') {
              result.inactive += 1;
            }
            break;
          default:
            break;
        }
      }
    });
  }
  return result;
};

export const isSupervisorOfType = (
  pdpType: Pdp['type'],
  superviseePdps: Pdp[] | undefined
) => {
  return superviseePdps?.some((pdp) => pdp?.type === pdpType);
};

export const getFilterYear = (pdps: Pdp[]) => {
  return [...new Set(pdps.map((item) => item.year))]
    .map((item) => ({
      id: item,
      name: `${item}`,
    }))
    .sort((a, b) => b.id - a.id);
};

export const isNumberInStringArray = (
  numberString: string | null,
  id: number
) => {
  try {
    let pastSupervisorsIntArray: number[] = [];
    if (numberString) {
      try {
        pastSupervisorsIntArray = JSON.parse(numberString).map((item: string) =>
          parseInt(item.trim())
        );
      } catch (err) {
        pastSupervisorsIntArray = numberString
          .split(',')
          .map((x: string) => parseInt(x.trim()));
      }
    }
    return pastSupervisorsIntArray.some((item) => item === id);
  } catch (err) {
    throw new Error('Wrong string format');
  }
};

export const isPrincipalInSameSchool = (
  employment: Employment[],
  location: string,
  userIsPrincipal: boolean
) => {
  return (
    employment.some((item) => item.staffSchoolCode === location) &&
    userIsPrincipal
  );
};

export const extractStringFromHtml = (html: string | null | undefined) => {
  return html ? trimHtml(html)?.replace(/<[^>]+>/g, '') : html;
};

export const trimHtml = (html: string | null) => {
  return html?.replace(/(<p>(\s*|<br>)<\/p>)*$/g, '');
};

export const isBoolInSet = (items: boolean[], predicate: boolean) => {
  const boolSet = new Set(items);
  return boolSet.has(predicate);
};

export const selectableSupervisorYears = () => {
  const startYear = 2023;
  const endYear = new Date().getFullYear() + 1;
  return [...Array(endYear - startYear + 1).keys()].map((item) => 2023 + item);
};

export const selectablePdpYears = (
  ownedPdp: Pdp[],
  yearOptions: number[],
  override = false
) => {
  const yearList = [];
  for (const yearOption of yearOptions) {
    if (
      !ownedPdp?.some(
        (pdp) =>
          pdp.year === yearOption &&
          pdp.sections.some(
            (section) =>
              section.sectionType === SECTION_TYPE.final && section.complete
          )
      ) ||
      override
    ) {
      yearList.push(yearOption);
    }
  }
  return yearList;
};

import { RootState, SchoolState } from '../types';
import { School } from '@/schema';
import api from '../../api';
import { ActionContext } from 'vuex';

export default {
  namespaced: true,
  state: (): SchoolState => ({
    schools: [],
    schoolsByFullName: [],
  }),
  mutations: {
    UPDATE_SCHOOLS(state: SchoolState, schools: School[]) {
      state.schools = schools;
    },
    UPDATE_SCHOOLS_BY_FULLNAME(state: SchoolState, schools: School[]) {
      state.schoolsByFullName = schools;
    },
  },
  actions: {
    async fetchAllSchools({
      commit,
      state,
    }: ActionContext<RootState, RootState>) {
      if (!state.isLoading) {
        commit('SET_IS_LOADING', true, { root: true });
      }
      const schools = await api.school.getAllSchools();
      commit('UPDATE_SCHOOLS', schools);
      commit('SET_IS_LOADING', false, { root: true });
    },
    async searchSchoolsByFullName(
      { commit }: ActionContext<RootState, RootState>,
      schoolFullName: string
    ) {
      const schools = await api.school.getSchoolsByFullName(schoolFullName);
      commit('UPDATE_SCHOOLS_BY_FULLNAME', schools);
    },
  },
};

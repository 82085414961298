import api from '../../api';
import {
  Comment,
  Pdp,
  Section,
  SectionLearningTheme,
  SectionType,
} from '@/schema';
import { ActionContext } from 'vuex';
import { PdpState, PdpStats, RootState } from '../types';
import { Generated } from 'kysely';
import { clone, equals } from 'ramda';
import {
  findSectionByType,
  findUserBySection,
  mapLegacyComments,
} from '@/lib/pdp';
import {
  COMMENT_TYPE,
  MANAGEMENT_QUERY_TYPE,
  PDP_REFRESH_RATE,
  PDP_SAVE_TIMEOUT,
  SECTION_TYPE,
  USER_TYPE,
} from '@/data/constants';
import { AxiosError } from 'axios';

export default {
  namespaced: true,
  state: {
    pdp: undefined,
    formPdp: undefined,
    actionPdp: undefined,
    originalActionPdp: undefined,
    ownedPdp: undefined,
    taskPdp: undefined,
    superviseePdp: undefined,
    schoolPdp: undefined,
    archiveManagementPdp: undefined,
    taskPdpLength: undefined,
    superviseePdpLength: undefined,
    schoolPdpLength: undefined,
    archiveManagementPdpLength: undefined,
    isDirty: false,
    isLoaded: undefined,
    isExpired: false,
    stats: undefined,
    intervalId: undefined,
    saveTimeoutId: undefined,
    isSaving: undefined,
    isSaved: undefined,
    personalNotes: undefined,
  } as PdpState,
  getters: {
    actionPdpPlanSection(state: PdpState) {
      let pdpSection;
      if (state.actionPdp) {
        pdpSection = findSectionByType(state.actionPdp, SECTION_TYPE.plan);
      }
      return pdpSection;
    },
    actionPdpMidYearSection(state: PdpState) {
      let pdpSection;
      if (state.actionPdp) {
        pdpSection = findSectionByType(state.actionPdp, SECTION_TYPE.midYear);
      }
      return pdpSection;
    },
    actionPdpFinalSection(state: PdpState) {
      let pdpSection;
      if (state.actionPdp) {
        pdpSection = findSectionByType(state.actionPdp, SECTION_TYPE.final);
      }
      return pdpSection;
    },
    originalActionPdpPlanSection(state: PdpState) {
      let pdpSection;
      if (state.originalActionPdp) {
        pdpSection = findSectionByType(
          state.originalActionPdp,
          SECTION_TYPE.plan
        );
      }
      return pdpSection;
    },
    originalActionPdpMidYearSection(state: PdpState) {
      let pdpSection;
      if (state.originalActionPdp) {
        pdpSection = findSectionByType(
          state.originalActionPdp,
          SECTION_TYPE.midYear
        );
      }
      return pdpSection;
    },
    originalActionPdpFinalSection(state: PdpState) {
      let pdpSection;
      if (state.originalActionPdp) {
        pdpSection = findSectionByType(
          state.originalActionPdp,
          SECTION_TYPE.final
        );
      }
      return pdpSection;
    },
    actionPdpUser:
      (state: PdpState) => (sectionType: SectionType, userType: string) => {
        return findUserBySection(state.actionPdp as Pdp, sectionType, userType);
      },
    pdpPlanSection(state: PdpState) {
      let pdpSection;
      if (state.pdp) {
        pdpSection = findSectionByType(state.pdp, SECTION_TYPE.plan);
      }
      return pdpSection;
    },
    pdpMidYearSection(state: PdpState) {
      let pdpSection;
      if (state.pdp) {
        pdpSection = findSectionByType(state.pdp, SECTION_TYPE.midYear);
      }
      return pdpSection;
    },
    pdpFinalSection(state: PdpState) {
      let pdpSection;
      if (state.pdp) {
        pdpSection = findSectionByType(state.pdp, SECTION_TYPE.final);
      }
      return pdpSection;
    },
    formPdpPlanSection(state: PdpState) {
      let pdpSection;
      if (state.formPdp) {
        pdpSection = findSectionByType(state.formPdp, SECTION_TYPE.plan);
      }
      return pdpSection;
    },
    formPdpMidYearSection(state: PdpState) {
      let pdpSection;
      if (state.formPdp) {
        pdpSection = findSectionByType(state.formPdp, SECTION_TYPE.midYear);
      }
      return pdpSection;
    },
    formPdpFinalSection(state: PdpState) {
      let pdpSection;
      if (state.formPdp) {
        pdpSection = findSectionByType(state.formPdp, SECTION_TYPE.final);
      }
      return pdpSection;
    },
    formPdpUser:
      (state: PdpState) => (sectionType: SectionType, userType: string) => {
        return findUserBySection(state.formPdp as Pdp, sectionType, userType);
      },
    pdpStats(state: PdpState) {
      return state.stats;
    },
    intervalId(state: PdpState) {
      return state.intervalId;
    },
    saveTimeoutId(state: PdpState) {
      return state.saveTimeoutId;
    },
    isSaving(state: PdpState) {
      return state.isSaving;
    },
    isSaved(state: PdpState) {
      return state.isSaved;
    },
    isPdpEqual(state: PdpState) {
      return equals(state.pdp, state.formPdp);
    },
    personalNotes(state: PdpState) {
      return state.personalNotes;
    },
  },
  mutations: {
    SET_OWNED_PDP(state: PdpState, pdps: Pdp[]) {
      state.ownedPdp = pdps;
    },
    SET_PDP(state: PdpState, pdp: Pdp) {
      state.pdp = clone(pdp);
      state.formPdp = clone(pdp);
    },
    SET_ACTION_PDP(state: PdpState, pdp: Pdp) {
      state.actionPdp = clone(pdp);
      state.originalActionPdp = clone(pdp);
    },
    SET_ALL_PDPS_TO_UNDEFINED(state: PdpState) {
      state.actionPdp = undefined;
      state.originalActionPdp = undefined;
      state.pdp = undefined;
      state.formPdp = undefined;
      state.ownedPdp = undefined;
    },
    SET_DIRTY(state: PdpState, dirty: boolean): void {
      state.isDirty = dirty;
    },
    SET_EXPIRED(state: PdpState, expired: boolean): void {
      state.isExpired = expired;
    },
    SET_TASK_PDP(state: PdpState, pdp: Pdp[]): void {
      state.taskPdp = pdp;
    },
    SET_SUPERVISEE_PDP(state: PdpState, pdp: Pdp[]): void {
      state.superviseePdp = pdp;
    },
    SET_SCHOOL_PDP(state: PdpState, pdp: Pdp[]): void {
      state.schoolPdp = pdp;
    },
    SET_ARCHIVE_MANAGEMENT_PDP(state: PdpState, pdp: Pdp[]): void {
      state.archiveManagementPdp = pdp;
    },
    SET_TASK_PDP_LENGTH(state: PdpState, length: number): void {
      state.taskPdpLength = length;
    },
    SET_SUPERVISEE_PDP_LENGTH(state: PdpState, length: number): void {
      state.superviseePdpLength = length;
    },
    SET_SCHOOL_PDP_LENGTH(state: PdpState, length: number): void {
      state.schoolPdpLength = length;
    },
    SET_ARCHIVE_MANAGEMENT_PDP_LENGTH(state: PdpState, length: number): void {
      state.archiveManagementPdpLength = length;
    },
    SET_IS_LOADED(state: PdpState, isLoaded: boolean): void {
      state.isLoaded = isLoaded;
    },
    SET_IS_SAVING(state: PdpState, isSaving: boolean): void {
      state.isSaving = isSaving;
    },
    SET_IS_SAVED(state: PdpState, isSaved: boolean): void {
      state.isSaved = isSaved;
    },
    SET_WORK_LEARNING_TEXT(
      state: PdpState,
      payload: Pick<Section, 'id' | 'workLearningText'>
    ) {
      if (state.formPdp) {
        const sectionToModify = state.formPdp.sections?.find(
          (section) => payload.id === section.id
        );
        if (sectionToModify) {
          sectionToModify.workLearningText = payload.workLearningText;
        }
      }
    },
    SET_CAREER_LEARNING_TEXT(
      state: PdpState,
      payload: Pick<Section, 'id' | 'careerLearningText'>
    ): void {
      if (state.formPdp) {
        const sectionToModify = state.formPdp.sections?.find(
          (section) => payload.id === section.id
        );
        if (sectionToModify) {
          sectionToModify.careerLearningText = payload.careerLearningText;
        }
      }
    },
    SET_WORK_LEARNING_THEMES(
      state: PdpState,
      payload: {
        sectionId: Generated<number>;
        themesToSave: SectionLearningTheme[];
      }
    ) {
      if (state.formPdp) {
        const sectionToModify = state.formPdp.sections?.find(
          (section) => payload.sectionId === section.id
        );
        if (sectionToModify) {
          sectionToModify.section_learning_theme_associations = [
            ...payload.themesToSave,
          ];
        }
      }
    },
    SET_PDP_STATS(state: PdpState, payload: PdpStats) {
      state.stats = payload;
    },
    SET_INTERVAL_ID(state: PdpState, payload: number | undefined) {
      state.intervalId = payload;
    },
    SET_SAVE_INTERVAL_ID(state: PdpState, payload: number | undefined) {
      state.saveTimeoutId = payload;
    },
    SET_PERSONAL_NOTES(state: PdpState, payload: string | undefined) {
      state.personalNotes = payload;
    },
  },
  actions: {
    makePdpDirty(
      { commit }: ActionContext<RootState, RootState>,
      dirty: boolean
    ) {
      commit('SET_DIRTY', dirty);
    },
    async getOwnedPdp(
      { commit }: ActionContext<RootState, RootState>,
      user_id: number
    ) {
      commit('SET_IS_LOADING', true, { root: true });
      commit('SET_OWNED_PDP', undefined);
      const pdps = await api.pdp.getUserPdp(user_id);
      if (pdps?.length) {
        commit('SET_OWNED_PDP', pdps);
      } else {
        commit('SET_OWNED_PDP', []);
      }
      commit('SET_IS_LOADING', false, { root: true });
    },
    async resetOwnedPdp({ commit }: ActionContext<RootState, RootState>) {
      commit('SET_OWNED_PDP', undefined);
    },
    async resetUserPdp({ commit }: ActionContext<RootState, RootState>) {
      commit('SET_PDP', undefined);
    },
    async getUserPdp(
      { commit }: ActionContext<RootState, RootState>,
      pdpId: number
    ) {
      commit('SET_IS_LOADING', true, { root: true });
      commit('SET_IS_LOADED', false);
      commit('SET_IS_SAVED', false);
      try {
        const pdp = await api.pdp.getPdpById(pdpId);
        if (pdp) {
          commit('SET_PDP', mapLegacyComments(pdp));
        }
      } finally {
        commit('SET_IS_LOADED', true);
        commit('SET_EXPIRED', false);
        commit('SET_IS_LOADING', false, { root: true });
      }
    },
    async getUserPdpStatus(
      { commit }: ActionContext<RootState, RootState>,
      payload: {
        pdpId: number;
        updated_at: string;
      }
    ) {
      const response = await api.pdp.getPdpStatus(
        payload.pdpId,
        payload.updated_at
      );
      const result = response?.is_expired;
      commit('SET_EXPIRED', result);
    },
    async createUserPdp({ commit }: ActionContext<RootState, RootState>) {
      commit('SET_IS_LOADING', true, { root: true });
      await api.pdp.createPdp();
      commit('SET_IS_LOADING', false, { root: true });
    },
    async getActionPdp(
      { commit }: ActionContext<RootState, RootState>,
      pdpId: number
    ) {
      commit('SET_IS_LOADING', true, { root: true });
      commit('SET_IS_LOADED', false);
      commit('SET_IS_SAVED', false);
      const pdp = await api.pdp.getPdpById(pdpId);
      if (pdp) {
        commit('SET_ACTION_PDP', mapLegacyComments(pdp));
      }
      commit('SET_IS_LOADED', true);
      commit('SET_EXPIRED', false);
      commit('SET_IS_LOADING', false, { root: true });
    },
    async getPdpManagement(
      { commit, rootState }: ActionContext<PdpState, RootState>,
      query: {
        query_type: string;
        page_size: number;
        page: number;
        year: number;
      }
    ) {
      try {
        let response;
        switch (query.query_type) {
          case MANAGEMENT_QUERY_TYPE.tasks:
            commit('SET_TASK_PDP', undefined);
            response = await api.pdp.getPdpManagement(
              MANAGEMENT_QUERY_TYPE.tasks,
              query.year,
              query.page_size,
              query.page
            );
            if (response) {
              commit('SET_TASK_PDP', response.task_pdp);
              commit('SET_TASK_PDP_LENGTH', response.total);
            }
            break;
          case MANAGEMENT_QUERY_TYPE.team:
            commit('SET_SUPERVISEE_PDP', undefined);
            response = await api.pdp.getPdpManagement(
              MANAGEMENT_QUERY_TYPE.team,
              query.year,
              query.page_size,
              query.page
            );
            if (response) {
              commit('SET_SUPERVISEE_PDP', response.supervisee_pdp);
              commit('SET_SUPERVISEE_PDP_LENGTH', response.total);
            }
            break;
          case MANAGEMENT_QUERY_TYPE.school:
            commit('SET_SCHOOL_PDP', undefined);
            response = await api.pdp.getPdpManagement(
              MANAGEMENT_QUERY_TYPE.school,
              query.year,
              query.page_size,
              query.page
            );
            if (response) {
              commit(
                'SET_SCHOOL_PDP',
                rootState.loggedInUser?.isPrincipal ? response.school_pdp : []
              );
              commit('SET_SCHOOL_PDP_LENGTH', response.total);
            }
            break;
          case MANAGEMENT_QUERY_TYPE.archived:
            commit('SET_ARCHIVE_MANAGEMENT_PDP', undefined);
            response = await api.pdp.getPdpManagement(
              MANAGEMENT_QUERY_TYPE.archived,
              query.year,
              query.page_size,
              query.page
            );
            if (response) {
              commit('SET_ARCHIVE_MANAGEMENT_PDP', response.archive_pdp);
              commit('SET_ARCHIVE_MANAGEMENT_PDP_LENGTH', response.total);
            }
            break;
        }
      } catch (e) {
        // console.error(`Error loading ${query.query_type} pdp`, e);
      }
    },
    async getPdpStats(
      { commit }: ActionContext<RootState, RootState>,
      query: {
        year: number;
      }
    ) {
      try {
        const response = await api.pdp.getPdpStats(query.year);
        if (response) {
          commit('SET_PDP_STATS', response);
        }
      } catch (e) {
        commit('SET_IS_LOADING', false, { root: true });
      }
    },
    async getPersonalNotes(
      { commit }: ActionContext<RootState, RootState>,
      pdp_id: number
    ) {
      try {
        commit('SET_IS_LOADING', true, { root: true });
        const response = await api.pdp.getPersonalNotes(pdp_id);
        if (response) {
          commit('SET_PERSONAL_NOTES', response);
        }
        commit('SET_IS_LOADING', false, { root: true });
      } catch (e) {
        commit('SET_IS_LOADING', false, { root: true });
      }
    },
    async updatePersonalNotes(
      { commit }: ActionContext<RootState, RootState>,
      comment: Comment
    ) {
      try {
        commit('SET_IS_LOADING', true, { root: true });
        const response = await api.pdp.updatePersonalNotes(comment);
        if (response) {
          commit('SET_PERSONAL_NOTES', response);
        }
        commit('SET_IS_LOADING', false, { root: true });
      } catch (e) {
        commit('SET_IS_LOADING', false, { root: true });
      }
    },
    async savePdp(
      { commit, dispatch }: ActionContext<RootState, RootState>,
      payload: Pdp
    ) {
      try {
        commit('SET_IS_LOADING', true, { root: true });
        commit('SET_IS_LOADED', false);
        commit('SET_IS_SAVED', false);
        commit('SET_IS_SAVING', true);
        commit('SET_EXPIRED', false);

        await dispatch('stopInterval');
        await dispatch('stopSaveTimeout');
        const pdp = await api.pdp.savePdp(payload);
        if (pdp) {
          commit('SET_PDP', mapLegacyComments(pdp));
          commit('SET_IS_LOADED', true);
          commit('SET_IS_SAVED', true);
          commit('SET_IS_SAVING', false);
          commit('SET_EXPIRED', false);
          commit('SET_IS_LOADING', false, { root: true });
        } else {
          commit('SET_IS_LOADING', false, { root: true });

          commit('SET_IS_SAVING', false);
        }
      } catch (e) {
        if (e instanceof AxiosError) {
          commit('SET_IS_LOADED', true);
          commit('SET_IS_LOADING', false, { root: true });
          commit('SET_IS_SAVING', false);
          const response = e.response;
          if (
            response?.data.detail === 'updated_at conflict' &&
            response?.status === 409
          ) {
            commit('SET_EXPIRED', true);
          }
        }
      }
    },
    async saveActionPdp(
      { commit, dispatch }: ActionContext<RootState, RootState>,
      payload: Pdp
    ) {
      try {
        commit('SET_IS_LOADING', true, { root: true });
        commit('SET_IS_LOADED', false);
        commit('SET_IS_SAVED', false);
        commit('SET_IS_SAVING', true);
        commit('SET_EXPIRED', false);
        await dispatch('stopInterval');
        await dispatch('stopSaveTimeout');
        const pdp = await api.pdp.savePdp(payload);
        if (pdp) {
          commit('SET_ACTION_PDP', mapLegacyComments(pdp));
          commit('SET_IS_LOADED', true);
          commit('SET_IS_SAVED', true);
          commit('SET_IS_SAVING', false);
          commit('SET_EXPIRED', false);
          commit('SET_IS_LOADING', false, { root: true });
        } else {
          commit('SET_IS_SAVING', false);
          commit('SET_IS_LOADING', false, { root: true });
        }
      } catch (e) {
        if (e instanceof AxiosError) {
          commit('SET_IS_LOADING', false, { root: true });
          commit('SET_IS_SAVING', false);
          const response = e.response;
          if (
            response?.data.detail === 'updated_at conflict' &&
            response?.status === 409
          ) {
            commit('SET_EXPIRED', true);
          }
        }
      }
    },
    async notifySupervisor(
      { commit, dispatch, state }: ActionContext<PdpState, RootState>,
      payload: Comment
    ) {
      try {
        commit('SET_IS_LOADING', true, { root: true });
        commit('SET_IS_LOADED', false);
        commit('SET_EXPIRED', false);
        await dispatch('stopInterval');
        payload.type = COMMENT_TYPE.section_notify_supervisor;
        payload.updated_at = state.formPdp?.updated_at;
        const notified = await api.pdp.notifySupervisor(payload);
        if (notified) {
          await dispatch('getUserPdp', state.formPdp?.id);
        }
      } catch (e) {
        if (e instanceof AxiosError) {
          commit('SET_IS_LOADED', true);
          commit('SET_IS_LOADING', false, { root: true });
        }
      } finally {
        commit('SET_IS_LOADED', true);
        commit('SET_EXPIRED', false);
        commit('SET_IS_LOADING', false, { root: true });
      }
    },
    async signPdp(
      { commit, dispatch, state }: ActionContext<PdpState, RootState>,
      payload: Comment
    ) {
      try {
        commit('SET_IS_LOADING', true, { root: true });
        commit('SET_IS_LOADED', false);
        commit('SET_EXPIRED', false);
        await dispatch('stopInterval');
        if (payload.type === COMMENT_TYPE.section_sign_user) {
          payload.updated_at = state.formPdp?.updated_at;
        } else if (
          payload.type === COMMENT_TYPE.section_sign_supervisor ||
          payload.type === COMMENT_TYPE.section_sign_pdp_principal
        ) {
          payload.updated_at = state.actionPdp?.updated_at;
        }
        const signed = await api.pdp.signPdp(payload);
        if (signed) {
          if (payload.type === COMMENT_TYPE.section_sign_user) {
            await dispatch('getUserPdp', payload.pdp_id);
          } else if (
            payload.type === COMMENT_TYPE.section_sign_supervisor ||
            payload.type === COMMENT_TYPE.section_sign_pdp_principal
          ) {
            await dispatch('getActionPdp', payload.pdp_id);
          }
        }
      } catch (e) {
        if (e instanceof AxiosError) {
          commit('SET_IS_LOADED', true);
          commit('SET_IS_LOADING', false, { root: true });
        }
      } finally {
        commit('SET_IS_LOADED', true);
        commit('SET_EXPIRED', false);
        commit('SET_IS_LOADING', false, { root: true });
      }
    },
    async commentOnPdp(
      { commit, dispatch, state }: ActionContext<PdpState, RootState>,
      payload: Comment
    ) {
      try {
        commit('SET_IS_LOADING', true, { root: true });
        commit('SET_IS_LOADED', false);
        commit('SET_EXPIRED', false);
        await dispatch('stopInterval');
        if (payload.type === COMMENT_TYPE.section_comment_user) {
          payload.updated_at = state.formPdp?.updated_at;
        } else if (payload.type === COMMENT_TYPE.section_comment_supervisor) {
          payload.updated_at = state.actionPdp?.updated_at;
        }
        const commented = await api.pdp.commentOnPdp(payload);
        if (commented) {
          if (payload.type === COMMENT_TYPE.section_comment_user) {
            await dispatch('getUserPdp', payload.pdp_id);
          } else if (payload.type === COMMENT_TYPE.section_comment_supervisor) {
            await dispatch('getActionPdp', payload.pdp_id);
          }
        }
      } catch (e) {
        if (e instanceof AxiosError) {
          commit('SET_IS_LOADED', true);
          commit('SET_IS_LOADING', false, { root: true });
        }
      } finally {
        commit('SET_IS_LOADED', true);
        commit('SET_EXPIRED', false);
        commit('SET_IS_LOADING', false, { root: true });
      }
    },
    async resetPdpData({ commit }: ActionContext<RootState, RootState>) {
      commit('SET_IS_LOADING', true, { root: true });
      commit('SET_ALL_PDPS_TO_UNDEFINED');
      commit('SET_IS_LOADING', false, { root: true });
      commit('SET_EXPIRED', false);
    },
    async startInterval(
      { commit, dispatch }: ActionContext<PdpState, RootState>,
      payload: {
        pdpId: number;
        updated_at: string;
      }
    ) {
      // console.log(new Date().toLocaleTimeString(), 'start interval check');
      const interval = setInterval(async () => {
        // console.log(new Date().toLocaleTimeString(), '3s interval check');
        await dispatch('getUserPdpStatus', payload);
      }, PDP_REFRESH_RATE);
      commit('SET_INTERVAL_ID', interval);
    },
    stopInterval({ commit, state }: ActionContext<PdpState, RootState>) {
      // console.log(new Date().toLocaleTimeString(), 'stop interval check');
      clearInterval(state.intervalId);
      commit('SET_INTERVAL_ID', undefined);
    },
    async startSaveTimeout(
      { state, commit, dispatch }: ActionContext<PdpState, RootState>,
      payload: string
    ) {
      // console.log(new Date().toLocaleTimeString(), 'start autosave timeout');
      clearTimeout(state.saveTimeoutId);
      commit('SET_IS_SAVED', false);
      const interval = setTimeout(async () => {
        if (payload === USER_TYPE.owner) {
          // console.log('start autosave user');
          await dispatch('savePdp', state.formPdp);
          // console.log('autosaved', state.pdp?.updated_at);
        } else if (
          payload === USER_TYPE.supervisor ||
          payload === USER_TYPE.pdpPrincipal
        ) {
          // console.log('start autosave supervisor');
          await dispatch('saveActionPdp', state.actionPdp);
          // console.log('autosaved', state.actionPdp?.updated_at);
        }
      }, PDP_SAVE_TIMEOUT);
      commit('SET_SAVE_INTERVAL_ID', interval);
    },
    stopSaveTimeout({ commit, state }: ActionContext<PdpState, RootState>) {
      // console.log(new Date().toLocaleTimeString(), 'stop autosave timeout');
      clearInterval(state.saveTimeoutId);
      commit('SET_SAVE_INTERVAL_ID', undefined);
    },
  },
};

import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { tokenRotation } from '@/api/auth';

export const configure = (token?: string) => {
  // console.log(`Bearer ${token}`);
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};
/**
 * Create an axios instance and configure THIS instance.
 * If directly import axios and use, settings here won't take effect.
 * Default headers is 'Content-Type': 'application/json'
 */
const requestConfig: AxiosRequestConfig = {
  // API base URL is configurable and set up in .env.* files
  // baseURL: process.env.VUE_APP_API_BASE_URL,
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  // timeout: 10000,
};
const axiosInstance = axios.create(requestConfig);
// await axiosInstance.interceptors.request.use((request) => {
//   console.log('REQUEST: ', JSON.stringify(request, null, 2));
//   return request;
// });
// axiosInstance.interceptors.response.use((response) => {
//   console.log('RESPONSE: ', JSON.stringify(response, null, 2));
//   return response;
// });

/**
 * GET method
 * @param url
 * @param config
 * @returns {Promise}
 */
export async function get<T>(url: string, config = {}) {
  await tokenRotation();
  return axiosInstance.get<T>(url, config);
}

/**
 * POST method
 * by default, axios uses JSON format - e.g. 'Content-Type': 'application/json'
 * @param url
 * @param data
 * @param config
 * @returns {Promise}
 * @param isForm determines headers['Content-Type']
 */
export async function post(
  url: string,
  data = {},
  config = {},
  isForm = false
) {
  if (isForm) {
    axiosInstance.defaults.headers.post['Content-Type'] =
      'application/x-www-form-urlencoded';
    axiosInstance.defaults.transformRequest = (transformedData) =>
      qs.stringify(transformedData);
  } else {
    // axios default
    axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
    axiosInstance.defaults.transformRequest = undefined;
  }
  await tokenRotation();
  return axiosInstance.post(url, JSON.stringify(data), config);
}

/**
 * PUT method
 * by default, axios uses JSON format - e.g. 'Content-Type': 'application/json'
 * @param url
 * @param data
 * @param config
 * @returns {Promise}
 * @param isForm determines headers['Content-Type']
 */
export async function put(url: string, data = {}, config = {}, isForm = false) {
  if (isForm) {
    axiosInstance.defaults.headers.put['Content-Type'] =
      'application/x-www-form-urlencoded';
    axiosInstance.defaults.transformRequest = (transformedData) =>
      qs.stringify(transformedData);
  } else {
    // axios default
    axiosInstance.defaults.headers.put['Content-Type'] = 'application/json';
    axiosInstance.defaults.transformRequest = undefined;
  }
  await tokenRotation();
  return axiosInstance.put(url, JSON.stringify(data), config);
}

/**
 * PATCH method
 * by default, axios uses JSON format - e.g. 'Content-Type': 'application/json'
 * @param url
 * @param data
 * @param config
 * @returns {Promise}
 * @param isForm determines headers['Content-Type']
 */
export function patch(url: string, data = {}, config = {}, isForm = false) {
  if (isForm) {
    axiosInstance.defaults.headers.patch['Content-Type'] =
      'application/x-www-form-urlencoded';
    axiosInstance.defaults.transformRequest = (transformedData) =>
      qs.stringify(transformedData);
  } else {
    // axios default
    axiosInstance.defaults.headers.patch['Content-Type'] = 'application/json';
    axiosInstance.defaults.transformRequest = undefined;
  }
  return axiosInstance.patch(url, JSON.stringify(data), config);
}

/**
 * DELETE method
 * @param url
 * @param config
 * @returns {Promise}
 */
export function del(url: string, config = {}) {
  return axiosInstance.delete(url, config);
}

// Can add JWT token here for each request
/*axiosInstance.interceptors.request.use(
  function () {
  },
  function () {
  }
);*/

// Can check HTTP response code here and jump to different page (e.g. Offline Page)
/*axiosInstance.interceptors.request.use(
  function () {
  },
  function () {
  }
);*/

export default axiosInstance;

import { router } from '@/router';
import { OAuth } from '@nswdoe/doe-ui-core';
import { Buffer } from 'buffer';
import { configure } from '@/api/http';

globalThis.Buffer = Buffer;

export const authOptions = {
  router: router,
  pkce: true, // (recommended to be true for OpenAM SSO, and required to be true for Azure SSO)
  authoriseUri: process.env.VUE_APP_PDP_AUTH_URL,
  tokenUri: process.env.VUE_APP_PDP_TOKEN_URL, // (this field is only required for Azure SSO)
  logoutUri: process.env.VUE_APP_PDP_LOGOUT_URL,
  forceProdMode: true,
  secureApp: true,
  // clientSecret: process.env.VUE_APP_CSU_AUTH_CLIENT_SECRET,
  params: {
    client_id: process.env.VUE_APP_PDP_AUTH_CLIENT_ID,
    redirect_uri: encodeURIComponent(
      `${window.location.origin}/login/callback`
    ),
    scope: encodeURIComponent(
      `${process.env.VUE_APP_PDP_AUTH_CLIENT_ID}/.default openid profile email`
    ),
    // response_type: 'id_token',
  },
  afterLogin: (accessToken?: string /*, idToken */) => {
    configure(accessToken);
  },
};

export const oauth = (vue: Vue) => {
  OAuth.install(vue, authOptions);
};

<template>
  <div>
    <v-tabs
      v-model="tab"
      class="ma-0 pa-0"
      slider-color="red"
    >
      <v-tab
        v-for="(item, index) in availableTabs"
        :key="`tab${index + 1}`"
      >
        {{ item.tab }}
      </v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item key="tabItem1">
          <NTSSectionReadOnly
            :pdp="formPdp"
            :section="planSection"
            class="mt-12"
          />
        </v-tab-item>
        <v-form
          ref="tabForm"
          v-model="isFormValid"
          lazy-validation
        >
          <v-tab-item key="tabItem2">
            <NTSMidYearReview
              :valid="isFormValid"
              @signed="handleSignedForm"
              @validate="validateTabForm"
            />
          </v-tab-item>
          <v-tab-item key="tabItem3">
            <NTSAnnualReview
              :valid="isFormValid"
              class="px-md-10"
              @signed="handleSignedForm"
              @validate="validateTabForm"
            />
          </v-tab-item>
        </v-form>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import NTSMidYearReview from '@/components/NTSMidYearReview.vue';
import NTSAnnualReview from '@/components/NTSAnnualReview.vue';
import { mapGetters } from 'vuex';
import NTSSectionReadOnly from '@/components/NTSSectionReadOnly';
import { TAB_TYPES } from '@/data/constants';

export default {
  name: 'NTSMyPdpTabs',
  components: {
    NTSSectionReadOnly,
    NTSMidYearReview,
    NTSAnnualReview,
  },
  data() {
    return {
      tab: 0,
      availableTabs: [TAB_TYPES.plan, TAB_TYPES.implement, TAB_TYPES.review],
      isFormValid: false,
      isMidYearValidatedOnStartup: false,
    };
  },
  props: {
    selectedTab: {
      type: String,
      default: '',
    },
    showTabs: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['formPdp', 'pdp', 'isLoading', 'isPdpIncompleteArchived']),
    ...mapGetters('pdp', {
      planSection: 'formPdpPlanSection',
      midYearSection: 'formPdpMidYearSection',
      finalSection: 'formPdpFinalSection',
    }),
    paramPdpId() {
      return parseInt(this.$route.params.pdpId);
    },
  },
  watch: {
    showTabs: {
      async handler() {
        this.validateTabForm();
      },
      immediate: true,
    },
    selectedTab: {
      async handler() {
        if (this.selectedTab) {
          await this.pushToCorrectRoute();
        }
      },
      immediate: true,
    },
    tab: {
      async handler() {
        if (this.availableTabs && this.selectedTab && this.showTabs) {
          await this.syncRouteToTab();
          this.validateTabForm();
        }
      },
      immediate: true,
    },
    paramPdpId: {
      async handler() {
        if (this.paramPdpId && this.showTabs) {
          await this.pushToCorrectRoute();
          this.validateTabForm();
        }
      },
      immediate: true,
    },
    isFormValid: {
      handler() {
        if (this.$refs.tabForm && !this.isMidYearValidatedOnStartup) {
          this.validateTabForm();
          this.isMidYearValidatedOnStartup = true;
        }
      },
    },
  },
  methods: {
    async syncTabToRoute() {
      this.tab = this.availableTabs.findIndex(
        (item) => item.id === this.selectedTab
      );
    },
    async syncRouteToTab() {
      if (this.showTabs && this.selectedTab && !this.isLoading) {
        const tab = this.availableTabs.findIndex(
          (item) => item.id === this.selectedTab
        );
        if (this.tab !== tab) {
          await this.$router.push(
            `/my-pdp/${this.paramPdpId}/${this.availableTabs[this.tab].id}`
          );
        }
      }
    },
    async pushToCorrectRoute() {
      if (this.showTabs && !this.selectedTab && this.paramPdpId) {
        const implementRoute = `/my-pdp/${this.paramPdpId}/${
          this.availableTabs[
            this.availableTabs?.findIndex(
              (item) => item.id === TAB_TYPES.implement.id
            )
          ].id
        }`;
        const reviewRoute = `/my-pdp/${this.paramPdpId}/${
          this.availableTabs[
            this.availableTabs?.findIndex(
              (item) => item.id === TAB_TYPES.review.id
            )
          ].id
        }`;
        if (this.planSection?.complete && this.$route.path !== implementRoute) {
          await this.$router.push(implementRoute);
        } else if (
          this.planSection?.complete &&
          this.midYearSection?.complete &&
          this.finalSection?.notifySupervisor &&
          this.$route.path !== reviewRoute
        ) {
          await this.$router.push(reviewRoute);
        }
      } else if (
        this.availableTabs &&
        this.selectedTab &&
        this.showTabs &&
        this.paramPdpId
      ) {
        await this.syncTabToRoute();
      }
    },
    async handleSignedForm(sectionToBeUpdated, comment) {
      this.$emit('tabSigned', sectionToBeUpdated, comment);
    },
    gtmTrackSign(sectionType) {
      this.$gtm.trackEvent({
        event: null,
        category: 'MyPdp',
        action: 'Supervisee Sign Pdp',
        label: 'OrientationBanner',
        value: `${sectionType}`,
      });
    },
    validateTabForm() {
      this.$refs.tabForm?.validate();
    },
  },
  emits: ['tabSigned'],
};
</script>

<style lang="scss" scoped>
:deep .v-tabs .v-tab {
  font-size: 14px;
  text-align: left;
  letter-spacing: 0.02em;

  &.v-tab--active {
    font-weight: bold;
    color: $ads-grey-01;
  }

  &.v-tab:not(.v-tab--active) {
    color: $ads-grey-01;
  }

  .v-expansion-panel-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 959px) {
  :deep .v-slide-group .v-slide-group__prev {
    &--disabled {
      display: none !important;
    }
  }
}
</style>

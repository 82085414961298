<template>
  <v-container class="pa-0 my-10">
    <SignaturePanel
      v-if="showSignaturePanel"
      :disableSign="disableSign"
      :pdp="pdp"
      :owner="formPdp?.owner"
      :section="formPdpFinalSection"
      :users="formPdp?.pdp_user_associations"
      class="my-10"
      @signed="handleSignedForm"
    />
    <NTSReviewReadOnly
      v-if="
        (formPdpFinalSection &&
          (formPdpFinalSection.supervisorComment ||
            formPdpFinalSection.userComment)) ||
        isPdpIncompleteArchived
      "
      :pdp="pdp"
      :section="formPdpFinalSection"
      class="pb-0 px-0 my-10"
    />
    <v-card
      v-if="!formPdpFinalSection.complete && !isPdpIncompleteArchived"
      class="py-10 px-0 annual-review__card"
    >
      <div class="py-4 px-md-10 display-1 ads-grey-01">Annual review</div>
      <v-row class="px-md-10">
        <v-col class="d-flex flex-column justify-center">
          <p class="mb-0">
            Reflect on the year and prepare for the Annual Review conversation.
          </p>
          <p class="mb-0">Think about what you did and how you did it.</p>
          <p class="mb-0">
            During the review meeting share your reflections and listen openly
            to feedback from the PDP Supervisor.
          </p>
        </v-col>
        <v-col
          v-if="!readOnly"
          class="pl-6"
        >
          <v-card
            class="px-6 pt-5 pb-md-0"
            color="primary-teal"
            elevation="0"
            max-height="140px"
            rounded="lg"
          >
            <v-row
              class="d-flex"
              no-gutters
            >
              <v-col
                class="flex-grow-1 pb-7 pb-md-8 pr-2"
                cols="9"
              >
                <v-card-title
                  class="pa-0 pb-3 font-weight-bold primary-blue--text"
                >
                  Useful links
                </v-card-title>
                <v-card-text class="d-flex px-0 grey-01--text font-weight-bold">
                  <a
                    href="https://education.nsw.gov.au/inside-the-department/human-resources/performance/non-teaching-staff-in-schools/meaningful-conversations"
                    target="_blank"
                  >
                    Meaningful conversations to help us be at our best
                    <v-icon class="ml-2 primary-blue--text">
                      mdi-exit-to-app
                    </v-icon>
                  </a>
                </v-card-text>
              </v-col>
              <v-col
                v-if="!isMobile"
                class="d-flex justify-end align-end image-col"
              >
                <v-img
                  class="fill-height image-content"
                  max-height="115px"
                  max-width="116px"
                  src="../../public/gender-neutral-clipboard.svg"
                ></v-img>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <div class="mt-6 px-md-10">
        <div class="no-break-inside">
          <p class="annual-review-section-title">
            <v-icon
              class="mr-2"
              color="success"
            >
              mdi-message-reply-text-outline
            </v-icon>
            Comments
          </p>

          <p>
            Identify the goals (or any components thereof) that have been
            achieved. Consider factors that impacted the achievement of goals:
            what worked well, what could be done differently. Identify any areas
            to be considered for future planning.
          </p>
        </div>
        <RichTextField
          v-model.trim="annualText"
          :disabled="readOnly || !formPdpMidYearSection.complete"
          :placeholder="
            readOnly
              ? null
              : 'Please enter between 50 characters or more, approx. 10-500 words.'
          "
          :rules="
            formPdpMidYearSection.complete
              ? [rules.stringLengthRequired(COMMENTS_MIN_LENGTH)]
              : []
          "
          label="Add details"
          @validated="handleAnnualTextValidated"
        />
      </div>
      <div class="d-flex flex-row align-center px-md-10">
        <AdsButton
          :disabled="isAnnualTextSaveButtonDisabled"
          buttonText="Save"
          icon="mdi-arrow-right"
          @click="handleSaveAnnualText"
        />
        <SaveTimestamp
          v-if="isSaving || isSaved || !this.isAnnualTextSaveButtonDisabled"
          :pending="!this.isAnnualTextSaveButtonDisabled"
          :updated="pdp.updated_at"
          class="ml-4 d-flex flex-row align-center"
        />
      </div>
    </v-card>
    <v-card
      v-if="showNotifySupervisor"
      class="mt-10 pa-10 no-break-inside"
    >
      <p class="body-1 ads-grey-01">
        Confirm you are ready to make your Annual review available to:
      </p>
      <div
        v-for="pdpUser in pdpUsers"
        :key="pdpUser.id"
        class="d-flex mb-7"
      >
        <InitialsIcon
          :class="readOnly ? 'mr-4' : 'mr-md-4'"
          :givenName="pdpUser.displayedName"
          size="42px"
        />
        <div class="ads-grey-01">
          <div>
            <strong>{{ pdpUser.displayedName }}</strong>
          </div>
          <div>
            {{ pdpUser.pdpRole }}
          </div>
        </div>
      </div>
      <p class="mb-8 body-1 ads-grey-01">
        You will still be able to make changes to your Annual review until it is
        signed.
      </p>
      <AdsButton
        buttonText="Notify Supervisor"
        icon="mdi-arrow-right"
        type="submit"
        @click.prevent="handleNotifySupervisor"
      />
    </v-card>

    <!-- OPTIONAL COMMENT SECTION (AFTER BOTH MANDATORY SIGNATURES OBTAINED) -->
    <v-card
      v-if="
        pdp?.active && formPdpFinalSection?.complete && !pdp?.finalCommentLocked
      "
      class="my-6 pa-8"
    >
      <div>
        <p class="annual-review-section-title">
          <v-icon
            class="mr-2"
            color="success"
          >
            mdi-message-reply-text-outline
          </v-icon>
          Staff Member Comments (Optional)
        </p>
      </div>
      <p>A response by the staff member can be noted.</p>
      <RichTextField
        v-model.trim="finalComment"
        :disabled="readOnly"
        :rules="[rules.maxStringLengthRule(COMMENTS_MAX_LENGTH)]"
        placeholder="Add a comment ..."
      />
      <div class="d-flex flex-row align-center">
        <AdsButton
          :disabled="!hasFinalCommentBeenUpdated"
          buttonText="Save"
          icon="mdi-arrow-right"
          @click="handleSaveFinalComment"
        />
        <SaveTimestamp
          v-if="isSaving || isSaved || hasFinalCommentBeenUpdated"
          :pending="hasFinalCommentBeenUpdated"
          :updated="pdp.updated_at"
          class="ml-4 d-flex flex-row align-center"
        />
      </div>
      <v-card
        v-if="
          pdp?.active && !pdp?.finalCommentLocked && !hasFinalCommentBeenUpdated
        "
        class="mt-8 pa-10"
      >
        <p class="mb-8 title">Finalise your PDP</p>
        <p class="mb-8 body-1 ads-grey-01">
          If your principal wishes to sign your Digital PDP, they need to do so
          before you archive. Once archived your Digital PDP will no longer be
          editable. You can still view your archived PDP at any time.
        </p>
        <p>
          <strong>Confirm move to archive?</strong>
        </p>
        <AdsButton
          buttonText="Archive PDP"
          icon="mdi-arrow-right"
          @click="handleFinalisePdp"
        />
      </v-card>
    </v-card>
    <EventLog
      v-if="formPdpFinalSection?.comments.length || isPdpIncompleteArchived"
      :comments="formPdpFinalSection.comments"
      :pdp="pdp"
      class="mt-10 px-0 px-md-10 py-8"
    />
    <IncompleteArchiveCard
      v-if="isCurrentSectionArchival && isPdpIncompleteArchived"
      :pdp="pdp"
    />
  </v-container>
</template>

<script>
import { AdsButton, InitialsIcon } from '@nswdoe/doe-ui-core';
import { mapActions, mapGetters } from 'vuex';
import SignaturePanel from '@/components/SignaturePanel.vue';
import NTSReviewReadOnly from '@/components/NTSReviewReadOnly.vue';
import EventLog from '@/components/EventLog.vue';
import validators from '@/lib/validators';
import { areStringsEqual, getDisplayUsers } from '@/utils';
import { isAnnualTextValid, isNtsGoalsValid } from '@/lib/dataValidation';
import {
  COMMENT_TYPE,
  COMMENTS_MAX_LENGTH,
  COMMENTS_MIN_LENGTH,
  SUPERVISEE_COMMENTS_MAX_LENGTH,
  USER_TYPE,
} from '@/data/constants';
import {
  archivePdp,
  createComment,
  finalisePdp,
  getPdpSectionOnArchival,
  resetNotification,
  resetPrincipalSignature,
  resetSupervisorSignature,
  resetUserSignature,
} from '@/lib/pdp';
import RichTextField from '@/components/RichTextField.vue';
import IncompleteArchiveCard from '@/components/IncompleteArchiveCard.vue';
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import SaveTimestamp from '@/components/SaveTimestamp.vue';

export default {
  name: 'NTSAnnualReview',
  components: {
    SaveTimestamp,
    IncompleteArchiveCard,
    RichTextField,
    EventLog,
    NTSReviewReadOnly,
    AdsButton,
    InitialsIcon,
    SignaturePanel,
  },
  data() {
    return {
      rules: {
        stringLengthRequired: validators.stringLengthRequired,
        maxStringLengthRule: validators.maxStringLengthRule,
      },
      COMMENTS_MIN_LENGTH,
      COMMENTS_MAX_LENGTH,
      SUPERVISEE_COMMENTS_MAX_LENGTH,
      ADS_Colors,
      isAnnualTextValid: null,
    };
  },
  props: {
    valid: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'loggedInUser',
      'formPdp',
      'pdp',
      'isPdpArchived',
      'isPdpIncompleteArchived',
    ]),
    ...mapGetters('pdp', [
      'formPdpFinalSection',
      'formPdpMidYearSection',
      'isPdpEqual',
      'saveTimeoutId',
      'isSaving',
      'isSaved',
    ]),
    disableSign() {
      return !(
        this.valid &&
        this.isSectionValid &&
        !this.hasAnnualTextBeenUpdated
      );
    },
    annualText: {
      get() {
        return this.formPdp.annualText;
      },
      set(value) {
        this.formPdp.annualText = value;
      },
    },
    finalComment: {
      get() {
        return this.formPdp.finalComment;
      },
      set(value) {
        this.formPdp.finalComment = value;
      },
    },
    pdpUsers() {
      return getDisplayUsers(this.pdp.pdp_user_associations);
    },
    showSignaturePanel() {
      return (
        this.formPdpFinalSection?.notifySupervisor &&
        !this.formPdpFinalSection.complete &&
        !this.isPdpArchived &&
        this.isPdpEqual
      );
    },
    showNotifySupervisor() {
      return (
        this.formPdpMidYearSection.complete &&
        !this.formPdpFinalSection.complete &&
        !this.isPdpIncompleteArchived &&
        !this.formPdpFinalSection?.notifySupervisor &&
        this.isPdpEqual &&
        this.isAnnualTextValid
      );
    },
    isAnnualTextSaveButtonDisabled() {
      return (
        !this.hasAnnualTextBeenUpdated || !this.formPdpMidYearSection.complete
      );
    },
    hasAnnualTextBeenUpdated() {
      return !areStringsEqual(this.pdp?.annualText, this.formPdp?.annualText);
    },
    hasFinalCommentBeenUpdated() {
      return !areStringsEqual(
        this.pdp?.finalComment,
        this.formPdp?.finalComment
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    supervisor() {
      return this.pdp?.pdp_user_associations?.find(
        (user) =>
          user.type === USER_TYPE.supervisor &&
          user.user_id === this.formPdpFinalSection?.supervisor_id
      )?.user;
    },
    manager() {
      return this.pdp?.pdp_user_associations?.find(
        (user) =>
          user.type === USER_TYPE.additionalSupervisor &&
          user.user_id === this.formPdpFinalSection?.manager_id
      )?.user;
    },
    principal() {
      return this.pdp?.pdp_user_associations?.find(
        (user) =>
          user.type === USER_TYPE.pdpPrincipal &&
          user.user_id === this.formPdpFinalSection?.principal_id
      )?.user;
    },
    isSectionValid() {
      return (
        isNtsGoalsValid(this.formPdpMidYearSection) &&
        isAnnualTextValid(this.formPdp)
      );
    },
    pdpSectionOnArchival() {
      return getPdpSectionOnArchival(this.pdp);
    },
    isCurrentSectionArchival() {
      return this.pdpSectionOnArchival?.id === this.formPdpFinalSection.id;
    },
  },
  watch: {
    annualText: {
      async handler() {
        if (!this.isAnnualTextSaveButtonDisabled) {
          this.resetSection();
          await this.autosave();
        } else if (this.saveTimeoutId) {
          this.stopAutosave();
        }
      },
      immediate: true,
    },
    finalComment: {
      async handler() {
        if (this.hasFinalCommentBeenUpdated) {
          await this.autosave();
        } else if (this.saveTimeoutId) {
          this.stopAutosave();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('pdp', [
      'savePdp',
      'getOwnedPdp',
      'notifySupervisor',
      'startSaveTimeout',
      'stopSaveTimeout',
    ]),
    resetSection() {
      this.formPdpFinalSection.supervisorComment = null;
      resetSupervisorSignature(this.formPdpFinalSection);
      resetPrincipalSignature(this.formPdpFinalSection);
      resetUserSignature(this.formPdpFinalSection);
      resetNotification(this.formPdpFinalSection);
    },
    handleAnnualTextValidated(value) {
      this.isAnnualTextValid = value;
    },
    async handleSaveAnnualText() {
      this.resetSection();
      await this.savePdp(this.formPdp);
    },
    async handleNotifySupervisor() {
      if (this.formPdpFinalSection) {
        const notifyData = createComment(
          this.formPdp.id,
          this.formPdpFinalSection.id,
          COMMENT_TYPE.section_notify_supervisor,
          this.formPdp.annualText
        );
        await this.notifySupervisor(notifyData);
        await this.savePdp(this.formPdp);
      }
      this.$vuetify.goTo(0);
    },
    async handleSaveFinalComment() {
      await this.savePdp(this.formPdp);
    },
    async handleFinalisePdp() {
      finalisePdp(this.formPdp);
      archivePdp(this.formPdp);
      await this.savePdp(this.formPdp);
      await this.getOwnedPdp(this.loggedInUser?.id);
      await this.$router.push('/my-pdp/');
    },
    handleSignedForm(sectionToBeUpdated, commentType) {
      if (
        sectionToBeUpdated &&
        commentType === COMMENT_TYPE.section_sign_user
      ) {
        this.$emit('signed', sectionToBeUpdated);
      }
    },
    async autosave() {
      await this.startSaveTimeout(USER_TYPE.owner);
      this.$emit('validate');
    },
    stopAutosave() {
      this.stopSaveTimeout();
    },
  },
  emits: ['signed'],
};
</script>

<style lang="scss" scoped>
.v-divider {
  min-width: 100%;
}

:deep .v-messages:not(.error--text) {
  color: $ads-navy;
  font-size: 16px;
}

.fill-height {
  height: 100%;
}

.image-col {
  position: relative; /* Enable positioning of absolutely positioned image */
}

.image-content {
  position: absolute;
  width: auto; /* Adjust width as necessary */
}

:deep .v-card {
  &__title {
    font-size: 24px;
    line-height: 30px;
    word-break: break-word;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
  }

  .v-image {
    bottom: -14px;
  }
}

.annual-review-section-title {
  color: #000;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}

@media print {
  .annual-review__card > div {
    padding-left: 40px;
    padding-right: 40px;
  }
}
</style>

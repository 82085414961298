var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Dialog',{attrs:{"displayCloseBtn":false,"openDialog":_vm.showExpiredPdpDialog,"maxWidth":"60%","persistent":""},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('PdpUpdateDialog',{staticClass:"ma-0 px-7",attrs:{"openDialog":_vm.showExpiredPdpDialog},on:{"closeExpiredPdpDialog":_vm.closeExpiredPdpDialog}})]},proxy:true}])}),_c('div',[_c('v-app-bar',{attrs:{"color":_vm.ADS_Colors.PRIMARY_BLUE,"elevation":"0"}},[_c('AdsButton',{staticClass:"ma-0 pa-2",staticStyle:{"border-style":"none !important","background-color":"rgba(0, 0, 0, 0) !important","color":"white !important"},attrs:{"buttonText":_vm.actionPdp?.owner
            ? _vm.actionPdp?.owner?.staffPreferredName
              ? `${_vm.actionPdp?.owner?.staffPreferredName} PDP`
              : `${_vm.actionPdp?.owner?.staffGivenName} ${_vm.actionPdp?.owner?.staffFamilyName} PDP`
            : '',"ripple":false,"icon":"mdi-close","left":"","tertiary":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleBackButton.apply(null, arguments)}}})],1),_c('UserBanner',{attrs:{"pdp":_vm.actionPdp,"user":_vm.actionPdp?.owner}}),_c('Ribbon',{attrs:{"pdp":_vm.actionPdp,"planSection":_vm.planSection},on:{"print":_vm.getNotes,"selected":_vm.pdpSelected},scopedSlots:_vm._u([{key:"printContent",fn:function(){return [(_vm.actionPdp?.active)?_c('TSSupervisorPrintContent',{attrs:{"actionPdp":_vm.actionPdp,"activeSection":_vm.activeSection,"finalSection":_vm.finalSection,"finalSupervisor":_vm.finalSupervisor,"isCurrentSupervisor":_vm.isCurrentSupervisor,"isMidYearSupervisor":_vm.isMidYearSupervisor,"isPlanSupervisor":_vm.isPlanSupervisor,"isSigningPrintBanner":_vm.isSigningBanner,"midYearSection":_vm.midYearSection,"midYearSupervisor":_vm.midYearSupervisor,"owner":_vm.actionPdp?.owner,"planSection":_vm.planSection,"selectedTab":_vm.selectedTab,"showTabs":_vm.showTabs,"supervisorName":_vm.supervisorName,"supervisorNotes":_vm.notes,"userIsPrincipal":_vm.userIsSchoolPrincipal}}):_c('TSSupervisorArchivedPrintContent',{attrs:{"actionPdp":_vm.actionPdp,"finalSection":_vm.finalSection,"finalSupervisor":_vm.finalSupervisor,"isCurrentSupervisor":_vm.isCurrentSupervisor,"isMidYearSupervisor":_vm.isMidYearSupervisor,"isPlanSupervisor":_vm.isPlanSupervisor,"isSupervisor":true,"midYearSection":_vm.midYearSection,"midYearSupervisor":_vm.midYearSupervisor,"owner":_vm.actionPdp?.owner,"planSection":_vm.planSection,"selectedTab":_vm.selectedTab,"supervisorNotes":_vm.notes,"userIsPrincipal":_vm.userIsSchoolPrincipal}})]},proxy:true}])}),(!_vm.showTabs)?_c('div',[_c('v-container',{staticClass:"px-0 px-md-8"},[(
            !_vm.isCurrentSupervisor &&
            !_vm.isPlanSupervisor &&
            !_vm.userIsSchoolPrincipal
          )?_c('div',[_c('NoPermissionCard')],1):_c('div',[(_vm.isCurrentSupervisor)?_c('OrientationBanner',{staticClass:"pa-0 mt-3",attrs:{"isSigningPrintBanner":_vm.isSigningBanner,"isSupervisor":true,"pdp":_vm.actionPdp ?? {},"section":_vm.activeSection,"supervisorName":_vm.supervisorName},on:{"signed":_vm.handleSignedForm,"startSigning":function($event){_vm.isSigningBanner = true}}}):_vm._e(),_c('TSPlanReadOnly',{attrs:{"pdp":_vm.actionPdp,"section":_vm.planSection}})],1)])],1):_vm._e(),(_vm.showTabs)?_c('div',[_c('v-tabs',{staticClass:"ma-0 pa-0",attrs:{"slider-color":"red"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.availableTabs),function(item,index){return _c('v-tab',{key:`managementtab${index + 1}`},[_vm._v(" "+_vm._s(item.tab)+" ")])}),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"tabItem1"},[(
                !_vm.isCurrentSupervisor &&
                !_vm.isPlanSupervisor &&
                !_vm.userIsSchoolPrincipal
              )?_c('v-container',[_c('NoPermissionCard')],1):_c('TSPlanReadOnly',{attrs:{"pdp":_vm.actionPdp,"section":_vm.planSection}})],1),_c('v-tab-item',{key:"tabItem2"},[_c('v-container',{staticClass:"pa-0 pa-md-3"},[(
                  !_vm.isCurrentSupervisor &&
                  !_vm.isMidYearSupervisor &&
                  !_vm.userIsSchoolPrincipal
                )?_c('NoPermissionCard'):_c('div',[(
                    _vm.showOrientationBannerOnGoalsAndEvidence &&
                    _vm.isCurrentSupervisor &&
                    !_vm.isActionPdpArchived
                  )?_c('OrientationBanner',{staticClass:"pa-0 mt-3",attrs:{"isSigningPrintBanner":_vm.isSigningBanner,"isSupervisor":true,"pdp":_vm.actionPdp ?? {},"section":_vm.activeSection,"supervisorName":_vm.supervisorName},on:{"signed":_vm.handleSignedForm,"startSigning":function($event){_vm.isSigningBanner = true}}}):_vm._e(),_c('v-card',{staticClass:"my-5"},[(
                      _vm.isActionPdpIncompleteArchived &&
                      !_vm.midYearSection?.goals?.length
                    )?_c('IncompleteFieldCard',{staticClass:"mt-8 py-5 px-0"}):_c('v-container',{staticClass:"px-0 px-md-10 py-5",attrs:{"fluid":""}},[_c('TSPdpGoals',{attrs:{"editable":false,"isSupervisor":true,"owner":_vm.actionPdp?.owner,"section":_vm.midYearSection,"showEditToggle":false,"startEdit":false,"userIsPrincipal":_vm.ownerIsPrincipal}})],1)],1)],1)],1)],1),_c('v-tab-item',{key:"tabItem3"},[(
                !_vm.isCurrentSupervisor &&
                !_vm.isMidYearSupervisor &&
                !_vm.userIsSchoolPrincipal
              )?_c('v-container',[_c('NoPermissionCard')],1):_c('div',[(
                  _vm.isActionPdpIncompleteArchived &&
                  !_vm.actionPdp?.observations?.length
                )?_c('v-container',{staticClass:"px-0 px-md-3 mt-0 pt-0"},[_c('IncompleteFieldCard',{staticClass:"mt-8 py-5 px-0"})],1):_c('Wrapper',{staticClass:"pt-5 px-3",attrs:{"isMobile":_vm.isMobile,"cardClass":"py-5 px-10 mt-5"}},[_c('TSPdpObservations',{attrs:{"edit-mode":false,"pdp":_vm.actionPdp}})],1),(_vm.isActionPdpIncompleteArchived)?_c('v-container',{staticClass:"px-0 px-md-3 mt-0 pt-0"}):_vm._e()],1)],1),_c('v-tab-item',{key:"tabItem4"},[_c('v-container',{staticClass:"px-0 px-md-8"},[(
                  !_vm.isCurrentSupervisor &&
                  !_vm.isMidYearSupervisor &&
                  !_vm.userIsSchoolPrincipal
                )?_c('NoPermissionCard'):_c('div',[(
                    !_vm.showOrientationBannerOnGoalsAndEvidence &&
                    (_vm.isCurrentSupervisor || _vm.finalSection.complete) &&
                    !_vm.isActionPdpArchived
                  )?_c('OrientationBanner',{staticClass:"pa-0 mt-3",attrs:{"isSigningPrintBanner":_vm.isSigningBanner,"isSupervisor":true,"pdp":_vm.actionPdp ?? {},"section":_vm.activeSection,"supervisorName":_vm.supervisorName},on:{"signed":_vm.handleSignedForm,"startSigning":function($event){_vm.isSigningBanner = true}}}):_vm._e(),_c('Wrapper',{staticClass:"mt-8 px-0",attrs:{"isMobile":_vm.isMobile,"cardClass":"py-5 px-10"}},[_c('h4',{staticClass:"display-1 pt-5 mb-5 mx-5 mx-md-0"},[_vm._v("Reviews")]),_c('AdsExpansionPanel',{attrs:{"items":_vm.panelItems,"multiple":""},scopedSlots:_vm._u([{key:`content1`,fn:function(){return [_c('v-container',{staticClass:"pa-0 pa-md-5"},[_c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',{staticClass:"px-0 px-md-4 text-h6"},[_vm._v(" Review of progress ")]),_c('div',{staticClass:"px-0 px-md-4"},[(_vm.actionPdp.midYearText?.length)?_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
                                _vm.actionPdp.midYearText
                                  ? _vm.actionPdp.midYearText
                                  : ''
                              ),expression:"\n                                actionPdp.midYearText\n                                  ? actionPdp.midYearText\n                                  : ''\n                              "}],staticClass:"text-body-1"}):(_vm.isActionPdpIncompleteArchived)?_c('IncompleteFieldCard'):_vm._e()],1),(_vm.midYearSection.comments.length)?_c('v-row',{staticClass:"mx-0 px-0 flex-column"},[_c('v-col',{staticClass:"px-0 px-md-0 no-break-inside"},[(_vm.midYearSection.comments.length)?_c('EventLog',{staticClass:"px-0 px-md-0 elevation-0",attrs:{"comments":_vm.midYearSection.comments,"pdp":_vm.actionPdp}}):_vm._e()],1)],1):_vm._e()],1)],1),(!_vm.midYearSection?.complete)?_c('v-card',{staticClass:"pa-5",attrs:{"flat":""}},[_c('p',{staticClass:"ml-0 ml-md-5"},[_c('strong',[_vm._v("Mid Year Self-Assessment")]),_vm._v(" has not yet been completed ")])]):_vm._e()]},proxy:true},{key:`content2`,fn:function(){return [(!_vm.isCurrentSupervisor && !_vm.userIsSchoolPrincipal)?_c('v-container',{staticClass:"pa-0 pa-md-5"},[_c('NoPermissionCard')],1):(
                          _vm.isCurrentSupervisor || _vm.userIsSchoolPrincipal
                        )?_c('v-container',{staticClass:"pa-0 pa-md-5"},[_c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',{staticClass:"px-0 px-md-4 text-h6"},[_vm._v(" Annual review ")]),_c('div',{staticClass:"px-0 px-md-4"},[(_vm.actionPdp.annualText?.length)?_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
                                _vm.actionPdp?.annualText
                                  ? _vm.actionPdp?.annualText
                                  : ''
                              ),expression:"\n                                actionPdp?.annualText\n                                  ? actionPdp?.annualText\n                                  : ''\n                              "}],staticClass:"text-body-1"}):(_vm.isActionPdpIncompleteArchived)?_c('IncompleteFieldCard'):_vm._e()],1),(_vm.finalSection.comments.length)?_c('v-row',{staticClass:"mx-0 px-0 flex-column"},[_c('v-col',{staticClass:"px-0 px-md-0 no-break-inside"},[(_vm.finalSection.comments.length)?_c('EventLog',{staticClass:"px-0 px-md-0 elevation-0",attrs:{"comments":_vm.finalSection.comments,"pdp":_vm.actionPdp}}):_vm._e()],1)],1):_vm._e()],1)],1):_vm._e(),(
                          !_vm.finalSection?.complete &&
                          (_vm.isCurrentSupervisor || _vm.userIsSchoolPrincipal)
                        )?_c('v-card',{staticClass:"pa-5",attrs:{"flat":""}},[_c('p',{staticClass:"ml-0 ml-md-5"},[_c('strong',[_vm._v("Annual review")]),_vm._v(" has not yet been completed ")])]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.reviewPanels),callback:function ($$v) {_vm.reviewPanels=$$v},expression:"reviewPanels"}})],1),(
                    _vm.isCurrentSupervisor &&
                    _vm.isArchivedDuringReview &&
                    _vm.isActionPdpIncompleteArchived
                  )?_c('IncompleteArchiveCard',{attrs:{"pdp":_vm.actionPdp}}):_vm._e()],1)],1)],1)],1)],2)],1):_vm._e()],1),(_vm.isCurrentSupervisor || _vm.isPlanSupervisor || _vm.isMidYearSupervisor)?_c('portal',{attrs:{"selector":".v-application--wrap"}},[_c('div',{staticClass:"notes-wrapper"},[_c('NotesBar',{attrs:{"archived":!_vm.actionPdp.active,"edit":_vm.actionPdp?.active && _vm.pdpUser?.active,"open":_vm.notesOpen},on:{"closeNotes":_vm.closeNotes,"openNotes":_vm.openNotes,"updateNotes":_vm.updateNotes},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <UserBanner
      :pdp="pdp"
      :user="loggedInUser"
      @editPdpUsers="showEditor = !showEditor"
    />
    <Ribbon
      :pdp="pdp"
      @print="getNotes"
      @save="archivePdp"
      @selected="pdpSelected"
    >
      <template #printContent>
        <NTSPrintContent
          v-if="pdp?.active"
          :pdp="pdp"
          :planSection="planSection"
        />
        <NTSArchivedPrintContent
          v-else
          :pdp="pdp"
          :planSection="planSection"
        />
      </template>
    </Ribbon>
    <Dialog
      :openDialog="
        pdp?.active && pdp?.onboardingComplete && !pdp?.owner_school_id
      "
      maxWidth="55em"
      persistent
    >
      <template #text>
        <UpdateSchoolModal
          class="px-12"
          @archive="archivePdp"
          @save="updateSchool"
        />
      </template>
    </Dialog>
    <Dialog
      :openDialog="pdp?.active && pdp?.year && !pdp?.yearSelected"
      maxWidth="55em"
      persistent
    >
      <template #text>
        <UpdateYearModal
          class="px-12"
          @save="updateYear"
        />
      </template>
    </Dialog>
    <Dialog
      :displayCloseBtn="true"
      :openDialog="showEditor"
      maxWidth="60%"
      @close="showEditor = !showEditor"
    >
      <template #text>
        <NTSPdpDetails
          :openDialog="showEditor"
          class="ma-0 px-7"
          @editPdpUsers="editPdpDetails"
        />
      </template>
    </Dialog>
    <!-- Expired PDP Dialog -->
    <Dialog
      :displayCloseBtn="false"
      :openDialog="showExpiredPdpDialog"
      maxWidth="60%"
      persistent
    >
      <template #text>
        <PdpUpdateDialog
          :openDialog="showExpiredPdpDialog"
          class="ma-0 px-7"
          @closeExpiredPdpDialog="closeExpiredPdpDialog"
        />
      </template>
    </Dialog>
    <!-- ... Expired PDP Dialog -->
    <NTSLandingPage
      v-if="showLandingPage"
      @createNtsPdp="createPdp"
    />
    <NTSStepper
      v-if="showStepper"
      :showStepper="showStepper"
      @reloadOwnedPdp="reloadOwnedPdp"
    />
    <NTSPlanEditor
      v-else-if="showPlanEditor"
      class="px-md-10"
      @planSigned="handleSectionSigned"
    />
    <NTSMyPdpTabs
      v-else-if="showTabs"
      :selectedTab="selectedTab"
      :showTabs="showTabs"
      @tabSigned="handleSectionSigned"
    />
    <portal selector=".v-application--wrap">
      <div class="notes-wrapper">
        <NotesBar
          v-model="notes"
          :archived="!formPdp?.active"
          :edit="formPdp?.active"
          :open="notesOpen"
          @closeNotes="closeNotes"
          @openNotes="openNotes"
          @updateNotes="updateNotes"
        />
      </div>
    </portal>
  </div>
</template>

<script>
import NTSLandingPage from '@/components/NTSLandingPage';
import { Dialog } from '@nswdoe/doe-ui-core';
import { mapActions, mapGetters } from 'vuex';
import NTSStepper from '@/components/NTSStepper';
import UserBanner from '@/components/UserBanner';
import NTSMyPdpTabs from '@/components/NTSMyPdpTabs';
import NTSPlanEditor from '@/components/NTSPlanEditor';
import Ribbon from '@/components/Ribbon';
import NTSPrintContent from '@/components/print/NTSPrintContent';
import NTSArchivedPrintContent from '@/components/print/NTSArchivedPrintContent';
import PdpUpdateDialog from '@/components/PdpUpdateDialog';
import NotesBar from '@/components/NotesBar';
import { COMMENT_TYPE, USER_TYPE } from '@/data/constants';
import { createComment } from '@/lib/pdp';
import UpdateSchoolModal from '@/components/UpdateSchoolModal';
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import { Portal } from '@linusborg/vue-simple-portal';
import NTSPdpDetails from '@/components/NTSPdpDetails.vue';
import UpdateYearModal from '@/components/UpdateYearModal.vue';

export default {
  name: 'NTSMyPdpView',
  data() {
    return {
      ADS_Colors,
      USER_TYPE,
      showEditor: false,
      notesOpen: false,
    };
  },
  components: {
    UpdateYearModal,
    UpdateSchoolModal,
    NTSPdpDetails,
    NotesBar,
    NTSPlanEditor,
    NTSLandingPage,
    NTSStepper,
    Dialog,
    UserBanner,
    NTSMyPdpTabs,
    NTSPrintContent,
    NTSArchivedPrintContent,
    Ribbon,
    PdpUpdateDialog,
    Portal,
  },
  props: {
    selectedTab: {
      type: String,
      default: '',
    },
    showExpiredPdpDialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'loggedInUser',
      'formPdp',
      'pdp',
      'isPdpArchived',
      'ownedPdp',
      'isLoading',
    ]),
    ...mapGetters('formValidation', ['allGoalsValid', 'validationGoals']),
    ...mapGetters('pdp', {
      planSection: 'pdpPlanSection',
      personalNotes: 'personalNotes',
    }),
    notes: {
      get() {
        return this.personalNotes?.text;
      },
      set(value) {
        this.personalNotes.text = value;
      },
    },
    showStepper() {
      return (
        !!this.formPdp &&
        !this.isPdpArchived &&
        !this.formPdp?.onboardingComplete
      );
    },
    showPlanEditor() {
      return (
        this.formPdp?.onboardingComplete &&
        !this.planSection?.complete &&
        !this.isPdpArchived
      );
    },
    showTabs() {
      return this.planSection?.complete || this.isPdpArchived;
    },
    showLandingPage() {
      return !this.pdp && !this.isLoading;
    },
  },
  methods: {
    ...mapActions('pdp', [
      'getUserPdp',
      'savePdp',
      'getPersonalNotes',
      'updatePersonalNotes',
      'signPdp',
    ]),
    pdpSelected(id) {
      this.$emit('selected', id);
    },
    async editPdpDetails() {
      const yearSaved =
        this.formPdp.year && this.formPdp.year !== this.pdp.year;
      await this.savePdp(this.formPdp);
      if (yearSaved) {
        this.reloadOwnedPdp();
      }
      this.showEditor = !this.showEditor;
    },
    reloadOwnedPdp() {
      this.$emit('reloadOwnedPdp');
    },
    async getNotes() {
      if (
        !this.personalNotes ||
        (this.personalNotes && this.personalNotes.pdp_id !== this.formPdp.id)
      ) {
        await this.getPersonalNotes(this.formPdp.id);
      }
    },
    async openNotes() {
      await this.getNotes();
      this.notesOpen = true;
      this.$emit('notesOpen', this.notesOpen);
    },
    async updateNotes() {
      await this.updatePersonalNotes(this.personalNotes);
      this.notesOpen = false;
      this.$emit('notesOpen', this.notesOpen);
    },
    async closeNotes() {
      this.notesOpen = false;
      this.$emit('notesOpen', this.notesOpen);
    },
    async updateSchool() {
      await this.savePdp(this.formPdp);
    },
    async updateYear() {
      const yearSaved =
        this.formPdp.year && this.formPdp.year !== this.pdp.year;
      await this.savePdp(this.formPdp);
      if (yearSaved) {
        this.reloadOwnedPdp();
      }
    },
    async archivePdp() {
      this.formPdp.active = false;
      await this.savePdp(this.formPdp);
      this.$emit('archive');
    },
    async handleSectionSigned(sectionToBeUpdated, comment) {
      const signData = createComment(
        this.formPdp.id,
        sectionToBeUpdated.id,
        COMMENT_TYPE.section_sign_user,
        comment
      );
      await this.signPdp(signData);
      this.gtmTrackSign(sectionToBeUpdated.sectionType);
    },
    closeExpiredPdpDialog() {
      this.$emit('handleRefresh');
    },
    gtmTrackSign(sectionType) {
      this.$gtm.trackEvent({
        event: null,
        category: 'MyPdp',
        action: 'Supervisee Sign Pdp',
        label: 'OrientationBanner',
        value: `${sectionType}`,
      });
    },
    async createPdp() {
      this.$emit('createNtsPdp');
    },
  },
  emits: ['handleRefresh', 'createNtsPdp'],
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"no-break-inside"},[_c('v-row',{staticClass:"px-5 px-md-0",attrs:{"no-gutters":""}},[_c('v-col',[_c('p',[_c('b',{staticClass:"title"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v(" mdi-message-reply-text-outline ")]),_vm._v(" "+_vm._s(_vm.label)+" ")],1)])])],1),_vm._l((_vm.formattedComments),function(comment,index){return _c('v-card',{key:`comment-${index}-${comment?.time}`,staticClass:"mx-0 mx-md-0 pa-4 mb-5"},[(comment.text)?_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:sanitize",value:(comment.text),expression:"comment.text",arg:"sanitize"}],staticClass:"pa-0 ma-0"}):_vm._e(),_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:sanitize",value:(
        `<strong>${comment?.role} ${comment?.name} </strong>${
          comment?.action
        } this ${
          comment?.action === 'archived' ? 'PDP' : 'section'
        } on <strong>${comment?.time}</strong>`
      ),expression:"\n        `<strong>${comment?.role} ${comment?.name} </strong>${\n          comment?.action\n        } this ${\n          comment?.action === 'archived' ? 'PDP' : 'section'\n        } on <strong>${comment?.time}</strong>`\n      ",arg:"sanitize"}],staticClass:"pa-0 ma-0"})])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
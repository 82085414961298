<template>
  <v-app
    :class="{ 'hide-on-print': isDialogOpen }"
    data-test="app"
  >
    <div>
      <NavigationDrawer
        v-model="navModel"
        :items="navDrawerItems"
        data-test="nav-items"
      />
      <AppBar
        :enableHomeLink="false"
        :showNavIcon="true"
        appName="my-nsw-gov"
        :color="ADS_Colors.PRIMARY_BLUE"
        :title="appBarTitle"
      >
        <template #appIcon>
          <AppIcon
            :transparent="true"
            appName="my-nsw-gov"
            class="mt-2"
            size="50px"
          />
        </template>
        <template #profile>
          <Profile
            :givenName="formatName(loggedInUser) ?? 'No name'"
            :jobTitle="userPosition"
            :logoutHandler="logoutHandler"
            :surname="' '"
            :logout="false"
            :changePassword="false"
            :portalSettings="false"
            :updateProfile="false"
            :updateSecretQuestion="false"
          />
        </template>
      </AppBar>
      <v-main>
        <v-fade-transition mode="out-in">
          <router-view :inFocus="inFocus" />
        </v-fade-transition>
      </v-main>
      <!-- App-wide component -->
      <BlockUI
        v-if="isLoading"
        data-test="loading-widget"
      />
    </div>
  </v-app>
</template>

<script>
import { AppBar, AppIcon, BlockUI, Profile } from '@nswdoe/doe-ui-core/index';
import { mapState } from 'vuex';
import NavigationDrawer from '@/components/ADS-customised/NavigationDrawer.vue';
import { generateAppBarTitle } from './App.lib';
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import { formatName, getPositions } from '@/utils';
import { PDP_ROUTES, PDP_TYPE, QPLUS_ROUTES } from '@/data/constants';

export default {
  name: 'App',
  title: 'Performance & Development Plan',
  components: {
    BlockUI,
    AppBar,
    NavigationDrawer,
    Profile,
    AppIcon,
  },
  data() {
    return {
      ADS_Colors,
      navDrawerItems: undefined,
      navModel: undefined,
      appIsDown: true,
      inFocus: true,
    };
  },
  computed: {
    ...mapState({
      superviseePdp(state) {
        return state.pdp.superviseePdp;
      },
      loggedInUser(state) {
        return state.loggedInUser;
      },
      isLoading(state) {
        return state.isLoading;
      },
    }),
    ...mapState('dialog', {
      isDialogOpen: (state) => state.isOpen,
    }),
    userProfile() {
      return this.$OAuth ? this.$OAuth.data.profile : undefined;
    },
    userPosition() {
      return getPositions(this.loggedInUser?.staffEmployment);
    },
    appBarTitle() {
      return generateAppBarTitle(this.$route.name, this.isMobile);
    },
    isSupervisorOfNTS() {
      return this.loggedInUser?.supervised_pdp_types.includes(PDP_TYPE.nts);
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    loggedInUser: {
      async handler() {
        if (this.loggedInUser) {
          this.updateSidebar();
        }
      },
      immediate: true,
    },
  },
  methods: {
    formatName,
    logoutHandler() {
      this.$store.state.loggedInUser = null;
      this.$store.state.user.userData = null;
      if (localStorage) {
        localStorage.removeItem('auth_token');
      }
      if (this.$OAuth) this.$OAuth.logout();
    },
    updateSidebar() {
      this.navDrawerItems = [
        ...(this.loggedInUser?.isTeachingStaff
          ? [
              {
                title: 'My Status',
                icon: 'home',
                iconOutlined: true,
                route: QPLUS_ROUTES.status.base.path,
              },
              {
                title: 'My Details',
                icon: 'person_pin',
                iconOutlined: true,
                route: QPLUS_ROUTES.fullDetails.base.path,
              },
              {
                title: 'Workspace',
                icon: 'playlist_add_check',
                route: QPLUS_ROUTES.tasks.base.path,
              },
              {
                title: 'Resources',
                icon: 'apps',
                route: QPLUS_ROUTES.resources.base.path,
              },
              {
                title: 'My Documents & Links',
                icon: 'file_copy',
                iconOutlined: true,
                route: QPLUS_ROUTES.documentsAndLinks.base.path,
              },
            ]
          : []),
        ...[
          {
            title: 'PDP',
            icon: 'person_pin',
            iconOutlined: true,
            items: [
              ...((this.loggedInUser?.isTeachingStaff ||
                this.loggedInUser?.isNonTeachingStaff) &&
              !this.loggedInUser?.isDel
                ? [
                    {
                      title: 'My PDP',
                      route: PDP_ROUTES.myPdp.base.path,
                    },
                    {
                      title: 'PDP Guide',
                      route: PDP_ROUTES.pdpGuide.base.path,
                    },
                  ]
                : []),
              ...(this.loggedInUser?.isSupervisor ||
              this.loggedInUser.isPrincipal
                ? [
                    {
                      title: 'Management',
                      route: PDP_ROUTES.management.base.path,
                    },
                  ]
                : []),
              ...(this.loggedInUser?.isNonTeachingStaff ||
              this.isSupervisorOfNTS
                ? [
                    {
                      title: 'Useful links',
                      route: PDP_ROUTES.usefulLinks.base.path,
                    },
                  ]
                : []),
            ],
          },
        ],
        ...(this.loggedInUser?.isTeachingStaff
          ? [
              {
                title: 'Stay connected',
                icon: 'mdi-storefront-outline',
                iconOutlined: true,
                route: QPLUS_ROUTES.stayConnected.base.path,
              },
            ]
          : []),
        ...(this.loggedInUser?.isTeachingStaff
          ? [
              {
                title: 'Support & Feedback',
                icon: 'mdi-help-circle-outline',
                iconOutlined: true,
                route: QPLUS_ROUTES.supportFeedback.base.path,
              },
            ]
          : []),
        ...(this.loggedInUser?.isNonTeachingStaff
          ? [
              {
                title: 'Support',
                icon: 'mdi-help-circle-outline',
                iconOutlined: true,
                route: PDP_ROUTES.support.base.path,
              },
            ]
          : []),
      ];
    },
  },
  mounted() {
    this.inFocus = !document.hidden;
    window.addEventListener('focus', () => {
      // console.log('Add App focus listener', this.$route.name);
      this.inFocus = true;
    });
    window.addEventListener('blur', () => {
      // console.log('Add App blur listener', this.$route.name);
      this.inFocus = false;
    });
  },
  beforeDestroy() {
    window.removeEventListener('focus', () => {
      // console.log('Remove App focus listener');
      this.inFocus = false;
    });
    window.removeEventListener('blur', () => {
      // console.log('Remove App blur listener');
      this.inFocus = false;
    });
  },
};
</script>

<style lang="scss">
// use pre-defined ADS scss classes for the whole project, note here the styles are non-scoped in App.vue
@import './scss/ads';
@import url('https://fonts.googleapis.com/css2?family=Public+Sans&display=swap');

.v-application .d-flex.invisible {
  display: none !important;
}

.v-toolbar__content {
  box-shadow: none !important;
}

.visible {
  display: block;
}

.container {
  a {
    color: $ads-primary-blue !important;
  }
}

.notes-wrapper {
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  margin-bottom: 0;
  box-shadow: 0 0 0 100vmax white;
  clip-path: inset(0 -100vmax);
  margin-top: auto;
}

.ts-print-content {
  .v-btn:not(header.toolbar .v-btn) {
    display: none;
  }
}

.nts-print-content {
  .quillWrapper .ql-snow.ql-toolbar {
    display: none;
  }
  .v-btn:not(header.toolbar .v-btn),
  a {
    pointer-events: none;
  }
}

.no-break-inside {
  break-inside: avoid;
}

.v-expansion-panel-header {
  word-break: break-word;
}
// prevent text rendered as <pre> by rich text editor from overflowing
pre {
  white-space: normal;
}

@media print {
  @page {
    size: A4;
    margin-top: 0;
  }
  .hide-on-print .v-application--wrap {
    display: none;
  }
  .v-dialog__content.v-dialog__content--active {
    position: inherit;
  }
  .v-dialog--fullscreen {
    position: absolute;
    overflow: visible;
  }
  .ts-print-content {
    header.toolbar {
      display: none;
    }
    .contentWrapper {
      padding-top: 0;
    }
    .v-btn {
      display: none;
    }
    .v-expansion-panel-content .goal-text:not(.v-card__text > .goal-text) {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }
  }
  .nts-print-content {
    header.toolbar {
      display: none;
    }
    .contentWrapper {
      padding-top: 0;
    }
    .v-expansion-panel-content .goal-text:not(.v-card__text > .goal-text) {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }
  }
}
</style>

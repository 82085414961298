<template>
  <v-row
    align="center"
    class="mb-2"
  >
    <v-col
      cols="7"
      class="px-0"
      style="background-color: #2d4586"
    >
      <AdsChart
        :showTitle="false"
        type="donut"
        width="100%"
        height="200px"
        :chartOptions="chartOptions"
        :adsForcedOptions="false"
      />
    </v-col>
    <v-col cols="5">
      <template v-for="(label, index) in labels">
        <v-row
          :key="label"
          class="pa-0 d-flex row align-center"
          style="color: white"
        >
          <v-icon
            size="40"
            :color="chartOptions.colors[index]"
          >
            mdi-checkbox-blank
          </v-icon>
          <p class="text-body-1 ma-0">
            {{ label }}:
            <span class="mb-0">{{ series[index] }}</span>
          </p>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import AdsChart from '@/components/ADS-customised/AdsChart.vue';
import { CHART_TYPE, PHASE } from '@/data/constants';

export default {
  name: 'PdpCompletion',
  props: {
    areaLabel: {
      type: String,
      required: true,
    },
    stats: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  components: {
    AdsChart,
  },
  mounted() {
    this.localStats = this.stats;
  },
  data() {
    return {
      localStats: null,
      baseChartOptions: {
        chart: {
          background: '#2d4487',
        },
        stroke: {
          width: 0,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        states: {
          hover: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          active: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              size: '60%',
              labels: {
                show: true,
                name: {
                  show: true,
                  color: ADS_Colors['Dark_80'],
                  formatter: () => {
                    return this.areaLabel;
                  },
                },
                value: {
                  show: true,
                  color: ADS_Colors['Dark_80'],
                  formatter: () => {
                    return '';
                  },
                },
                total: {
                  show: true,
                  label: this.areaLabel,
                  color: ADS_Colors['Dark_80'],
                  formatter: () => {
                    return '';
                  },
                },
              },
            },
          },
        },
        legend: {
          show: false,
        },
      },
    };
  },
  watch: {
    stats() {
      this.localStats = this.stats;
    },
  },
  computed: {
    labels() {
      return this.localStats
        ? [
            PHASE.planInProgress.stage,
            PHASE.implementInProgress.stage,
            PHASE.reviewInProgress.stage,
            ...(this.type === CHART_TYPE.school ? [PHASE.inactive.stage] : []),
            PHASE.complete.stage,
          ]
        : [];
    },
    series() {
      const values = this.localStats
        ? [
            this.stats.plan,
            this.stats.implement,
            this.stats.review,
            ...(this.type === CHART_TYPE.school ? [this.stats.inactive] : []),

            this.stats.complete,
          ]
        : this.labels.map(() => 0);
      const sum = values.reduce((acc, curr) => acc + curr, 0);
      if (sum === 0) {
        values.push(1);
      }
      return values ?? [];
    },
    chartOptions() {
      let chartColours;
      switch (this.type) {
        case CHART_TYPE.school:
          chartColours = [
            ADS_Colors['White'],
            ADS_Colors['TEAL_1_DARK'],
            ADS_Colors['Error_Red_Dark'],
            ADS_Colors['GREY_03'],
            ADS_Colors['Success_Green'],
          ];
          break;
        case CHART_TYPE.team:
          chartColours = [
            ADS_Colors['White'],
            ADS_Colors['TEAL_1_DARK'],
            ADS_Colors['Error_Red_Dark'],
            ADS_Colors['Success_Green'],
          ];
          break;
        default:
          break;
      }
      return this.stats || null
        ? {
            ...this.baseChartOptions,
            labels: this.labels,
            series: this.series,
            colors: chartColours,
          }
        : {};
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',[_c('TSUserBannerPrint',{staticClass:"ts-user-banner--print",attrs:{"pdp":_vm.actionPdp,"user":_vm.actionPdp?.owner,"backgroundColour":"white"}}),_c('v-container',{staticClass:"px-0 pb-5"},[_c('h1',{staticClass:"display-1 black--text"},[_vm._v("Plan")])]),(_vm.canViewSection(_vm.TAB_TYPES.plan))?_c('div',[_c('TSPlanReadOnlyPrint',{attrs:{"pdp":_vm.actionPdp,"section":_vm.planSection}})],1):_c('v-container',{staticClass:"px-0"},[_c('NoPermissionCard')],1),_c('v-container',{staticClass:"px-0 py-5 page-break-before"},[_c('h1',{staticClass:"display-1 black--text"},[_vm._v("Goals and evidence")])]),(_vm.canViewSection(_vm.TAB_TYPES.goalsAndEvidence))?_c('v-container',{staticClass:"pa-0"},[(_vm.midYearSection?.goals?.length)?_c('v-card',{staticClass:"my-5"},[_c('v-container',{staticClass:"px-0 px-md-10 py-5",attrs:{"fluid":""}},[_c('TSPdpGoalsPrint',{attrs:{"editable":false,"goals":_vm.midYearSection?.goals,"owner":_vm.actionPdp?.owner,"section":_vm.midYearSection,"showEditToggle":false,"startEdit":false,"userIsPrincipal":_vm.userIsPrincipal,"expandAll":"","isSupervisor":""}})],1)],1):_c('IncompleteFieldCard')],1):_c('v-container',{staticClass:"px-0"},[_c('NoPermissionCard')],1),_c('v-container',{staticClass:"px-0 page-break-before"},[_c('v-container',{staticClass:"pa-0 pt-5"},[_c('h1',{staticClass:"display-1 black--text"},[_vm._v("Observations")])]),(
        _vm.canViewSection(_vm.TAB_TYPES.observations) &&
        _vm.isIncompleteArchivedPdp &&
        _vm.actionPdp?.observations?.length === 0
      )?_c('IncompleteFieldCard',{staticClass:"mt-5"}):(_vm.canViewSection(_vm.TAB_TYPES.observations))?_c('v-card',{staticClass:"my-5 py-5 page-break"},[_c('TSPdpObservationsPrint',{attrs:{"editMode":false,"pdp":_vm.actionPdp}})],1):_c('NoPermissionCard',{staticClass:"px-0"})],1),_c('v-container',{staticClass:"pa-0 page-break-before"},[_c('v-container',{staticClass:"pa-0 pt-5"},[_c('h1',{staticClass:"display-1 black--text"},[_vm._v("Reviews")])]),(_vm.canViewSection(_vm.TAB_TYPES.reviews))?_c('div',[_c('Wrapper',{staticClass:"mt-8 pa-0",attrs:{"isMobile":_vm.isMobile,"cardClass":"py-5 px-10"}},[_c('AdsExpansionPanel',{attrs:{"items":[
            { id: 1, title: 'Mid-Year Self-Assessment' },
            { id: 2, title: 'Annual review' },
          ],"multiple":""},scopedSlots:_vm._u([{key:`content1`,fn:function(){return [(_vm.isIncompleteArchivedPdp && !_vm.actionPdp?.midYearText)?_c('div',{staticClass:"pa-5"},[_c('IncompleteFieldCard')],1):_c('div',[_c('v-container',{staticClass:"pa-0 pa-md-5"},[_c('v-card',{staticClass:"pa-5 elevation-0"},[_c('v-card-title',{staticClass:"px-0 px-md-4 text-h6"},[_vm._v(" Review of progress ")]),_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
                      _vm.actionPdp?.midYearText ? _vm.actionPdp?.midYearText : ''
                    ),expression:"\n                      actionPdp?.midYearText ? actionPdp?.midYearText : ''\n                    "}],staticClass:"px-0 px-md-4 text-body-1"}),_c('v-row',{staticClass:"mx-0 px-0 flex-column mt-5"},[_c('v-col',{staticClass:"px-0 px-md-0 no-break-inside"},[_c('EventLog',{staticClass:"px-0 px-md-0 elevation-0",attrs:{"comments":_vm.midYearSection.comments,"pdp":_vm.actionPdp,"section":_vm.midYearSection}})],1)],1)],1)],1),(!_vm.midYearSection?.complete)?_c('v-card',{staticClass:"pa-5",attrs:{"flat":""}},[_c('p',{staticClass:"ml-0 ml-md-5"},[_c('strong',[_vm._v("Mid Year Self-Assessment")]),_vm._v(" has not yet been completed ")])]):_vm._e()],1)]},proxy:true},{key:`content2`,fn:function(){return [(_vm.isCurrentSupervisor || _vm.userIsSchoolPrincipal)?_c('div',[(_vm.isIncompleteArchivedPdp && !_vm.actionPdp?.annualText)?_c('div',{staticClass:"pa-5"},[_c('IncompleteFieldCard')],1):_c('div',[_c('v-container',{staticClass:"pa-0 pa-md-5"},[_c('v-card',{staticClass:"pa-5 elevation-0"},[_c('v-card-title',{staticClass:"px-0 px-md-4 text-h6"},[_vm._v(" Annual review ")]),_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
                        _vm.actionPdp?.annualText ? _vm.actionPdp.annualText : ''
                      ),expression:"\n                        actionPdp?.annualText ? actionPdp.annualText : ''\n                      "}],staticClass:"px-0 px-md-4 text-body-1"}),_c('v-row',{staticClass:"mx-0 px-0 flex-column mt-5"},[_c('v-col',{staticClass:"px-0 px-md-0 no-break-inside"},[_c('EventLog',{staticClass:"px-0 px-md-0 elevation-0",attrs:{"comments":_vm.finalSection.comments,"pdp":_vm.actionPdp,"section":_vm.finalSection}})],1)],1)],1)],1),(!_vm.finalSection?.complete)?_c('v-card',{staticClass:"pa-5",attrs:{"flat":""}},[_c('p',{staticClass:"ml-0 ml-md-5"},[_c('strong',[_vm._v("Annual review")]),_vm._v(" has not yet been completed ")])]):_vm._e()],1)]):_c('v-container',{staticClass:"pa-0 pa-md-5"},[_c('NoPermissionCard')],1)]},proxy:true}],null,true),model:{value:(_vm.reviewPanels),callback:function ($$v) {_vm.reviewPanels=$$v},expression:"reviewPanels"}})],1)],1):_c('NoPermissionCard',{staticClass:"px-0"})],1),_c('v-container',{staticClass:"pa-0 my-5 page-break-before"},[(_vm.isIncompleteArchivedPdp)?_c('IncompleteArchiveCard',{attrs:{"pdp":_vm.actionPdp}}):_vm._e()],1),_c('PersonalNotesCard',{staticClass:"mb-10 pa-0 page-break-before",attrs:{"comments":_vm.supervisorNotes}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-row
      v-if="isSupervisor"
      id="goals"
      class="mb-2"
      justify="space-between"
    >
      <v-col>
        <h4 class="display-1 mx-5 mx-md-0">Work goals</h4>
      </v-col>
    </v-row>
    <v-row
      v-if="!isSupervisor && allowEdit"
      no-gutters
    >
      <v-col
        v-if="type === GOAL_TYPE.work"
        class="ma-0 pa-0"
      >
        <div class="mb-10 no-break-inside">
          <h1 class="mb-8 display-1">Work Goals</h1>
          <v-row no-gutters>
            <v-col class="pr-3 body-1 ads-grey-01">
              <p>
                The PDP is a dynamic plan, open to adjustment and refinement as
                required in consultation with supervisors.
              </p>
              <p>
                This may include making goal adjustments or adding new
                strategies or support structures. At least three and no more
                than five professional goals are required.
              </p>
              <p>
                You may draft your goals before or after meeting with your
                supervisor.
              </p>
              <p>
                Select "Skip this step" to meet with your supervisor before you
                draft your goals.
              </p>
            </v-col>
            <v-col
              v-if="!readOnly"
              class="pl-3"
            >
              <NTSGuidesCard />
            </v-col>
          </v-row>
        </div>
        <div v-if="!hasNotifiedPdPSupervisor">
          <div class="no-break-inside">
            <h3 class="my-3">Work goals overview</h3>
            <p class="body-1 ads-grey-01">
              These should be based on the responsibilities identified for your
              role and on the school plan/priorities. Consider your individual
              and team priorities. Include the date goals will be achieved by.
              Each goal is recommended to be less than 100 words. Identify 3-5
              work goals.
            </p>
          </div>
          <Alert
            alertClass="info-background-banner subtitle-1"
            class="flex-grow-1 mx-md-1 mt-4 mb-5 no-break-inside"
            elevation="0"
            inPage
            showAlert
            text="Your privacy is important"
            type="info"
          >
            <template #optional>
              <p class="body-1 ads-grey-01 mb-0">
                Only your selected PDP supervisor, additional supervisor and
                principal can see your PDP. Nobody else can search or access
                your PDP.
              </p>
              <p class="body-1 ads-grey-01 mb-0">
                A
                <a
                  href="https://education.nsw.gov.au/content/dam/main-education/inside-the-department/human-resources/media/documents/performance/Digtial_PDP_SDD_pack-data_security_fact_sheet.pdf"
                  target="_blank"
                >
                  fact sheet
                </a>
                is available for more information about the security, privacy
                and confidentiality of your data in the Digital PDP.
              </p>
            </template>
          </Alert>
        </div>
      </v-col>
      <v-col
        v-if="type === GOAL_TYPE.career"
        class="body-1 ads-grey-01"
      >
        <p class="ma-0 pa-0">
          Identify any career goal/s. This can be short, medium and/or long term
          but must be realistic and achievable.
        </p>
        <p>
          Include the date goals will be achieved by. Each goal is recommended
          to be less than 100 words.
        </p>
      </v-col>
    </v-row>
    <v-row
      class="mr-2"
      no-gutters
    >
      <v-col>
        <AdsExpansionPanel
          v-model="goalPanelIndex"
          :accordion="false"
          :items="goals"
          class="ml-1"
          data-test="goals-panel"
          multiple
        >
          <template
            v-for="(goal, goalIndex) of goals"
            #[`header${goal.id}`]
          >
            <p
              :id="`expansion-header-work-${goalIndex}`"
              :key="`expansion-header-work-${goalIndex}`"
              v-dompurify-html:plaintext="
                goal.title
                  ? `Goal ${goalIndex + 1}: ${goal.title}`
                  : `Goal ${goalIndex + 1}`
              "
              class="ma-0 pa-0 title ads-grey-01"
            />
          </template>
          <template
            v-for="(goal, goalIndex) of goals"
            #[`content${goal.id}`]
          >
            <NTSGoal
              :id="`pdpGoal-${type}-${goal.id}`"
              :key="`pdpGoal-${type}-${goal.id}`"
              :editMode="allowEdit"
              :goal="goal"
              :goalIndex="goalIndex"
              :isSupervisor="isSupervisor"
              :owner="owner"
              :readOnly="readOnly"
              :section="section"
              :type="type"
              :validation="type === GOAL_TYPE.work ? validation : false"
              @autosave="autosave"
              @deleted="updateValidation"
              @saved="updateValidation"
              @stopAutosave="stopAutosave"
            />
          </template>
        </AdsExpansionPanel>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col class="ma-md-0 ma-0 pa-0">
        <AdsButton
          v-if="goals && goals.length < NTS_MAX_GOALS && allowEdit"
          buttonText="Add another goal"
          class="mt-8 pl-2 nts-add-goal-button"
          icon="add_circle_outline"
          secondary
          @click.stop="handleAddNewGoal"
        />
        <p
          v-if="goals && allowEdit"
          class="mt-6 mb-4 body-1"
        >
          <v-icon class="mr-1">info_outline</v-icon>
          You added
          {{ goalsInText }}
          goals (maximum five).
        </p>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col class="ma-md-0 ma-0 pa-0 pr-16 no-break-inside">
        <p class="goal-section-title">
          <v-icon
            :color="ADS_Colors.Blue_2"
            class="mr-2"
          >
            mdi-school-outline
          </v-icon>
          Learning and development
        </p>
        <p class="body-1 ads-dark">
          Is any knowledge or skill development required, or engagement in
          work-related experiences needed, for you to be able to achieve your
          goals? How will this be undertaken? For example, learning by doing,
          job shadowing, mentoring and formal courses.
        </p>
        <p>
          You can find a link to professional learning opportunities in the
          <router-link
            target="_blank"
            to="/useful-links"
          >
            Useful Links
          </router-link>
          tab.
        </p>
        <p v-if="type === GOAL_TYPE.work">
          Please select between one and four Professional learning themes:
        </p>
        <v-combobox
          v-if="type === GOAL_TYPE.work"
          v-model="selectedLearningThemes"
          :disabled="readOnly"
          :error="validation && !isSelectedLearningThemesValid"
          :items="learningThemes"
          :rules="validation ? [rules.learningThemeRequired] : []"
          chips
          clearable
          deletable-chips
          item-text="name"
          label="Professional learning themes"
          multiple
          outlined
          placeholder="Select themes..."
          return-object
          validate-on-blur
        >
          <template #item="{ item }">
            <v-list-item
              :class="`${isSelected(item) ? 'selected' : ''}`"
              @click.stop="handleWorkLearningThemeSelect(item)"
            >
              <v-list-item-action>
                <v-checkbox
                  :disabled="
                    selectedLearningThemes.length === 4 && !isSelected(item)
                  "
                  :input-value="isSelected(item)"
                />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.description }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
        <RichTextField
          v-model.trim="learningDetails"
          :disabled="readOnly"
          :rules="
            validation
              ? [rules.maxStringLengthRule(LEARNING_DETAILS_MAX_LENGTH)]
              : []
          "
          label="Add details"
        />
        <SaveTimestamp
          v-if="isSaving || isSaved || !this.isLearningThemeSaveDisabled"
          :updated="pdp.updated_at"
          :pending="!this.isLearningThemeSaveDisabled"
          class="d-flex flex-row align-center mb-4"
        />
        <div class="d-flex flex-row">
          <AdsButton
            v-if="validation"
            :disabled="isLearningThemeSaveDisabled"
            buttonText="Save"
            class="save-learning-and-development-button"
            depressed
            icon="mdi-tray-arrow-down"
            @click.stop="handleUpdateLearningDetails"
          />
          <AdsButton
            v-else
            :disabled="proceedDisabled"
            buttonText="Proceed"
            depressed
            icon="mdi-arrow-right"
            @click.stop="proceed"
          />
        </div>
      </v-col>
      <v-col
        v-if="!allowEdit"
        class="mt-5"
      >
        <strong class="text--color-black text--size-18">
          <v-icon
            :color="ADS_Colors.Blue_2"
            class="mr-2"
          >
            mdi-star-outline
          </v-icon>
          Strategies to achieve goals
        </strong>
        <v-card
          v-if="section?.workLearningAndDevelopment"
          class="mx-10 my-5"
        >
          <v-card-text class="px-10 py-5 ma-0">
            <p
              v-dompurify-html:plaintext="section?.workLearningAndDevelopment"
              class="goal-text"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { AdsButton, AdsExpansionPanel, Alert } from '@nswdoe/doe-ui-core';
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import NTSGuidesCard from '@/components/NTSGuidesCard';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import NTSGoal from '@/components/NTSGoal.vue';
import validators from '@/lib/validators';
import {
  GOAL_TYPE,
  LEARNING_DETAILS_MAX_LENGTH,
  MAX_WORK_LEARNING_THEMES,
  NTS_MAX_GOALS,
  SECTION_TYPE,
} from '@/data/constants';
import {
  findSectionByType,
  getLearningThemes,
  handleAddNewGoal,
  setLearningThemes,
} from '@/lib/pdp';
import { areArraysEqual, areStringsEqual, numberToWord } from '@/utils';
import RichTextField from '@/components/RichTextField.vue';
import { isLearningThemeAssociationsValid } from '@/lib/dataValidation';
import SaveTimestamp from '@/components/SaveTimestamp.vue';

export default {
  name: 'NTSGoals',
  components: {
    SaveTimestamp,
    RichTextField,
    NTSGoal,
    NTSGuidesCard,
    AdsButton,
    AdsExpansionPanel,
    Alert,
  },
  props: {
    section: {
      type: Object,
      default: null,
    },
    isSupervisor: {
      // delete?
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    owner: {
      type: Object,
      default: () => ({}),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: () => '',
      required: true,
    },
    validation: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      ADS_Colors,
      GOAL_TYPE,
      editMode: false,
      goalPanelIndex: [],
      rules: {
        learningThemeRequired: validators.learningThemeRequired,
        maxStringLengthRule: validators.maxStringLengthRule,
      },
      LEARNING_DETAILS_MAX_LENGTH,
      NTS_MAX_GOALS,
    };
  },
  watch: {
    allowEdit: {
      handler(newVal) {
        this.editMode = newVal;
        this.setGoalPanelIndex();
      },
      immediate: true,
    },
    goals: {
      handler(newValue) {
        newValue.forEach((goal) => {
          if (goal.id) {
            this.addGoal({
              id: `pdpGoal-${goal.id}`,
              isValid: !!(
                goal.description && goal.description.trim().length >= 100
              ),
            });
          }
        });
        if (this.goals.length !== this.goalPanelIndex.length) {
          this.setGoalPanelIndex();
        }
      },
      immediate: true,
    },
    allGoalsValid: {
      handler() {
        this.$emit('updateStepValidity', {
          step: 2,
          value: this.allGoalsValid,
        });
      },
    },
    proceedDisabled: {
      handler(value) {
        this.$emit('toggle-skip-button', value);
      },
      immediate: true,
    },
    formSectionLearningThemes: {
      handler() {
        if (this.hasLearningThemesChanged) {
          this.autosave();
        } else if (this.saveTimeoutId) {
          this.stopAutosave();
        }
      },
      immediate: true,
    },
    learningDetails: {
      handler() {
        if (this.hasLearningDetailsChanged) {
          this.autosave();
        } else if (this.saveTimeoutId) {
          this.stopAutosave();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('lookups', ['learningThemes']),
    ...mapGetters(['pdp', 'formPdp']),
    ...mapGetters('formValidation', ['allGoalsValid']),
    ...mapGetters('pdp', ['saveTimeoutId', 'isSaving', 'isSaved']),
    isLearningThemeSaveDisabled() {
      return !this.hasLearningDetailsChanged && !this.hasLearningThemesChanged;
    },
    originalSectionLearningThemes() {
      const originalSection = findSectionByType(
        this.pdp,
        this.currentSectionType
      );
      return originalSection.section_learning_theme_associations.map(
        (item) => item.id
      );
    },
    formSectionLearningThemes() {
      const formSection = findSectionByType(
        this.formPdp,
        this.currentSectionType
      );
      return formSection.section_learning_theme_associations
        .filter((item) => item.id || (!item.id && !item.markForDelete))
        .map((item) =>
          item.id && item.markForDelete
            ? 'deleted item'
            : (item.id ?? 'new item')
        );
    },
    hasLearningThemesChanged() {
      return !areArraysEqual(
        this.originalSectionLearningThemes,
        this.formSectionLearningThemes
      );
    },
    hasLearningDetailsChanged() {
      const originalSection = findSectionByType(
        this.pdp,
        this.currentSectionType
      );
      let savedLearningDetails;
      if (this.type === GOAL_TYPE.work) {
        savedLearningDetails = originalSection.workLearningText;
      } else {
        savedLearningDetails = originalSection.careerLearningText;
      }
      return !areStringsEqual(savedLearningDetails, this.learningDetails);
    },
    currentSectionType() {
      return this.section.sectionType;
    },
    goals() {
      let goals = [];
      if (this.section?.goals) {
        goals = this.section?.goals.filter((goal) => {
          return goal.type === this.type;
        });
      }
      return goals;
    },
    hasNotifiedPdPSupervisor() {
      return !!(
        this.section?.notifySupervisorTime || this.section?.notifySupervisor
      );
    },
    allowEdit() {
      if (this.editable === false) {
        return false;
      }
      if (this.section?.sectionType === SECTION_TYPE.plan) {
        return !this.section.complete;
      } else {
        const finalSection = findSectionByType(
          this.formPdp,
          SECTION_TYPE.final
        );
        return !finalSection?.complete;
      }
    },
    learningDetails: {
      get() {
        if (this.type === GOAL_TYPE.work) {
          return this.section.workLearningText;
        } else {
          return this.section.careerLearningText;
        }
      },
      set(value) {
        if (this.type === GOAL_TYPE.work) {
          this.SET_WORK_LEARNING_TEXT({
            id: this.section.id,
            workLearningText: value,
          });
        } else {
          this.SET_CAREER_LEARNING_TEXT({
            id: this.section.id,
            careerLearningText: value,
          });
        }
      },
    },
    selectedLearningThemes: {
      get() {
        if (this.section) {
          return getLearningThemes(
            this.section.section_learning_theme_associations,
            this.learningThemes
          );
        } else {
          return [];
        }
      },
      set(selectedValues) {
        const themesToSave = setLearningThemes(
          this.section,
          selectedValues,
          this.type
        );
        this.SET_WORK_LEARNING_THEMES({
          sectionId: this.section.id,
          themesToSave,
        });
      },
    },
    isSelectedLearningThemesValid() {
      return isLearningThemeAssociationsValid(
        this.section.section_learning_theme_associations
      );
    },
    goalsInText() {
      return numberToWord(this.goals.length);
    },
    proceedDisabled() {
      let isStepInValid = false;
      if (this.type === GOAL_TYPE.work && this.validation) {
        isStepInValid = !this.allGoalsValid;
      }
      return isStepInValid;
    },
  },
  methods: {
    ...mapMutations('pdp', [
      'SET_WORK_LEARNING_TEXT',
      'SET_CAREER_LEARNING_TEXT',
      'SET_WORK_LEARNING_THEMES',
    ]),
    ...mapMutations('formValidation', ['addGoal']),
    ...mapActions('pdp', ['savePdp']),
    updateValidation() {
      this.setGoalPanelIndex();
      this.$emit('goalsAltered');
    },
    autosave() {
      this.setGoalPanelIndex();
      this.$emit('autosave');
    },
    stopAutosave() {
      this.setGoalPanelIndex();
      this.$emit('stopAutosave');
    },
    setGoalPanelIndex() {
      this.goalPanelIndex = this.goals.map((item, index) => index);
    },
    isSelected(item) {
      return this.selectedLearningThemes.some((theme) => theme.id === item.id);
    },
    handleWorkLearningThemeSelect(theme) {
      let selected = this.selectedLearningThemes;
      if (this.isSelected(theme)) {
        selected.splice(
          selected.findIndex((selected) => selected.id === theme.id),
          1
        );
      } else if (
        this.selectedLearningThemes.length < MAX_WORK_LEARNING_THEMES
      ) {
        selected.push(theme);
      }
      this.selectedLearningThemes = selected;
    },
    async handleAddNewGoal() {
      this.formPdp.sections = handleAddNewGoal(
        this.currentSectionType,
        this.type,
        this.formPdp
      );
      this.$emit('goalsAltered');
    },
    async handleUpdateLearningDetails() {
      this.$emit('goalsAltered');
    },
    proceed() {
      this.$emit('proceed');
    },
  },
  emits: [
    'updateStepValidity',
    'proceed',
    'toggle-skip-button',
    'goalsAltered',
    'saved',
  ],
};
</script>

<style lang="scss" scoped>
:deep .info-background-banner {
  background-color: $ads-primary-teal !important;
  border: none !important;
  // colour for info banner text
  &.theme--light.v-sheet {
    color: $ads-grey-01;
  }
}

:deep .v-messages:not(.error--text) {
  color: $ads-navy;
  font-size: 16px;
}

.text--size-18 {
  font-size: 18px;
}

.text--color-black {
  color: #000;
}

.goal-section-title {
  color: #000;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}

:deep .v-expansion-panel {
  break-inside: avoid;
}

@media screen and (max-width: 959px) {
  :deep .v-alert__wrapper {
    margin-left: 16px;
  }
  :deep .v-icon.v-alert__icon {
    display: none;
  }
  :deep .v-expansion-panel-header {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>

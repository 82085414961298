var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pa-5 d-flex flex-row",style:(`border-top: 3px solid ${_vm.ADS_Colors.Blue_1_Dark}`),attrs:{"id":"signaturepanel"}},[_c('div',[_c('v-icon',{attrs:{"color":_vm.ADS_Colors.Blue_1_Dark}},[_vm._v("mdi-thumb-up-outline")])],1),_c('div',{staticClass:"d-flex flex-column ml-5 mr-5",staticStyle:{"width":"100%"}},[_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:sanitize",value:(_vm.panelText.title),expression:"panelText.title",arg:"sanitize"}],staticClass:"title"}),_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:sanitize",value:(_vm.panelText.text),expression:"panelText.text",arg:"sanitize"}],staticClass:"ma-0 pa-0 body-1"}),_c('v-row',{staticClass:"mt-6 justify-space-between",attrs:{"dense":""}},[_c('v-col',{staticClass:"justify-space-between"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column justify-space-between"},[_c('div',{staticClass:"d-flex flex-row"},[_c('InitialsIcon',{staticClass:"mr-2",attrs:{"givenName":_vm.formatName(_vm.owner),"size":"48px"}}),_c('div',[_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:sanitize",value:(`<b>${_vm.formatName(_vm.owner)}</b>`),expression:"`<b>${formatName(owner)}</b>`",arg:"sanitize"}],staticClass:"ma-0 pa-0"}),_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:plaintext",value:(
                    _vm.getPositions(_vm.owner.staffEmployment)
                  ),expression:"\n                    getPositions(owner.staffEmployment)\n                  ",arg:"plaintext"}],staticClass:"mb-3"})])],1),_c('div',[_c('v-btn',{class:`${
                  _vm.ownerButtonState?.action === _vm.SIGN_BUTTON_STATE.signed.action
                    ? 'disable-events'
                    : ''
                } ${
                  _vm.ownerButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.signed.action ||
                  _vm.ownerButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.inactive.action ||
                  _vm.ownerButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.awaitReview.action ||
                  _vm.ownerButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.awaitSign.action
                    ? 'no-border'
                    : 'confirm-box'
                } ${_vm.ownerTicked ? 'active-button' : ''} ${
                  this.hasAnyoneSigned && !this.hasOwnerSigned ? 'mb-6' : ''
                }`,attrs:{"disabled":_vm.ownerButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.inactive.action ||
                  _vm.ownerButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.awaitReview.action ||
                  _vm.ownerButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.awaitSign.action,"depressed":"","min-width":"192px"},on:{"click":_vm.tickOwner}},[(
                    _vm.ownerButtonState?.action === _vm.SIGN_BUTTON_STATE.sign.action
                  )?_c('v-checkbox',{staticClass:"pr-2",attrs:{"input-value":_vm.ownerTicked,"readonly":""}}):_vm._e(),(
                    _vm.ownerButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.signed.action
                  )?_c('v-icon',{staticClass:"pr-5",attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.ownerButtonState?.text)+" ")],1),_c('p',{staticClass:"mb-4 pt-2"},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.section?.userSignTime))+" ")])],1)]),_c('v-col',{staticClass:"d-flex flex-column justify-space-between"},[_c('div',{staticClass:"d-flex flex-row"},[_c('InitialsIcon',{staticClass:"mr-2",attrs:{"givenName":_vm.formatName(_vm.sectionSupervisor),"size":"48px"}}),_c('div',[_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:sanitize",value:(
                    `<b>${_vm.formatName(_vm.sectionSupervisor)}</b>`
                  ),expression:"\n                    `<b>${formatName(sectionSupervisor)}</b>`\n                  ",arg:"sanitize"}],staticClass:"ma-0 pa-0"}),_c('p',{staticClass:"mb-3"},[_vm._v("PDP Supervisor")])])],1),_c('div',[_c('v-btn',{class:`${
                  _vm.supervisorButtonState?.action ===
                  _vm.SIGN_BUTTON_STATE.signed.action
                    ? 'disable-events'
                    : ''
                } ${
                  _vm.supervisorButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.signed.action ||
                  _vm.supervisorButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.inactive.action ||
                  _vm.supervisorButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.awaitReview.action ||
                  _vm.supervisorButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.awaitSign.action
                    ? 'no-border'
                    : 'confirm-box'
                } ${_vm.supervisorTicked ? 'active-button' : ''} ${
                  this.hasAnyoneSigned && !this.hasSupervisorSigned
                    ? 'mb-6'
                    : ''
                }`,attrs:{"disabled":_vm.supervisorButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.inactive.action ||
                  _vm.supervisorButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.awaitReview.action ||
                  _vm.supervisorButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.awaitSign.action,"active-class":"","depressed":"","min-width":"192px"},on:{"click":_vm.tickSupervisor}},[(
                    _vm.supervisorButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.sign.action
                  )?_c('v-checkbox',{staticClass:"pr-2",attrs:{"input-value":_vm.supervisorTicked,"readonly":""}}):_vm._e(),(
                    _vm.supervisorButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.signed.action
                  )?_c('v-icon',{staticClass:"pr-5",attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.supervisorButtonState?.text)+" ")],1),_c('p',{staticClass:"mb-4 pt-2"},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.section?.supervisorSignTime))+" ")])],1)]),(_vm.sectionPdpPrincipal)?_c('v-col',{staticClass:"d-flex flex-column justify-space-between"},[_c('div',{staticClass:"d-flex flex-row"},[_c('InitialsIcon',{staticClass:"mr-2",attrs:{"givenName":_vm.formatName(_vm.sectionPdpPrincipal),"size":"48px"}}),_c('div',[_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:sanitize",value:(
                    `<b>${_vm.formatName(_vm.sectionPdpPrincipal)}</b>`
                  ),expression:"\n                    `<b>${formatName(sectionPdpPrincipal)}</b>`\n                  ",arg:"sanitize"}],staticClass:"ma-0 pa-0"}),_c('p',{staticClass:"mb-3"},[_vm._v("PDP Principal (optional)")])])],1),_c('div',[_c('v-btn',{class:`${
                  _vm.pdpPrincipalButtonState?.action ===
                  _vm.SIGN_BUTTON_STATE.signed.action
                    ? 'disable-events'
                    : ''
                } ${
                  _vm.pdpPrincipalButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.signed.action ||
                  _vm.pdpPrincipalButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.inactive.action ||
                  _vm.pdpPrincipalButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.awaitReview.action ||
                  _vm.pdpPrincipalButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.awaitSign.action
                    ? 'no-border'
                    : 'confirm-box'
                } ${_vm.pdpPrincipalTicked ? 'active-button' : ''} ${
                  this.hasAnyoneSigned && !this.hasPdpPrincipalSigned
                    ? 'mb-6'
                    : ''
                }`,attrs:{"disabled":_vm.pdpPrincipalButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.inactive.action ||
                  _vm.pdpPrincipalButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.awaitReview.action ||
                  _vm.pdpPrincipalButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.awaitSign.action,"depressed":"","min-width":"192px"},on:{"click":_vm.tickPdpPrincipal}},[(
                    _vm.pdpPrincipalButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.sign.action
                  )?_c('v-checkbox',{staticClass:"pr-2",attrs:{"input-value":_vm.pdpPrincipalTicked,"readonly":""}}):_vm._e(),(
                    _vm.pdpPrincipalButtonState?.action ===
                    _vm.SIGN_BUTTON_STATE.signed.action
                  )?_c('v-icon',{staticClass:"pr-5",attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.pdpPrincipalButtonState?.text)+" ")],1),_c('p',{staticClass:"mb-4 pt-2"},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.section?.principalSignTime))+" ")])],1)]):_vm._e(),_c('v-col',{staticClass:"d-flex align-end",attrs:{"cols":"2"}},[(_vm.confirmButtonEnabled)?_c('AdsButton',{class:`${
                _vm.section?.userSignTime ||
                _vm.section?.supervisorSignTime ||
                _vm.section?.principalSignTime
                  ? 'mb-12'
                  : 'mb-6'
              } ${!_vm.confirmButtonEnabled ? 'confirm-btn--disabled' : ''}`,staticStyle:{"height":"52px"},attrs:{"disabled":!_vm.confirmButtonEnabled,"buttonText":"Confirm","depressed":"","icon":"mdi-send-outline"},on:{"click":_vm.confirmAction}}):_vm._e()],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container class="px-0">
    <v-row>
      <v-col>
        <v-card class="my-5">
          <v-row>
            <v-col cols="auto">
              <div class="px-5 px-md-10 pt-5">
                <h1 class="">Professional Goals</h1>
                <p
                  v-dompurify-html:plaintext="
                    `Submitted: ${
                      formatDateTime(this.section?.notifySupervisorTime) ?? ''
                    }`
                  "
                />
              </div>
            </v-col>
          </v-row>
          <v-row
            v-for="(goal, index) of section.goals"
            :key="`plan-read-only-goal-${goal.id}`"
          >
            <v-col>
              <div class="mb-0">
                <div class="goal-heading mb-10 px-5 px-md-10 py-5">
                  Goal
                  {{ index + 1 }}:
                  {{ goal.title ?? '' }}
                </div>
                <div class="pb-5 px-5 px-md-10">
                  <div class="divider">
                    <p>
                      <strong class="title">
                        <v-icon
                          :color="ADS_Colors.Blue_2"
                          class="mr-2"
                        >
                          mdi-star-outline
                        </v-icon>
                        Description
                      </strong>
                    </p>
                    <IncompleteFieldCard
                      v-if="
                        !goal?.description?.length && isPdpIncompleteArchived
                      "
                    />
                    <div
                      v-else
                      v-dompurify-html="goal?.description ?? ''"
                      class="goal-text mb-10"
                    />
                  </div>
                  <div class="divider">
                    <p>
                      <strong class="title">
                        <v-icon
                          :color="ADS_Colors.Blue_2"
                          class="mr-2"
                        >
                          mdi-calendar-edit
                        </v-icon>
                        Standard descriptors
                      </strong>
                    </p>
                    <div :class="`${goal.standards}mb-10`">
                      <TeachingStandard
                        v-for="standard of goal?.standards"
                        :key="`teachingStandard-${goal.id}${standard.id}${standard.focus}${standard.level}`"
                        :canDelete="false"
                        :outlined="false"
                        :standard="standard"
                        flat
                      />
                      <IncompleteFieldCard
                        v-if="
                          !goal?.standards?.length && isPdpIncompleteArchived
                        "
                      />
                    </div>
                  </div>
                  <div
                    v-if="ownerIsPrincipal"
                    class="divider"
                  >
                    <p>
                      <strong class="title">
                        <v-icon
                          :color="ADS_Colors.Blue_2"
                          class="mr-2"
                        >
                          mdi-comment-account-outline
                        </v-icon>
                        School Excellence Plan
                      </strong>
                    </p>
                    <IncompleteFieldCard
                      v-if="
                        (goal?.sipType === null ||
                          goal?.sipType === undefined) &&
                        isPdpIncompleteArchived
                      "
                    />
                    <div>
                      <div
                        v-dompurify-html:plaintext="
                          sipOptions[goal?.sipType]?.text
                        "
                        class="goal-text"
                      />
                      <div
                        v-dompurify-html="goal?.sipOther ?? ''"
                        class="goal-text mb-10"
                      />
                    </div>
                  </div>
                  <div
                    v-if="ownerIsPrincipal"
                    class="divider"
                  >
                    <p>
                      <strong class="title">
                        <v-icon
                          :color="ADS_Colors.Blue_2"
                          class="mr-2"
                        >
                          mdi-sitemap-outline
                        </v-icon>
                        Key Accountabilities
                      </strong>
                    </p>
                    <div :class="`${goal?.accountabilities}mb-10`">
                      <KeyAccountability
                        v-for="accountability of goal?.accountabilities"
                        :key="`goalaccountability-${goal.id}.${accountability?.id}`"
                        :accountability="accountability"
                        :canDelete="false"
                        :outlined="false"
                        flat
                      />
                      <IncompleteFieldCard
                        v-if="
                          !goal?.accountabilities?.length &&
                          isPdpIncompleteArchived
                        "
                      />
                    </div>
                  </div>
                  <div class="divider">
                    <p>
                      <strong class="title">
                        <v-icon
                          :color="ADS_Colors.Blue_2"
                          class="mr-2"
                        >
                          mdi-format-list-bulleted
                        </v-icon>
                        Professional learning
                      </strong>
                    </p>
                    <IncompleteFieldCard
                      v-if="
                        !goal?.professionalLearning?.length &&
                        isPdpIncompleteArchived
                      "
                    />
                    <div
                      v-else
                      v-dompurify-html="`${goal.professionalLearning ?? ''}`"
                      class="goal-text mb-10"
                    />
                  </div>
                  <div class="mb-5">
                    <p>
                      <strong class="title">
                        <v-icon
                          :color="ADS_Colors.Blue_2"
                          class="mr-2"
                        >
                          mdi-content-copy
                        </v-icon>
                        Evidence
                      </strong>
                    </p>
                    <IncompleteFieldCard
                      v-if="!goal?.evidence?.length && isPdpIncompleteArchived"
                    />
                    <div
                      v-else
                      v-dompurify-html="`${goal.evidence ?? ''}`"
                      class="goal-text"
                    />
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <IncompleteArchiveCard
      v-if="isCurrentSectionArchival && isPdpIncompleteArchived"
      :pdp="pdp"
    />
    <EventLog
      :comments="section.comments"
      :pdp="pdp"
      class="my-10 px-0 px-md-10 py-8"
    />
  </v-container>
</template>

<script>
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import { mdiInformationOutline } from '@mdi/js';
import TeachingStandard from '@/components/TeachingStandard.vue';
import { sipOptions } from '@/data/sip';
import KeyAccountability from '@/components/KeyAccountability.vue';
import { formatDateTime, formatName } from '@/utils';
import { USER_TYPE } from '@/data/constants';
import IncompleteFieldCard from '@/components/print/IncompleteFieldCard.vue';
import { getIsPdpIncompleteArchived } from '@/lib/pdp';
import EventLog from '@/components/EventLog.vue';
import IncompleteArchiveCard from '@/components/IncompleteArchiveCard.vue';

export default {
  name: 'TSPlanReadOnly',
  components: {
    IncompleteArchiveCard,
    EventLog,
    IncompleteFieldCard,
    KeyAccountability,
    TeachingStandard,
  },
  data: () => ({
    ADS_Colors,
    icons: {
      mdiInformationOutline,
    },
    sipOptions,
  }),
  props: {
    pdp: {
      type: Object,
      default: () => ({}),
    },
    section: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ownerIsPrincipal() {
      return this.pdp?.owner?.isPrincipal;
    },
    supervisor() {
      return this.pdp?.pdp_user_associations?.find(
        (user) =>
          user.type === USER_TYPE.supervisor &&
          user.user_id === this.section.supervisor_id
      )?.user;
    },
    isPdpIncompleteArchived() {
      return getIsPdpIncompleteArchived(this.pdp);
    },
    isCurrentSectionArchival() {
      return this.pdpSectionOnArchival?.id === this.section.id;
    },
  },
  methods: {
    formatName,
    formatDateTime,
  },
};
</script>

<style lang="scss" scoped>
.goal-heading {
  background-color: rgba(108, 172, 228, 0.2);
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}

.goal-text {
  color: $ads-dark;
  white-space: pre-line;
  font-size: 1rem;
}

.overview {
  background-color: $ads-light-10;
  padding: 1.5rem 2rem;

  .overview-chip {
    position: relative;
    top: -0.5rem;
  }

  .overview-content {
    width: 100%;
    padding-top: 1rem;
  }
}

.goal + .goal {
  margin-top: 2rem;
  padding-top: 2.5rem;
  border-top: 1px solid $ads-light-20;
}

.divider::after {
  content: '';
  display: block;
  width: calc(100% + 80px);
  border-top: 1px solid $ads-light-20;
  margin: 16px -40px 32px;
}
</style>
